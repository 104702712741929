import { ISelectValue } from '../../../../components/Select/Select';

export const STATE_OPTIONS: ISelectValue[] = [
  {
    value: 'denied',
    label: 'denied',
  },
  {
    value: 'approved',
    label: 'approved',
  },
  {
    value: 'confirmed',
    label: 'confirmed',
  },
  {
    value: 'removed',
    label: 'removed',
  },
];

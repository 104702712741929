import { IUser } from '../../backend/_models/user';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'users/changeMyPassword';

export interface IChangeMyPasswordInput {
  oldPassword: string;
  newPassword: string;
  twoFACode?: string;
}

export const useChangeMyPasswordCallback = (params: IPostCallbackParams<IUser>) =>
  usePostCallback<IChangeMyPasswordInput, IUser>(path, params);

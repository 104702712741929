import { FC, ReactNode } from 'react';
import styles from './UnloggedWrapper.module.scss';

export const UnloggedWrapper: FC<{ children: ReactNode | JSX.Element }> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/Loader/Loader';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import {
  ITrxRequestFull,
  ITrxRequestMerchant,
} from '../../core/backend/_models/transactions/requests';
import { USER_ROLE } from '../../core/backend/_models/user';
import { useGetTrxRequests } from '../../core/hooks/transactions/getTrxRequests';
import { Comparison, Reason } from '../../core/hooks/transactions/types';
import { RootState } from '../../store';
import { timeToUtc } from '../../utils/timeToUtc';
import { getColumnsAdmin, columnsMerchant } from './getColumns';
import { mapRequestAdmin, mapRequestMerchant } from './mapRequest';
import { RequestsHeader } from './RequestsHeader/RequestsHeader';

export type FilterValue = string | undefined;

export interface IPopUpItem {
  index: string;
  text: string;
  successText: string;
}

interface IFilters {
  txid: FilterValue;
  targetAddress: FilterValue;
  state: FilterValue;
  apiKey: FilterValue;
  ipAddress: FilterValue;
  amount: FilterValue;
  amountComparison: Comparison;
  amountUsd: FilterValue;
  amountUsdComparison: Comparison;
  reason: Reason | undefined;
  dateRange: [Date | undefined, Date | undefined] | undefined;
}

export interface IPageState {
  currentPage: number;
  savedPage: number | undefined;
  filtersActive: number;
  prevTrxState: string | undefined;
}

export const statusMap = {
  denied: 'DENIED',
  approved: 'APPROVED',
  confirmed: 'CONFIRMED',
  removed: 'REJECTED',
  none: undefined,
};

export const RequestsPage = () => {
  const [filters, setFilters] = useState<IFilters>({
    txid: undefined,
    targetAddress: undefined,
    state: undefined,
    apiKey: undefined,
    ipAddress: undefined,
    amount: undefined,
    amountComparison: 'GTE',
    amountUsd: undefined,
    amountUsdComparison: 'GTE',
    reason: undefined,
    dateRange: undefined,
  });

  const [pageState, setPageState] = useState<IPageState>({
    currentPage: 1,
    savedPage: undefined,
    filtersActive: 0,
    prevTrxState: undefined,
  });

  const [openedPopUp, setOpenedPopUp] = useState<IPopUpItem | undefined>();

  const merchantId = useSelector((state: RootState) => state.merchant.id);
  const range = useSelector((state: RootState) => state.dateRange.range);
  const userRole = useSelector((state: RootState) => state.user.info?.role);
  const rightTime = timeToUtc(range);

  const { data, loading, error, reload } = useGetTrxRequests({
    userId: merchantId,
    orderBy: [{ name: 'id', type: 'DESC' }],
    ...rightTime,
    page: pageState.currentPage,
    perPage: 10,
    txid: filters.txid,
    targetAddress: filters.targetAddress,
    status: (statusMap as any)[filters.state as any],
    apiKey: filters.apiKey,
    ipAddress: filters.ipAddress,
    comparisonAmount: filters.amount
      ? { operator: filters.amountComparison, value: filters.amount }
      : undefined,
    comparisonAmountUsd: filters.amountUsd
      ? { operator: filters.amountUsdComparison, value: filters.amountUsd }
      : undefined,
    reason: filters.reason,
  });

  const mapDataAdmin = useMemo(() => mapRequestAdmin(openedPopUp, setOpenedPopUp), [openedPopUp]);

  const mapDataMerchant = useMemo(
    () => mapRequestMerchant(openedPopUp, setOpenedPopUp),
    [openedPopUp],
  );

  const renderData = useCallback(() => {
    if (!data) return undefined;
    return userRole === USER_ROLE.SUPER_ADMIN
      ? (data.values as ITrxRequestFull[]).map(mapDataAdmin)
      : (data.values as ITrxRequestMerchant[]).map(mapDataMerchant);
  }, [data, mapDataAdmin, mapDataMerchant, userRole]);

  const setFilterCb = useCallback(
    (key: keyof IFilters) => {
      return (value?: string | Date | [Date | undefined, Date | undefined]) => {
        if (!value && pageState.filtersActive === 1) {
          setPageState((prev) => {
            return {
              filtersActive: 0,
              currentPage: prev.savedPage || 1,
              savedPage: undefined,
              prevTrxState: key === 'state' ? (value as string) : prev.prevTrxState,
            };
          });
          setFilters((filters) => {
            return { ...filters, [key]: value };
          });
          return;
        }

        if (!value) {
          setPageState((prev) => {
            return {
              filtersActive: prev.filtersActive - 1,
              currentPage: 1,
              savedPage: prev.savedPage,
              prevTrxState: key === 'state' ? (value as string) : prev.prevTrxState,
            };
          });
          setFilters((filters) => {
            return { ...filters, [key]: value };
          });
          return;
        }

        if (!pageState.savedPage) {
          setPageState((prev) => {
            return {
              filtersActive: prev.filtersActive + 1,
              currentPage: 1,
              savedPage: prev.currentPage,
              prevTrxState: key === 'state' ? (value as string) : prev.prevTrxState,
            };
          });
          setFilters((filters) => {
            return { ...filters, [key]: value };
          });
          return;
        }

        setPageState((prev) => {
          return {
            filtersActive:
              key === 'state' && prev.prevTrxState ? prev.filtersActive : prev.filtersActive + 1,
            currentPage: 1,
            savedPage: prev.savedPage,
            prevTrxState: key === 'state' ? (value as string) : prev.prevTrxState,
          };
        });
        setFilters((filters) => {
          return { ...filters, [key]: value };
        });
      };
    },
    [pageState.filtersActive, pageState.savedPage],
  );

  const columns = useMemo(
    () => (userRole === USER_ROLE.SUPER_ADMIN ? getColumnsAdmin() : columnsMerchant),
    [userRole],
  );

  // if (loading) return <Loader />;
  // if (!loading && error) toast(error);
  return (
    <>
      <RequestsHeader
        reload={reload}
        state={filters.state}
        reason={filters.reason}
        address={filters.targetAddress}
        amount={filters.amount}
        amountComparison={filters.amountComparison}
        amountUSD={filters.amountUsd}
        amountUSDComparison={filters.amountUsdComparison}
        apiKey={filters.apiKey}
        ipAddress={filters.ipAddress}
        txid={filters.txid}
        result={filters.state}
        setState={setFilterCb('state')}
        setReason={setFilterCb('reason')}
        setAddress={setFilterCb('targetAddress')}
        setAmount={setFilterCb('amount')}
        setAmountUSD={setFilterCb('amountUsd')}
        setApiKey={setFilterCb('apiKey')}
        setIpAddress={setFilterCb('ipAddress')}
        setTxid={setFilterCb('txid')}
        setAmountComparison={setFilterCb('amountComparison')}
        setAmountUSDComparison={setFilterCb('amountUsdComparison')}
        setResult={setFilterCb('state')}
        setDateRange={setFilterCb('dateRange')}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table columns={columns} data={renderData()} />
          {data && data.maxPage > 1 && (
            <Pagination
              maxPage={data.maxPage}
              currentPage={pageState.currentPage}
              setCurrentPage={(currentPage) =>
                setPageState((prev) => {
                  return { ...prev, currentPage: currentPage };
                })
              }
            />
          )}
        </>
      )}
    </>
  );
};

import { FC, ReactNode } from 'react';
import { Header } from '../_Header/Header';
import styles from './NeedTwoFAWrapper.module.scss';

export const NeedTwoFAWrapper: FC<{ children: ReactNode | JSX.Element }> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <main className={styles.main}>{children}</main>
    </div>
  );
};

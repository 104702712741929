import { FC, ReactNode, useState } from 'react';
import { ISelectValue } from '../Select/Select';
import styles from './Table.module.scss';
import { TableContent } from './TableContent/TableContent';

export interface ITableColumn {
  input: 'text' | 'date' | 'select' | 'none';
  placeholder?: string;
  width: number;
  maxWidth?: number;
  isFilter: boolean;
  value?: string;
  onInputChange?: (value?: string) => void;
  onSelectChange?: (value?: string) => void;
}

export interface ITableProps {
  columns: ITableColumn[];
  isMerchantsPage?: boolean;
  data?: (string | ReactNode | JSX.Element | number)[][];
  status?: ISelectValue | null;
  setStatus?: (sel: ISelectValue | null) => void;
  statusFull?: boolean;
}

export const Table: FC<ITableProps> = ({
  status,
  setStatus,
  statusFull,
  columns,
  isMerchantsPage = false,
  data,
}) => {
  return (
    <section className={styles.wrapper}>
      <TableContent
        status={status}
        setStatus={setStatus}
        statusFull={statusFull}
        columns={columns}
        isMerchantsPage={isMerchantsPage}
        data={data}
      />
    </section>
  );
};

import styles from './DashboardControls.module.scss';
import { FC } from 'react';
import { DashboardDateRangepicker } from './DashboardDateRangepicker/DashboardDateRangepicker';
import { MerchantSelect } from '../../../components/MerchantSelect/MerchantSelect';
import { ReloadButton } from '../../../components/ReloadButton/ReloadButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { USER_ROLE } from '../../../core/backend/_models/user';
import { SwitchAutoRefresh } from '../../../components/SwitchAutoRefresh/SwitchAutoRefresh';

export interface IDashboardControlsProps {
  reload: () => void;
}

export const DashboardControls: FC<IDashboardControlsProps> = ({ reload }) => {
  const userRole = useSelector((state: RootState) => state.user.info?.role);
  return (
    <section className={styles.inputs}>
      <DashboardDateRangepicker />
      {userRole === USER_ROLE.SUPER_ADMIN && <MerchantSelect variant="secondary" size="xxl" />}
      <ReloadButton reload={reload} />
      <SwitchAutoRefresh refetch={reload} />
    </section>
  );
};

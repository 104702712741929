import { FC } from 'react';
import { PlusAdminIcon } from '../../../assets/icons/PlusAdminIcon';
import { Button } from '../../Button/Button';
import { Loader } from '../../Loader/Loader';
import { IModal } from '../ModalWrapper/ModalWrapper';
import styles from './ModalButtons.module.scss';

type IModalButtonsProps = Pick<IModal, 'onClose'> & {
  actionButtonName: string | JSX.Element;
  loading?: boolean;
  maxWidth?: number;
  icon?: boolean;
  disableActBut?: boolean;

  onAction?: () => void;
};

export const ModalButtons: FC<IModalButtonsProps> = ({
  actionButtonName,
  loading = false,
  onClose,
  onAction,
  maxWidth,
  icon = true,
  disableActBut = false,
}) => {
  return (
    <section className={styles.buttons} style={{ maxWidth: maxWidth }}>
      <Button variant="transparent" size="preSm" onClick={onClose}>
        Close
      </Button>
      {!disableActBut ? (
        <Button
          variant="secondary"
          icon={icon ? <PlusAdminIcon /> : <></>}
          onClick={!loading ? onAction : () => void 0}
        >
          {loading ? <Loader size="sm" /> : actionButtonName}
        </Button>
      ) : (
        <></>
      )}
    </section>
  );
};

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowIcon } from '../../../assets/icons/ArrowIcon/ArrowIcon';
import { IconColor } from '../../../assets/types';
import { BackLink } from '../../../components/BackLink/BackLink';
import { Button } from '../../../components/Button/Button';
import { Heading } from '../../../components/Heading/Heading';
import { Input } from '../../../components/Input/Input';
import { useConfirm2fa } from '../../../core/hooks/auth/confirm2fa';
import { AppDispatch } from '../../../store';
import { userLoad, userLogout } from '../../../store/reducers/userReducer/userReducer';
import { QRCode } from './QRCode/QRCode';
import cx from 'classnames';
import styles from './TFAForm.module.scss';
import { customToast } from '../../../components/Toast/Toast';

export interface ITFAFormProps {
  isModal?: boolean;
  onClose?: () => void;
}

export const TFAForm: React.FC<ITFAFormProps> = ({ isModal = false, onClose }) => {
  const [twoFACode, setTwoFACode] = useState<number>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const confirm2fa = useConfirm2fa({
    onResult: (res) => {
      if (isModal) {
        if (!res) customToast('Code is incorrect');
        else {
          customToast('2FA created');
          dispatch(userLoad());
          onClose && onClose();
        }
      } else {
        if (res) dispatch(userLogout());
        else customToast('Code is incorrect');
      }
    },
    setLoading: setConfirmLoading,
  });

  return (
    <section className={cx(styles.form, { [styles.modal]: isModal })}>
      {!isModal && <BackLink onClick={() => dispatch(userLogout())} />}
      <Heading headType="h1"> 2FA </Heading>
      <QRCode />
      <Input
        minWidth={!isModal ? 401 : 170}
        placeholder="Verification code"
        value={twoFACode}
        onChange={(e) => setTwoFACode(Number(e.target.value))}
        type="number"
      />
      <Button
        size="lg"
        icon={<ArrowIcon fill={IconColor.WHITE} />}
        onClick={
          twoFACode
            ? () =>
                confirm2fa({
                  twoFACode: twoFACode,
                })
            : () => void 0
        }
      >
        Verify
      </Button>
      {!isModal && <span className={styles.copyright}>&copy;2022</span>}
    </section>
  );
};

import { FC } from 'react';
import { IIcon } from '../types';

export const KeyIcon: FC<IIcon> = ({ width = 30, height = 30, fill = '#8CC1FF' }) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.56196 8.01339C5.45891 8.2666 2.31198 12.0537 3.13013 16.3695C3.65214 19.1247 5.87421 21.3479 8.6303 21.8699C12.3776 22.5804 15.7198 20.2995 16.7028 17.0004H20.9998V18.0005C20.9998 19.1056 21.8948 20.0007 22.9999 20.0007C24.1049 20.0007 24.9999 19.1056 24.9999 18.0005V17.0004C26.105 17.0004 27 16.1053 27 15.0003C27 13.8952 26.105 13.0001 24.9999 13.0001H16.6989C15.9791 10.5432 13.9091 8.61178 11.3687 8.13059C10.7523 8.0137 10.1481 7.97722 9.56196 8.01339ZM9.99948 12C11.6565 12 12.9996 13.3431 12.9996 15.0003C12.9996 16.6574 11.6565 18.0005 9.99948 18.0005C8.34243 18.0005 6.99939 16.6574 6.99939 15.0003C6.99939 13.3431 8.34243 12 9.99948 12Z"
      fill={fill}
    />
  </svg>
);

import { IUser } from '../../../core/backend/_models/user';

export enum USER_STATE {
  CACHED = 'CACHED',
  LOADING = 'LOADING',
  UNLOGGED = 'UNLOGGED',
  LOGGED = 'LOGGED',
  BLOCKED = 'BLOCKED',
}

export interface UserReducerState {
  info?: IUser;
  state: USER_STATE;
  error?: string;
}

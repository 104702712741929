import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowIcon } from '../../../../assets/icons/ArrowIcon/ArrowIcon';
import { IconColor } from '../../../../assets/types';
import { Button } from '../../../../components/Button/Button';
import { Heading } from '../../../../components/Heading/Heading';
import { Input } from '../../../../components/Input/Input';
import { customToast } from '../../../../components/Toast/Toast';
import { AppDispatch } from '../../../../store';
import { userLogin } from '../../../../store/reducers/userReducer/userReducer';
import styles from './LoginForm.module.scss';

interface ICreds {
  login: string;
  password: string;
  twoFACode: string | undefined;
}

export const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [creds, setCreds] = useState<ICreds>({
    login: '',
    password: '',
    twoFACode: undefined,
  });

  const dispatch = useDispatch<AppDispatch>();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCreds((o) => ({ ...o, [e.target.id]: e.target.value }));
  };

  const handleClick = useCallback(
    (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      dispatch(
        userLogin({
          login: creds.login,
          password: creds.password,
          twoFACode: creds.twoFACode,
          onErr: customToast,
          setLoading,
        }),
      );
    },
    [creds.login, creds.password, creds.twoFACode, dispatch],
  );

  return (
    <form className={styles.loginForm}>
      <Heading headType="h1">Login</Heading>
      <Input width={401} placeholder="login" value={creds.login} id="login" onChange={onChange} />
      <Input
        width={401}
        placeholder="password"
        type={'password'}
        value={creds.password}
        id="password"
        onChange={onChange}
      />
      <Input
        width={401}
        placeholder="Verification code"
        value={creds.twoFACode}
        id="twoFACode"
        onChange={onChange}
      />
      <Button
        size="lg"
        variant={loading ? 'secondary' : 'primary'}
        onClick={loading ? undefined : handleClick}
        icon={<ArrowIcon fill={IconColor.WHITE} />}
      >
        GO
      </Button>
    </form>
  );
};

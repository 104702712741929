interface IStringDates {
  dateFrom: string | undefined;
  dateTo: string | undefined;
}

interface IDateDates {
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
}

function toggleDateRangeTypes({ dateFrom, dateTo }: IDateDates): IStringDates;
function toggleDateRangeTypes({ dateFrom, dateTo }: IStringDates): IDateDates;
function toggleDateRangeTypes({ dateFrom, dateTo }: IDateDates | IStringDates) {
  if (typeof dateFrom === 'string' || typeof dateTo === 'string') {
    return {
      dateFrom: dateFrom ? new Date(dateFrom) : undefined,
      dateTo: dateTo ? new Date(dateTo) : undefined,
    };
  }
  return {
    dateFrom: dateFrom ? dateFrom.toString() : undefined,
    dateTo: dateTo ? dateTo.toString() : undefined,
  };
}

export default toggleDateRangeTypes;

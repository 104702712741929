import { LoginImage } from '../../assets/images/LoginImage';
import styles from './TwoFactorAuthPage.module.scss';
import { TFAForm } from './TFAForm/TFAForm';
import { Header } from '../LoginPage/Header/Header';

export const TwoFactorAuthPage = () => {
  return (
    <>
      <Header />
      <main className={styles.main}>
        <TFAForm />
        <LoginImage className={styles.image} />
      </main>
    </>
  );
};

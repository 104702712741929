import { Rangepicker } from '../../../../components/Datepickers/Rangepicker/Rangepicker';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { useDispatch } from 'react-redux';
import { updateDateRange } from '../../../../store/reducers/dateRangeReducer/dateRangeReducer';
import toggleDateRangeTypes from '../../../../utils/toggleDateRangeTypes';

export const DashboardDateRangepicker = () => {
  const dates = useSelector((state: RootState) => {
    return toggleDateRangeTypes(state.dateRange);
  });
  const dispatch = useDispatch<AppDispatch>();

  const handleRangeChange = (date: [Date | undefined, Date | undefined] | undefined) => {
    if (!date) return;
    dispatch(
      updateDateRange(
        toggleDateRangeTypes({
          dateFrom: date[0],
          dateTo: date[1],
        }),
      ),
    );
  };

  return (
    <Rangepicker
      maxWidth={235}
      placeholder="Date Range"
      startDate={dates.dateFrom}
      endDate={dates.dateTo}
      onChange={handleRangeChange}
      id={'dashboardDateRange'}
    />
  );
};

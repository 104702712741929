import { IUser } from '../../backend/_models/user';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'auth/disable2FA';

export interface ISetDisableTwoFAInput {
  id: number;
}

export const useSetDisableTwoFACallback = (params: IPostCallbackParams<IUser>) =>
  usePostCallback<ISetDisableTwoFAInput, IUser>(path, params);

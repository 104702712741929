import { FC, HTMLAttributes, useEffect } from 'react';
import cx from 'classnames';
import { IModalWrapperProps } from '../ModalWrapper';
import styles from './ModalContent.module.scss';

type IModalContentProps = Required<Pick<IModalWrapperProps, 'onClose' | 'children'>> &
  HTMLAttributes<HTMLDivElement>;

export const ModalContent: FC<IModalContentProps> = ({ onClose, children, className }) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onClose]);

  return (
    <div className={styles.wrapperContainer} onMouseDown={onClose}>
      <section
        className={cx(styles.wrapper, className)}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </section>
    </div>
  );
};

import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { sendPost } from '../backend/sendPost';

export interface IPostCallbackParams<T> {
  setLoading: (loading: boolean) => void;
  onResult?: (res: T) => Promise<void> | void;
  onError?: (err: string) => void;
}
export function usePostCallback<Input, Output>(path: string, params: IPostCallbackParams<Output>) {
  const { onError, onResult, setLoading } = params;
  const res = useCallback(
    (input: Input) => {
      const act = async () => {
        try {
          const res = await sendPost<Input, Output>(path, input);
          if (onResult) await onResult(res);
          setLoading(false);
        } catch (e: any) {
          setLoading(false);
          if (onError) onError(e.message);
          else toast(e.message);
        }
      };
      setLoading(true);
      act();
    },
    [onError, onResult, setLoading, path],
  );
  return res;
}

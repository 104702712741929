import { FC, ReactNode } from 'react';
import { Ellipsis } from '../../../assets/icons/Ellipsis';
import styles from './PopUpController.module.scss';

interface IPopUpControllerProps {
  popup: JSX.Element | ReactNode;

  onClick: () => void;
}

export const PopUpController: FC<IPopUpControllerProps> = ({ popup, onClick }) => {
  return (
    <div id="popup-controller" className={styles.container}>
      {popup}
      <Ellipsis className={styles.controller} onClick={onClick} />
    </div>
  );
};

import { FC } from 'react';
import { IIcon } from '../types';

export const ReloadIcon: FC<IIcon> = ({ width = 24, height = 24, fill = '#3190ff', ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 473.677 473.677"
      enableBackground="new 0 0 473.677 473.677"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={fill}
        d="M0,236.84C0,106.026,106.036,0.002,236.835,0.002c130.807,0,236.842,106.028,236.842,236.838
	c0,130.795-106.036,236.835-236.842,236.835C106.039,473.671,0,367.636,0,236.84z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M408.84,164.345c-9.88,0.022-19.756,0.049-29.636,0.075
		c-22.217-49.815-65.828-85.797-120.912-94.428c-61.374-9.614-120.115,18.889-155.14,69.036
		c-15.557,22.269,21.005,43.121,36.431,21.035c48.039-68.774,150.608-62.559,192.76,4.473c-9.125,0.022-18.253,0.045-27.377,0.067
		c-2.554,0.004-4.368,1.144-5.49,2.745c-1.832,1.967-2.532,4.839-0.61,7.816c17.378,26.966,34.759,53.936,52.141,80.902
		c2.921,4.532,9.226,4.495,12.12-0.045c17.243-27.052,34.49-54.104,51.733-81.16C417.714,170.388,413.956,164.334,408.84,164.345z"
        />
        <path
          fill="#FFFFFF"
          d="M334.479,312.395c-48.039,68.786-150.6,62.548-192.76-4.476c9.125-0.022,18.253-0.045,27.377-0.067
		c2.554-0.007,4.368-1.144,5.49-2.745c1.832-1.963,2.528-4.839,0.61-7.816c-17.374-26.97-34.756-53.936-52.133-80.902
		c-2.917-4.532-9.226-4.495-12.12,0.045c-17.243,27.052-34.494,54.104-51.741,81.16c-2.853,4.476,0.905,10.531,6.021,10.519
		c9.88-0.026,19.756-0.049,29.64-0.075c22.221,49.815,65.828,85.797,120.915,94.432c61.374,9.614,120.115-18.889,155.132-69.036
		C386.466,311.157,349.901,290.309,334.479,312.395z"
        />
      </g>
      <path
        fill="#F7F7F7"
        d="M214.865,238.089c0-12.146,9.839-21.985,21.977-21.985c12.139,0,21.977,9.839,21.977,21.985
	c0,12.131-9.839,21.97-21.977,21.97C224.704,260.059,214.865,250.217,214.865,238.089z"
      />
    </svg>
  );
};

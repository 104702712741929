import { FC, ReactNode } from 'react';
import cx from 'classnames';
import { DateIcon } from '../../../../assets/icons/DateIcon';
import { IconColor } from '../../../../assets/types';
import styles from './DatepickerWrapper.module.scss';

export interface IDatepickerWrapperProps {
  id: string;
  variant?: 'primary' | 'secondary';
  maxWidth: number;
  children: ReactNode | JSX.Element;
  isTableView?: boolean;
  isFilter?: boolean;
}

export const DatepickerWrapper: FC<IDatepickerWrapperProps> = ({
  id,
  variant,
  maxWidth,
  children,
  isTableView = false,
  isFilter,
}) => {
  const iconCx = cx(styles.icon, { [styles.iconPrimary]: variant === 'primary' });
  return (
    <div className={styles.wrapper} style={{ maxWidth: maxWidth }}>
      <label htmlFor={id}>
        {children}
        <DateIcon
          width={isTableView || isFilter ? 16 : undefined}
          height={isTableView || isFilter ? 16 : undefined}
          className={iconCx}
          fill={isFilter ? IconColor.BLUE : undefined}
        />
      </label>
    </div>
  );
};

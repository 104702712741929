import { FC } from 'react';
import { useSelector } from 'react-redux';
import { IPIcon } from '../../../assets/icons/IPIcon';
import { KeyIcon } from '../../../assets/icons/KeyIcon';
import { MerchantIcon } from '../../../assets/icons/MerchantIcon';
import { CardTable } from '../../../components/CardTable/CardTable';
import { Loader } from '../../../components/Loader/Loader';
import { customToast } from '../../../components/Toast/Toast';
import { USER_ROLE } from '../../../core/backend/_models/user';
import { useGetTopStatistics } from '../../../core/hooks/stats/getTopStatistics';
import { RootState } from '../../../store';
import { timeToUtc } from '../../../utils/timeToUtc';
import { useReloader } from '../DashboardMainInfo/useReloader';
import { IDateRangeProps } from '../types';
import styles from './DashboardTables.module.scss';

export const DashboardTables: FC<IDateRangeProps> = ({ beginDate, endDate, reloader }) => {
  const globalState = useSelector((state: RootState) => state);
  const userRole = globalState.user.info?.role;
  const merchantId = globalState.merchant.id;

  const isAdmin = userRole === USER_ROLE.SUPER_ADMIN;
  const rightTime = timeToUtc({ from: beginDate, to: endDate });

  const { data, loading, error, reload } = useGetTopStatistics({
    beginDate: rightTime.beginDate ?? beginDate,
    endDate: rightTime.endDate ?? endDate,
    currency: 'BTC',
    userId: merchantId,
  });

  useReloader(reloader, reload);

  if (!loading && error) customToast(error);

  return (
    <section className={styles.tables}>
      {!loading ? (
        <>
          {isAdmin && (
            <CardTable
              title={'Top Merchant'}
              maxWidth={386}
              icon={<MerchantIcon />}
              rows={data && data.topMerchant}
              isAdmin={isAdmin}
            />
          )}
          <CardTable
            title={'Top ApiKey'}
            maxWidth={isAdmin ? 386 : 594}
            icon={<KeyIcon />}
            rows={data && data.topApiKey}
            isAdmin={isAdmin}
          />
          <CardTable
            title={'Top IP Address'}
            maxWidth={isAdmin ? 386 : 594}
            icon={<IPIcon />}
            rows={data && data.topIPAddress}
            isAdmin={isAdmin}
          />
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

import { ITransactionItem } from '../../backend/_models/stats';
import { usePostRequest } from '../usePostRequest';

const path = 'statistics/get-total-statistic';

export interface IGetTotalStatistics {
  status: 'YEARLY' | 'MONTHLY' | 'DAILY';
  userId?: number;
}

export const useGetTotalStatistics = (input: IGetTotalStatistics) =>
  usePostRequest<IGetTotalStatistics, ITransactionItem[]>(path, input);

import { IMerchantRequest, IUser, USER_ROLE } from '../../backend/_models/user';
import { usePostRequest } from '../usePostRequest';

const path = 'users/get-users';

export interface IGetMerchants {
  beginDate?: Date;
  endDate?: Date;
  isBanned?: boolean;
  role?: USER_ROLE.MERCHANT | USER_ROLE.SUPER_ADMIN;
  page?: number;
  perPage?: number;
}

export const useGetMerchants = (input: IGetMerchants) =>
  usePostRequest<IGetMerchants, IMerchantRequest>(path, input);

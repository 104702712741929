import { FC, useState } from 'react';
import { FiltersIcon } from '../../../assets/icons/FiltersIcon';
import { Button } from '../../../components/Button/Button';
import { Heading } from '../../../components/Heading/Heading';
import { ReloadButton } from '../../../components/ReloadButton/ReloadButton';
import { SwitchAutoRefresh } from '../../../components/SwitchAutoRefresh/SwitchAutoRefresh';
import { IRequestsFiltersProps, RequestsFilters } from './RequestsFilters/RequestsFilters';
import styles from './RequestsHeader.module.scss';

interface IRequestsHeaderProps extends IRequestsFiltersProps {
  reload: () => void;
}

export const RequestsHeader: FC<IRequestsHeaderProps> = ({ reload, ...props }) => {
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);

  return (
    <section className={styles.header}>
      <section className={styles.headerMain}>
        <div className={styles.headingWrapper}>
          <Heading headType={'h1'}> Requests </Heading>
          <ReloadButton size={32} reload={reload} />
          <SwitchAutoRefresh refetch={reload} />
        </div>
        <Button
          variant={areFiltersOpen ? 'ghost' : 'secondary'}
          onClick={() => setAreFiltersOpen((prev) => !prev)}
          icon={<FiltersIcon />}
        >
          Filters
        </Button>
      </section>
      {areFiltersOpen && <RequestsFilters {...props} />}
    </section>
  );
};

import { IUser } from '../backend/_models/user';

const userKey = 'CACHED-USER';

export const getCachedUser = (): IUser | undefined => {
  const data = localStorage.getItem(userKey);
  if (!data) return;
  return JSON.parse(data) as IUser;
};

export const setCachedUser = (user?: IUser) =>
  user ? localStorage.setItem(userKey, JSON.stringify(user)) : localStorage.removeItem(userKey);

import { IPieData } from '../../../../components/PieChart/PieChart';
import { IMainStats } from '../../../../core/backend/_models/stats';

interface IPieStatsObject {
  none?: IPieData;
  approved?: IPieData;
  denied?: IPieData;
}

export const getTopPieStats = (stats?: IMainStats) => {
  if (!stats) return { none: { key: 'NONE', value: 0, color: '#fff' } };

  const allTopStats = stats ? Number(stats.approved) + Number(stats.denied) : 1;
  const approved = stats ? Number(stats.approved) / allTopStats : 0;
  const denied = stats ? Number(stats.denied) / allTopStats : 0;

  const data: IPieStatsObject = {};
  if (approved > 0) data.approved = { key: 'APR', value: approved, color: '#00C797' };
  if (denied > 0) data.denied = { key: 'DEN', value: denied, color: '#DF1647' };
  if (Object.keys(data).length > 0) data.none = { key: 'NONE', value: 0, color: '#fff' };

  return data;
};

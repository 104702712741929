import { FC } from 'react';
import { useGetUserIpAddresses } from '../../../core/hooks/ip/getUserIpAddresses';
import { Heading } from '../../Heading/Heading';
import { Loader } from '../../Loader/Loader';
import { customToast } from '../../Toast/Toast';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { AddIPSection } from './AddIPSection/AddIPSection';
import { EditIPsSection } from './EditIPsSection/EditIPsSection';
import styles from './ModalIPs.module.scss';

interface IModalIPsProps {
  userId: number;

  onClose: () => void;
}

export const ModalIPs: FC<IModalIPsProps> = ({ userId, onClose }) => {
  const { data, loading, error, reloadAsync } = useGetUserIpAddresses({ userId });

  if (loading)
    return (
      <ModalWrapper onClose={onClose}>
        <Loader />
      </ModalWrapper>
    );

  if (!loading && error) customToast(error);

  return (
    <ModalWrapper onClose={onClose} className={styles.wrapper}>
      <Heading variant="modal" headType="h2">
        IP addresses
      </Heading>
      {!data || data.length === 0 ? (
        <div className={styles.noData}> No data </div>
      ) : (
        <EditIPsSection IPs={data} userId={userId} reloadAsync={reloadAsync} />
      )}
      <AddIPSection userId={userId} closeModal={onClose} reloadAsync={reloadAsync} />
    </ModalWrapper>
  );
};

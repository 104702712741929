import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { updateDateRangeAction } from '../../actions/dateRangeActions';
import { DateRangeReducerState } from './types';

const dateFrom = dayjs().startOf('day').toDate();
const dateTo = dayjs().endOf('day').toDate();

const initialState: DateRangeReducerState = {
  range: { from: dateFrom, to: dateTo },
  dateFrom: dateFrom.toString(),
  dateTo: dateTo.toString(),
};

export const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    updateDateRange: updateDateRangeAction,
  },
});

export const { updateDateRange: updateDateRange } = dateRangeSlice.actions;
export const dateRangeReducer = dateRangeSlice.reducer;

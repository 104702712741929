import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Rangepicker } from '../../../../../components/Datepickers/Rangepicker/Rangepicker';
import { AppDispatch, RootState } from '../../../../../store';
import { updateDateRange } from '../../../../../store/reducers/dateRangeReducer/dateRangeReducer';
import toggleDateRangeTypes from '../../../../../utils/toggleDateRangeTypes';
import { DateRangeAction } from '../../../../TransactionsPage/TransactionsHeader/TransactionsFilters/types';

export const RequestsRangepicker: FC<{ width: number; setRange: DateRangeAction }> = ({
  width,
  setRange,
}) => {
  const dates = useSelector((state: RootState) => {
    return toggleDateRangeTypes(state.dateRange);
  });
  const dispatch = useDispatch<AppDispatch>();

  const handleRangeChange = (date: [Date | undefined, Date | undefined] | undefined) => {
    if (!date) return;
    dispatch(
      updateDateRange(
        toggleDateRangeTypes({
          dateFrom: date[0],
          dateTo: date[1],
        }),
      ),
    );
    setRange(date);
  };

  return (
    <Rangepicker
      maxWidth={width}
      variant="primary"
      placeholder="Date Range"
      startDate={dates.dateFrom}
      endDate={dates.dateTo}
      onChange={handleRangeChange}
      id={'transactionRequestsDateRange'}
      isFilter
    />
  );
};

import ReactDatePicker from 'react-datepicker';
import cx from 'classnames';
import { IDateProps } from '../common/types';
import { FC, SyntheticEvent } from 'react';
import '../common/ReactDatePicker.scss';
import { renderCustomHeader } from '../common/DatepickerHeader/DatepickerHeader';
import { DatepickerWrapper } from '../common/DatepickerWrapper/DatepickerWrapper';
import styles from './Rangepicker.module.scss';
import dayjs from 'dayjs';
import { getMaxDate } from '../common/getMaxDate';

interface IRangepickerProps extends IDateProps {
  startDate?: Date;
  endDate?: Date;
  variant?: 'primary' | 'secondary';

  onChange: (
    date: [Date | undefined, Date | undefined] | undefined,
    event: SyntheticEvent<any, Event> | undefined,
  ) => void;
}

export const Rangepicker: FC<IRangepickerProps> = ({
  id,
  variant = 'secondary',
  maxWidth,
  disabled,
  onChange,
  className,
  placeholder,
  startDate,
  endDate,
  isFilter,
}) => {
  const rangePickerCx = cx(styles.rangepicker, styles[variant], className, {
    [styles.filterView]: isFilter,
  });
  const maxDate = getMaxDate();
  return (
    <DatepickerWrapper id={id} variant={variant} maxWidth={maxWidth} isFilter={isFilter}>
      <ReactDatePicker
        id={id}
        className={rangePickerCx}
        startDate={startDate}
        endDate={endDate}
        onChange={([dateFrom, dateTo], e) => {
          // react-datepicker chooses 00:00:00 time by default
          const endDate = dateTo ? dayjs(dateTo).endOf('day').toDate() : undefined;
          onChange([dateFrom || undefined, endDate], e);
        }}
        shouldCloseOnSelect={false}
        disabled={disabled}
        dateFormat="yyyy-MM-dd"
        placeholderText={placeholder}
        renderCustomHeader={renderCustomHeader}
        formatWeekDay={(day) => day.substring(0, 1)}
        maxDate={maxDate}
        selectsRange
      />
    </DatepickerWrapper>
  );
};

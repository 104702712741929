import { FC, useState } from 'react';
import { useDeleteIpAddress } from '../../../../../core/hooks/ip/deleteIpAddress';
import { useSetIpAddressEnabled } from '../../../../../core/hooks/ip/setIpAddressEnabled';
import { useUpdateIpAddress } from '../../../../../core/hooks/ip/updateIpAddress';
import { Button } from '../../../../Button/Button';
import { Input } from '../../../../Input/Input';
import { Loader } from '../../../../Loader/Loader';
import { customToast } from '../../../../Toast/Toast';
import { INPUT_MAX_WIDTH } from '../../constants';
import { IPEditProps } from '../../IPEditProps';
import styles from './EditIP.module.scss';

export const EditIP: FC<IPEditProps> = ({ IP, reloadAsync, userId }) => {
  const [newIP, setNewIP] = useState<string>(String(IP.address));
  const [loading, setLoading] = useState(false);
  const [toggleIPLoad, setToggleIPLoad] = useState(false);

  const setIPAddressEnabled = useSetIpAddressEnabled({
    onResult: async () => {
      await reloadAsync();
    },
    setLoading: setToggleIPLoad,
    onError: (e) => customToast(e),
  });

  const toggleIPState = () =>
    setIPAddressEnabled({
      userId: userId,
      ipAddress: IP.address,
      enabled: !IP.enabled,
    });

  const updateIpAddress = useUpdateIpAddress({
    onResult: async () => {
      await reloadAsync();
    },
    setLoading,
    onError: (e) => customToast(e),
  });

  const deleteIpAddress = useDeleteIpAddress({
    onResult: async () => {
      await reloadAsync();
    },
    setLoading,
    onError: (e) => customToast(e),
  });

  const updateIP = () => {
    updateIpAddress({ userId, targetIpAddress: IP.address, updatedIpAddress: newIP });
  };

  const deleteIP = () => {
    deleteIpAddress({ userId, ipAddress: IP.address });
  };

  return (
    <>
      <Input
        value={newIP}
        variant="secondary"
        width={INPUT_MAX_WIDTH}
        onChange={(e) => setNewIP(e.target.value)}
      />
      <div className={styles.buttons}>
        <Button size="largerXs" onClick={updateIP}>
          {loading ? <Loader size="xs" color="secondary" /> : 'Save'}
        </Button>
        <Button
          variant={IP.enabled ? 'secondary' : 'primary'}
          size="largerXs"
          onClick={!toggleIPLoad ? toggleIPState : () => void 0}
        >
          {toggleIPLoad ? (
            <Loader size="xs" color={IP.enabled ? 'primary' : 'secondary'} />
          ) : IP.enabled ? (
            'Disable'
          ) : (
            'Enable'
          )}
        </Button>
        <Button size="largerXs" variant="secondary" onClick={deleteIP}>
          Delete
        </Button>
      </div>
    </>
  );
};

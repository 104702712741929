import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusAdminIcon } from '../../../../assets/icons/PlusAdminIcon';
import { Button } from '../../../../components/Button/Button';
import { Rangepicker } from '../../../../components/Datepickers/Rangepicker/Rangepicker';
import { Heading } from '../../../../components/Heading/Heading';
import { RootState, AppDispatch } from '../../../../store';
import { updateDateRange } from '../../../../store/reducers/dateRangeReducer/dateRangeReducer';
import toggleDateRangeTypes from '../../../../utils/toggleDateRangeTypes';
import { ModalState } from '../MerchantsPage';
import styles from './MerchantsHeader.module.scss';

interface IMerchantsHeaderProps {
  setModalState: (name: keyof ModalState, state?: boolean | number) => void;
}

export const MerchantsHeader: FC<IMerchantsHeaderProps> = ({ setModalState }) => {
  const dates = useSelector((state: RootState) => {
    return toggleDateRangeTypes(state.dateRange);
  });
  const dispatch = useDispatch<AppDispatch>();

  const handleRangeChange = (date: [Date | undefined, Date | undefined] | undefined) => {
    if (!date) return;
    dispatch(
      updateDateRange(
        toggleDateRangeTypes({
          dateFrom: date[0],
          dateTo: date[1],
        }),
      ),
    );
  };

  return (
    <section className={styles.header}>
      <div className={styles.headerRow}>
        <Heading headType={'h1'}>Super Admin Manage Merchants</Heading>
        <Button
          variant="secondary"
          onClick={() => setModalState('create', true)}
          icon={<PlusAdminIcon />}
        >
          Create
        </Button>
      </div>
      <Rangepicker
        maxWidth={235}
        placeholder="Date Range"
        startDate={dates.dateFrom}
        endDate={dates.dateTo}
        onChange={handleRangeChange}
        id={'merchantsDateRange'}
      />
    </section>
  );
};

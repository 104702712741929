import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'auth/confirm2fa';

interface IConfirm2fa {
  twoFACode: number;
}

export const useConfirm2fa = (params: IPostCallbackParams<any>) =>
  usePostCallback<IConfirm2fa, any>(path, params);

import { FC } from 'react';
import { PlusAdminIcon } from '../../../../assets/icons/PlusAdminIcon';
import { Button } from '../../../../components/Button/Button';
import { Heading } from '../../../../components/Heading/Heading';
import styles from './AdminsHeader.module.scss';

interface IAdminsHeaderProps {
  openModal: () => void;
}

export const AdminsHeader: FC<IAdminsHeaderProps> = ({ openModal }) => {
  return (
    <section className={styles.header}>
      <div className={styles.headerRow}>
        <Heading headType={'h1'}>Super Admin Manage Admins</Heading>
        <Button variant="secondary" onClick={openModal} icon={<PlusAdminIcon />}>
          Create
        </Button>
      </div>
    </section>
  );
};

import { FC } from 'react';
import { IIcon } from '../types';

export const Ellipsis: FC<IIcon> = ({ width = 25, height = 11, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 5.5C1 3.01472 3.01472 1 5.5 1H19.5C21.9853 1 24 3.01472 24 5.5V5.5C24 7.98528 21.9853 10 19.5 10H5.5C3.01472 10 1 7.98528 1 5.5V5.5Z"
      fill="#E4F1FF"
    />
    <circle cx="6.5" cy="5.5" r="1.5" fill="#3190FF" />
    <circle cx="12.5" cy="5.5" r="1.5" fill="#3190FF" />
    <circle cx="18.5" cy="5.5" r="1.5" fill="#3190FF" />
    <path
      d="M5.5 1.25H19.5V0.75H5.5V1.25ZM19.5 9.75H5.5V10.25H19.5V9.75ZM5.5 9.75C3.15279 9.75 1.25 7.84721 1.25 5.5H0.75C0.75 8.12335 2.87665 10.25 5.5 10.25V9.75ZM23.75 5.5C23.75 7.84721 21.8472 9.75 19.5 9.75V10.25C22.1234 10.25 24.25 8.12335 24.25 5.5H23.75ZM19.5 1.25C21.8472 1.25 23.75 3.15279 23.75 5.5H24.25C24.25 2.87665 22.1234 0.75 19.5 0.75V1.25ZM5.5 0.75C2.87665 0.75 0.75 2.87665 0.75 5.5H1.25C1.25 3.15279 3.15279 1.25 5.5 1.25V0.75Z"
      fill="#E4F1FF"
    />
  </svg>
);

import { FC } from 'react';
import { Cell, Pie, PieChart as RCPieChart, PieProps, AreaProps } from 'recharts';
import { COLORS } from './constants';

export interface IPieData {
  key: string;
  value: number;
  color: string;
}

interface IPieChart extends Partial<PieProps> {
  size: number;
  data?: IPieData[];
}

export const PieChart: FC<IPieChart> = ({ data, size }) => {
  return (
    <RCPieChart width={size} height={size}>
      <Pie
        dataKey={'value'}
        data={data}
        innerRadius={size / 2 - 42}
        outerRadius={size / 2}
        cornerRadius={8}
        startAngle={90}
        paddingAngle={4}
        endAngle={-270}
        fill="#8884d8"
      >
        {!data ? <></> : data.map((d) => <Cell key={d.key} fill={d.color} />)}
      </Pie>
    </RCPieChart>
  );
};

import { FC } from 'react';
import { IIcon } from '../types';

export const LeftArrow: FC<IIcon> = ({ width = 10, height = 16, fill = '#3190FF' }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85064 1.60573C8.84564 1.77573 8.77564 1.93573 8.65314 2.05323L2.70564 8.00073L8.65314 13.9482C8.77689 14.0695 8.84689 14.2345 8.84689 14.4082C8.84689 14.6682 8.68814 14.902 8.44689 14.9995C8.20564 15.0982 7.92939 15.0395 7.74814 14.8532L1.34814 8.45323C1.09814 8.20323 1.09814 7.79823 1.34814 7.54823L7.74814 1.14823C7.93064 0.958233 8.21189 0.898233 8.45564 1.00073C8.69939 1.10198 8.85564 1.34198 8.85064 1.60573Z"
      fill={fill}
      stroke={fill}
      strokeLinecap="round"
    />
  </svg>
);

import { components, DropdownIndicatorProps } from 'react-select';
import { DropdownIcon } from '../../../assets/icons/DropdownIcon';

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon />
    </components.DropdownIndicator>
  );
};

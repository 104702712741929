import { IApiKey } from '../../backend/_models/apiKey';
import { usePostRequest } from '../usePostRequest';

const path = 'security/getUserApiKeys';

export interface IGetUserApiKeys {
  userId: number;
}

export const useGetUserApiKeys = (input: IGetUserApiKeys) =>
  usePostRequest<IGetUserApiKeys, IApiKey[]>(path, input);

import { BlueGradient } from './BlueGradient/BlueGradient';
import { CrimsonGradient } from './CrimsonGradient/CrimsonGradient';
import { OrangeGradient } from './OrangeGradient/OrangeGradient';
import { BarChartColor } from './types';

export const addGradient = (color: BarChartColor, id: string, opacity?: number) => {
  switch (color) {
    case 'orange':
      return !opacity ? (
        <OrangeGradient id={id} />
      ) : (
        <OrangeGradient id={`${id}Op`} opacity={opacity} />
      );
    case 'crimson':
      return !opacity ? (
        <CrimsonGradient id={id} />
      ) : (
        <CrimsonGradient id={`${id}Op`} opacity={opacity} />
      );
    case 'blue':
      return !opacity ? (
        <BlueGradient id={id} />
      ) : (
        <BlueGradient id={`${id}Op`} opacity={opacity} />
      );
    default:
      return <OrangeGradient id={id} />;
  }
};

export const getXAxisColor = (color: BarChartColor) => {
  switch (color) {
    case 'orange':
      return '#FFEAC1';
    case 'crimson':
      return '#FFC1E3';
    case 'blue':
      return '#BDD8F9';
  }
};

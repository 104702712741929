import { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { FC, RefObject } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { CopyIcon } from '../../../assets/icons/CopyIcon';
import styles from './PopUp.module.scss';
import { useCloseOnOutsideClick } from '../../../core/hooks/popUp/useCloseOnOutsideClick';

interface IPopUpProps {
  text: string;

  onClose: () => void;
  onCopy: () => void;
}

export const PopUp: FC<IPopUpProps> = ({ text, onClose, onCopy }) => {
  const popupRef = useRef() as RefObject<HTMLDivElement>;
  const [turnRight, setTurnRight] = useState<boolean | undefined>(undefined);

  useCloseOnOutsideClick(popupRef, () => onClose());

  useEffect(() => {
    if (!popupRef || !('current' in popupRef)) return;
    const popupClientRect = popupRef.current?.getBoundingClientRect();
    const popupX = popupClientRect?.x;
    if (popupX && popupX < 0) setTurnRight(true);
    else setTurnRight(false);
  }, [popupRef]);

  useEffect(() => {
    document.body.addEventListener('mousedown', onClose);
    return () => document.body.removeEventListener('mousedown', onClose);
  }, [onClose]);

  const popupCx = cx(
    styles.wrapper,
    { [styles.wrapperRight]: turnRight === true },
    { [styles.wrapperLeft]: turnRight === false },
  );

  return (
    <div className={popupCx} ref={popupRef} onMouseDown={(e) => e.stopPropagation()}>
      <span>{text}</span>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <div className={styles.copyIcon}>
          <CopyIcon />
        </div>
      </CopyToClipboard>
      <CloseIcon className={styles.closeIcon} onClick={onClose} />
    </div>
  );
};

/* eslint-disable react/jsx-key */
import { ITableColumn } from '../../components/Table/Table';

export const getColumnsAdmin = (): ITableColumn[] => [
  {
    input: 'text',
    placeholder: 'id',
    width: 54,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'createdAt',
    width: 91,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'currency',
    width: 54,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'amount',
    width: 77,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'amountUSD',
    width: 90,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'rate',
    width: 90,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'feeRate',
    width: 90,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'state',
    width: 108,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'firstSeenAt',
    width: 96,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'deniedAt',
    width: 96,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'den_reason',
    width: 87,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'approvedAt',
    width: 100,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'app_reason',
    width: 100,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'confirmedAt',
    width: 103,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'block',
    width: 60,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'properties',
    width: 90,
    isFilter: false,
  },
];

export const columnsMerchant: ITableColumn[] = [
  {
    input: 'text',
    placeholder: 'id',
    width: 69,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'createdAt',
    width: 91,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'currency',
    width: 54,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'amount',
    width: 77,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'amount USD',
    width: 90,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'result',
    width: 100,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'approvedAt',
    width: 100,
    isFilter: false,
  },
  {
    input: 'date',
    placeholder: 'confirmedAt',
    width: 103,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'block',
    width: 60,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'properties',
    width: 90,
    isFilter: false,
  },
];

import styles from './Loader.module.scss';
import cx from 'classnames';
import { FC } from 'react';

interface ILoaderProps {
  size?: 'xs' | 'sm' | 'md';
  color?: 'primary' | 'secondary';
  margin?: number;
}

export const Loader: FC<ILoaderProps> = ({ size = 'md', color = 'primary', margin }) => {
  const loaderCx = cx(styles.loader, styles['loader-4'], styles[size], styles[color]);
  return (
    <div className={styles.wrapper}>
      <div style={{ margin: margin }} className={loaderCx}></div>
    </div>
  );
};

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const formatDateTime = (dateTime: Date) => {
  dayjs.extend(utc);
  const dDate = dayjs(dateTime);
  return {
    date: dDate.utc().format('YYYY-MM-DD').toString(),
    time: dDate.utc().format('HH:mm:ss').toString(),
  };
};

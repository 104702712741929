import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import { LoggedWrapper, UnloggedWrapper } from '../components/PageWrappers';
import { NeedTwoFAWrapper } from '../components/PageWrappers/NeedTwoFAWrapper/NeedTwoFAWrapper';
import { USER_ROLE } from '../core/backend/_models/user';
import { DashboardPage } from '../pages/DashboardPage/DashboardPage';
import { LoginPage } from '../pages/LoginPage/LoginPage';
import { MerchantsPage } from '../pages/users/MerchantsPage/MerchantsPage';
import { RequestsPage } from '../pages/RequestsPage/RequestsPage';
import { TransactionsPage } from '../pages/TransactionsPage/TransactionsPage';
import { TwoFactorAuthPage } from '../pages/TwoFactorAuthPage/TwoFactorAuthPage';
import { RootState } from '../store';
import { USER_STATE } from '../store/reducers/userReducer/types';
import { APP_ROUTES } from './routes';
import { AdminsPage } from '../pages/users/AdminsPage/AdminsPage';

interface IRoute {
  path: APP_ROUTES;
  element: JSX.Element;
  param?: string;
}

const unloggedRoutes: IRoute[] = [
  {
    path: APP_ROUTES.DASHBOARD,
    element: (
      <UnloggedWrapper>
        <LoginPage />
      </UnloggedWrapper>
    ),
  },
];

const needTwoFARoutes: IRoute[] = [
  {
    path: APP_ROUTES.TWO_FACTOR_AUTH,
    element: (
      <NeedTwoFAWrapper>
        <TwoFactorAuthPage />
      </NeedTwoFAWrapper>
    ),
  },
];

const loggedRoutes: IRoute[] = [
  {
    path: APP_ROUTES.DASHBOARD,
    element: (
      <LoggedWrapper>
        <DashboardPage />
      </LoggedWrapper>
    ),
  },
  {
    path: APP_ROUTES.MERCHANTS,
    element: (
      <LoggedWrapper>
        <MerchantsPage />
      </LoggedWrapper>
    ),
  },
  {
    path: APP_ROUTES.ADMINS,
    element: (
      <LoggedWrapper>
        <AdminsPage />
      </LoggedWrapper>
    ),
  },
  {
    path: APP_ROUTES.REQUESTS,
    element: (
      <LoggedWrapper>
        <RequestsPage />
      </LoggedWrapper>
    ),
  },
  {
    path: APP_ROUTES.TRANSACTIONS,
    element: (
      <LoggedWrapper>
        <TransactionsPage />
      </LoggedWrapper>
    ),
  },
];

const getRouteElems = (routes: IRoute[], userRole?: USER_ROLE) => {
  return routes.map((r) => {
    if (
      (r.path === APP_ROUTES.MERCHANTS ||
        r.path === APP_ROUTES.REQUESTS ||
        r.path === APP_ROUTES.ADMINS) &&
      userRole === USER_ROLE.MERCHANT
    )
      return <></>;
    return <Route key={r.path} path={`${r.path}${r.param ?? ''}`} element={r.element} />;
  });
};

export const UnloggedRoutes: React.FC = () => {
  return (
    <Routes>
      {getRouteElems(unloggedRoutes)}
      <Route path="*" element={<Navigate replace to={`${APP_ROUTES.DASHBOARD}`} />} />
    </Routes>
  );
};

export const LoggedRoutes: React.FC<{ userRole: USER_ROLE | undefined }> = ({ userRole }) => {
  return (
    <Routes>
      {getRouteElems(loggedRoutes, userRole)}
      <Route path="*" element={<Navigate replace to={`${APP_ROUTES.DASHBOARD}`} />} />
    </Routes>
  );
};

export const NeedTwoFARoutes: React.FC = () => {
  return (
    <Routes>
      {getRouteElems(needTwoFARoutes)}
      <Route path="*" element={<Navigate replace to={`${APP_ROUTES.TWO_FACTOR_AUTH}`} />} />
    </Routes>
  );
};

export const AppRoutes: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.user.info);
  const userRole = userInfo?.role;
  const enabled2FA = userInfo?.enabled2FA;
  const state = useSelector((store: RootState) => store.user.state);
  if (state === USER_STATE.UNLOGGED || state === USER_STATE.LOADING) return <UnloggedRoutes />;
  if (
    (state === USER_STATE.LOGGED || USER_STATE.CACHED) &&
    userRole === USER_ROLE.MERCHANT &&
    !enabled2FA
  )
    return <NeedTwoFARoutes />;
  return <LoggedRoutes userRole={userRole} />;
};

import { components, DropdownIndicatorProps } from 'react-select';
import { DropdownIcon } from '../../../assets/icons/DropdownIcon';

export const DropdownIndicatorPrimary = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon width={16} height={16} />
    </components.DropdownIndicator>
  );
};

import { Header } from './Header/Header';
import { Main } from './Main/Main';

export const LoginPage = () => {
  return (
    <>
      <Header />
      <Main />
    </>
  );
};

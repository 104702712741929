import { FC } from 'react';
import { IIcon } from '../types';

export const LogoIcon: FC<IIcon> = ({ width = '134', height = '44', className, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M41.9512 4.32248H29.4478L28.5954 10.5456C28.5954 10.5456 38.8236 10.9901 40.9047 10.9901C43.5115 10.9901 44.7901 12.7681 44.3639 15.8797L43.8989 19.8889C43.4338 23.5131 40.9047 26.5479 36.5878 26.5479H25.6122L23.0552 41.6612L29.5239 37.1302L30.178 32.771H37.271C40.207 32.771 47.9977 30.4278 49.6838 21.4811L50.5849 15.6582C51.2826 10.5456 48.957 4.32248 41.9512 4.32248Z"
      fill="#DAEBFE"
    />
    <path
      d="M91.3412 3.13033C91.1884 3.23283 91.1556 3.42645 90.9046 5.28294C90.8391 5.73852 90.7628 6.17133 90.73 6.22827C90.6864 6.28522 90.348 6.33078 89.9661 6.33078C89.3222 6.33078 89.2567 6.35356 89.213 6.58135C88.9184 8.27838 88.8747 8.17588 89.8024 8.267C90.3262 8.31255 90.4026 8.35811 90.3808 8.55173C90.3262 8.96175 90.1189 10.3627 90.0206 11.0005C89.9552 11.3422 89.8788 12.0939 89.846 12.6747C89.7805 13.6656 89.7915 13.7453 90.0534 14.2237C90.5008 15.0096 90.8937 15.1576 92.5307 15.1576H93.8949L93.9604 14.7021C93.9931 14.4515 94.0586 14.0529 94.1132 13.8137L94.1896 13.3923H93.7967C93.0982 13.3923 92.4652 13.1645 92.3125 12.8797C92.1706 12.6064 92.1924 12.0141 92.4107 10.6588C92.4652 10.3171 92.5635 9.69068 92.618 9.26927C92.6835 8.84786 92.7599 8.44923 92.8036 8.38089C92.8363 8.32394 93.2728 8.267 93.7749 8.267C94.3314 8.267 94.7352 8.21005 94.8444 8.11893C94.9426 8.05059 95.0408 7.7203 95.0845 7.41278C95.1172 7.09388 95.1609 6.7408 95.1718 6.61552C95.2045 6.39912 95.1281 6.38773 94.1241 6.35356L93.0546 6.31939L93.12 5.89798C93.3601 4.50846 93.4365 3.95037 93.4475 3.54035L93.4584 3.08477L92.4762 3.0506C91.9414 3.03921 91.4285 3.07338 91.3412 3.13033Z"
      fill="#3190FF"
    />
    <path
      d="M99.5338 6.31952C97.5476 6.62704 96.0634 7.971 95.4304 10.0325C95.2121 10.7273 95.2449 12.7774 95.474 13.3127C95.7687 13.9733 96.3908 14.6225 97.0674 14.9528C97.6349 15.2375 97.7877 15.2603 99.0427 15.2489C100.145 15.2489 100.516 15.192 100.985 15.0097C102.317 14.463 103.201 13.6771 103.724 12.5496C104.205 11.5131 104.281 11.1487 104.281 9.95278C104.281 8.36964 103.954 7.62932 102.917 6.91178C102.677 6.74094 102.306 6.54731 102.098 6.47898C101.596 6.29674 100.21 6.21702 99.5338 6.31952ZM101.149 8.28991C101.76 8.63159 101.924 9.04162 101.913 10.2033C101.891 11.6726 101.378 12.7204 100.44 13.233C99.9703 13.4835 98.7371 13.4835 98.3551 13.233C97.7877 12.8457 97.6785 12.5154 97.6894 11.3423C97.7003 10.4539 97.7549 10.1464 97.9732 9.63387C98.246 9.00745 98.5952 8.58604 99.0645 8.32408C99.381 8.13046 100.843 8.10768 101.149 8.28991Z"
      fill="#3190FF"
    />
    <path
      d="M57.1071 6.50171C55.0991 7.01424 54.0841 8.31264 53.5603 11.0233C53.4402 11.6725 53.4511 11.7978 53.8004 13.0507C53.9532 13.6315 54.4333 14.3035 54.9244 14.6566C55.5465 15.1008 56.485 15.2375 58.6895 15.1919L60.5885 15.1577L60.7194 14.4174C60.7958 14.0074 60.8613 13.6088 60.8613 13.529C60.8722 13.4265 60.5775 13.3924 59.759 13.3924C58.4822 13.3924 57.2381 13.2329 56.8343 13.0279C56.1358 12.6634 55.8739 11.9573 56.0158 10.8069C56.1031 10.0552 56.2995 9.5541 56.6706 9.08713C57.2053 8.42654 57.4782 8.34681 59.508 8.28986C60.5339 8.26708 61.4397 8.19875 61.527 8.15319C61.6798 8.06207 61.9854 6.59283 61.8871 6.41059C61.8108 6.27392 57.7073 6.34226 57.1071 6.50171Z"
      fill="#3190FF"
    />
    <path
      d="M63.1354 6.58154C63.1136 6.72961 63.0263 7.23074 62.9608 7.69771C62.8953 8.16468 62.7971 8.84805 62.7316 9.20113C62.6771 9.56559 62.5898 10.1578 62.5352 10.5109C62.4697 10.8754 62.3715 11.5588 62.306 12.0257C62.2296 12.4927 62.1314 13.1305 62.0878 13.4494C62.0332 13.7569 61.9568 14.2922 61.924 14.6225L61.8477 15.2148H62.9936H64.1285L64.2486 14.5656C64.3796 13.8253 64.5323 12.8344 64.707 11.5701C64.7834 11.1032 64.8816 10.4995 64.9361 10.2376C64.9907 9.97562 65.0344 9.62254 65.0344 9.44031C65.0344 9.26947 65.0889 8.92778 65.1435 8.69999C65.2745 8.22163 65.4491 8.16468 66.2894 8.28997C67.0424 8.40386 67.2279 8.6886 67.1952 9.57698L67.1625 10.2604H68.2538H69.3451L69.4761 9.34919C69.6616 8.18746 69.5198 7.4927 69.0287 6.96879C68.5157 6.44487 67.8937 6.33097 65.3508 6.33097C63.19 6.33097 63.1791 6.33097 63.1354 6.58154Z"
      fill="#3190FF"
    />
    <path
      d="M70.4956 6.92304C70.3865 7.56085 70.2446 8.47201 70.0481 9.86153C69.9718 10.3285 69.8735 10.9321 69.819 11.1941C69.7644 11.4561 69.7207 12.0825 69.7207 12.5836C69.7207 13.3012 69.7753 13.5973 69.9499 13.9276C70.2664 14.5312 70.3646 14.6223 70.7466 14.8046C71.4887 15.1463 71.8816 15.2146 73.2239 15.2146C73.9879 15.2146 74.6427 15.2488 74.6645 15.3057C74.73 15.431 74.4571 16.0688 74.2389 16.251C74.097 16.3763 73.5295 16.4219 71.6197 16.4447C69.7535 16.4788 69.1642 16.513 69.0659 16.6383C69.0005 16.718 68.9132 17.1508 68.8804 17.595L68.8149 18.4037H71.1722C74.3262 18.4037 75.3302 18.1531 76.1923 17.1622C76.7598 16.513 76.9672 15.8752 77.24 14.0757C77.3055 13.6087 77.4146 12.9367 77.4692 12.595C77.5238 12.2533 77.622 11.5813 77.6875 11.1144C77.8184 10.226 77.9276 9.49707 78.1131 8.32395C78.1677 7.9481 78.255 7.34445 78.2986 6.97999L78.3641 6.33079H77.2291H76.0832L75.9523 7.15083C75.7012 8.72258 75.6249 9.23511 75.5048 10.0893C75.4284 10.5563 75.3302 11.1599 75.2756 11.4219C75.2211 11.6839 75.1774 12.0597 75.1774 12.2533C75.1774 12.447 75.101 12.7772 75.0246 12.9936L74.8609 13.3923H74.0861C73.0384 13.3923 72.4054 13.21 72.2308 12.8798C72.078 12.5836 72.1326 11.3194 72.3399 10.0324C72.6673 8.10755 72.8638 6.52441 72.8092 6.41051C72.7874 6.36496 72.2745 6.33079 71.6851 6.33079H70.6047L70.4956 6.92304Z"
      fill="#3190FF"
    />
    <path
      d="M80.1472 6.58143C80.1035 6.85478 79.9617 7.75455 79.7543 9.12129C79.4706 11.0347 79.4051 11.4334 79.3178 12.0256C79.2632 12.3673 79.165 13.0051 79.0995 13.4493C79.034 13.8821 78.9358 14.5313 78.8813 14.873C78.8267 15.2147 78.7285 15.8867 78.663 16.3536C78.5975 16.8206 78.4884 17.4242 78.4338 17.6862C78.2483 18.5518 78.2046 18.5176 79.4815 18.5176C80.3109 18.5176 80.6492 18.4721 80.671 18.3696C80.6929 18.3012 80.7693 17.8457 80.8457 17.3787C80.9111 16.9117 81.0203 16.2283 81.0858 15.8639L81.2058 15.2147H82.7009C83.9232 15.2147 84.3161 15.1691 84.8836 14.9755C86.5533 14.406 87.5028 13.2329 88.0048 11.1145C88.5177 8.90489 87.5901 6.90033 85.844 6.46753C85.462 6.37642 84.3598 6.33086 82.7228 6.33086H80.1909L80.1472 6.58143ZM85.0255 8.54042C85.2001 8.66571 85.4293 8.95045 85.5493 9.18963C86.1168 10.3514 85.4183 12.447 84.2834 12.9937C83.6176 13.3126 81.9479 13.5063 81.6423 13.3012C81.5005 13.2101 81.5332 12.709 81.8279 10.8867C81.8933 10.4766 81.9916 9.81605 82.0461 9.40603C82.2098 8.16457 82.1225 8.23291 83.5085 8.28986C84.4689 8.32402 84.7854 8.36958 85.0255 8.54042Z"
      fill="#3190FF"
    />
    <path
      d="M115.544 15.8571C115.391 15.9596 115.358 16.1532 115.107 18.0097C115.042 18.4653 114.965 18.8981 114.933 18.9551C114.889 19.012 114.551 19.0576 114.168 19.0576C113.524 19.0576 113.459 19.0803 113.415 19.3081C113.121 21.0052 113.077 20.9027 114.005 20.9938C114.529 21.0393 114.605 21.0849 114.583 21.2785C114.529 21.6885 114.321 23.0895 114.223 23.7273C114.158 24.069 114.081 24.8207 114.048 25.4015C113.983 26.3924 113.994 26.4721 114.256 26.9505C114.703 27.7364 115.096 27.8844 116.734 27.8844H118.098L118.164 27.4289C118.197 27.1783 118.262 26.7797 118.317 26.5405L118.393 26.1191H118C117.301 26.1191 116.668 25.8913 116.516 25.6065C116.374 25.3332 116.395 24.7409 116.614 23.3856C116.668 23.0439 116.767 22.4175 116.821 21.9961C116.887 21.5747 116.963 21.176 117.007 21.1077C117.039 21.0507 117.476 20.9938 117.978 20.9938C118.535 20.9938 118.939 20.9368 119.048 20.8457C119.146 20.7774 119.245 20.4471 119.288 20.1396C119.321 19.8207 119.365 19.4676 119.376 19.3423C119.408 19.1259 119.332 19.1145 118.328 19.0803L117.258 19.0462L117.323 18.6248C117.563 17.2352 117.64 16.6772 117.651 16.2671L117.662 15.8116L116.679 15.7774C116.144 15.766 115.631 15.8002 115.544 15.8571Z"
      fill="#3190FF"
    />
    <path
      d="M52.6008 19.3082C52.5572 19.5816 52.4152 20.4813 52.2078 21.8481C51.924 23.7615 51.8585 24.1602 51.7712 24.7524C51.7166 25.0941 51.6183 25.7319 51.5529 26.1761C51.4874 26.6089 51.3891 27.2581 51.3345 27.5998C51.2799 27.9415 51.1817 28.6135 51.1162 29.0804C51.0507 29.5474 50.9415 30.151 50.887 30.413C50.7014 31.2786 50.6577 31.2444 51.9349 31.2444C52.7646 31.2444 53.103 31.1989 53.1248 31.0964C53.1466 31.028 53.223 30.5724 53.2995 30.1055C53.365 29.6385 53.4741 28.9551 53.5396 28.5907L53.6597 27.9415H55.1552C56.3778 27.9415 56.7708 27.8959 57.3385 27.7023C59.0087 27.1328 59.9584 25.9597 60.4605 23.8412C60.9736 21.6317 60.0457 19.6271 58.2991 19.1943C57.917 19.1032 56.8145 19.0576 55.1771 19.0576H52.6445L52.6008 19.3082ZM57.4804 21.2672C57.6551 21.3925 57.8843 21.6772 58.0044 21.9164C58.572 23.0781 57.8734 25.1738 56.7381 25.7205C56.0722 26.0394 54.402 26.233 54.0963 26.028C53.9544 25.9369 53.9872 25.4358 54.2819 23.6135C54.3474 23.2034 54.4457 22.5428 54.5002 22.1328C54.664 20.8914 54.5767 20.9597 55.963 21.0166C56.9237 21.0508 57.2402 21.0964 57.4804 21.2672Z"
      fill="#3190FF"
    />
    <path
      d="M62.4118 19.9004C62.3354 20.3674 62.3136 20.8002 62.3463 20.8685C62.4009 20.9482 63.1432 20.9938 64.6496 20.9938C67.4333 20.9938 67.7499 21.0963 67.7608 22.0416V22.4744H66.3744C63.6126 22.4744 62.4118 22.7706 61.7568 23.5906C61.211 24.2854 60.9927 24.9004 60.9927 25.7432C60.9927 26.6772 61.3202 27.3491 61.9424 27.6681C62.3136 27.8617 62.6847 27.8845 65.7959 27.9186L69.2345 27.9528L69.3764 27.1214C69.4528 26.6658 69.562 25.9596 69.6275 25.5496C69.693 25.1396 69.7803 24.5473 69.8349 24.2398C70.3371 21.2444 70.1842 20.2079 69.1363 19.5815C68.4267 19.1487 67.7062 19.0576 65.0426 19.0576H62.5537L62.4118 19.9004ZM67.2805 24.5473C67.4224 24.7865 67.3132 25.7091 67.1277 25.9368C66.9639 26.1191 66.8002 26.1419 65.4138 26.0963C63.6563 26.0507 63.3943 25.9596 63.3943 25.3902C63.3943 24.9687 63.7327 24.5701 64.1584 24.4904C64.8134 24.3651 67.2041 24.4107 67.2805 24.5473Z"
      fill="#3190FF"
    />
    <path
      d="M72.5385 19.6498C72.4294 20.2876 72.2875 21.1988 72.091 22.5883C72.0145 23.0553 71.9163 23.6589 71.8617 23.9209C71.8071 24.1829 71.7635 24.8093 71.7635 25.3104C71.7635 26.028 71.8181 26.3241 71.9927 26.6544C72.3093 27.258 72.4075 27.3491 72.7896 27.5314C73.5319 27.8731 73.9249 27.9414 75.2676 27.9414C76.0317 27.9414 76.6867 27.9756 76.7086 28.0325C76.774 28.1578 76.5011 28.7956 76.2828 28.9778C76.1409 29.1031 75.5733 29.1487 73.6629 29.1715C71.7962 29.2056 71.2067 29.2398 71.1085 29.3651C71.043 29.4448 70.9557 29.8776 70.9229 30.3218L70.8574 31.1305H73.2153C76.3701 31.1305 77.3744 30.8799 78.2368 29.889C78.8045 29.2398 79.0119 28.602 79.2848 26.8024C79.3503 26.3355 79.4595 25.6635 79.514 25.3218C79.5686 24.9801 79.6669 24.3081 79.7324 23.8412C79.8634 22.9528 79.9725 22.2239 80.1581 21.0507C80.2127 20.6749 80.3 20.0712 80.3437 19.7068L80.4092 19.0576H79.2739H78.1277L77.9967 19.8776C77.7456 21.4494 77.6692 21.9619 77.5491 22.8161C77.4727 23.2831 77.3744 23.8867 77.3199 24.1487C77.2653 24.4106 77.2216 24.7865 77.2216 24.9801C77.2216 25.1737 77.1452 25.504 77.0688 25.7204L76.905 26.1191H76.13C75.082 26.1191 74.4489 25.9368 74.2742 25.6065C74.1214 25.3104 74.176 24.0462 74.3834 22.7592C74.7109 20.8343 74.9074 19.2512 74.8528 19.1373C74.831 19.0917 74.3179 19.0576 73.7284 19.0576H72.6477L72.5385 19.6498Z"
      fill="#3190FF"
    />
    <path
      d="M82.2222 19.3084C82.2004 19.4564 82.113 19.9576 82.0475 20.4245C81.982 20.8915 81.8838 21.5749 81.8183 21.928C81.7637 22.2924 81.6764 22.8847 81.6218 23.2378C81.5563 23.6022 81.4581 24.2856 81.3926 24.7526C81.3162 25.2195 81.2179 25.8573 81.1742 26.1763C81.1197 26.4838 81.0432 27.0191 81.0105 27.3494L80.9341 27.9416H82.0803H83.2156L83.3357 27.2924C83.4667 26.5521 83.6195 25.5612 83.7942 24.297C83.8706 23.83 83.9688 23.2264 84.0234 22.9644C84.078 22.7024 84.1216 22.3494 84.1216 22.1671C84.1216 21.9963 84.1762 21.6546 84.2417 21.4154L84.3509 20.994H85.5626H86.7634L86.7088 21.5863C86.6433 22.1899 86.5232 23.0441 86.3049 24.4109C86.1957 25.117 86.0975 25.7093 85.8901 27.1785C85.8246 27.6683 85.8246 27.8619 85.9228 27.8961C85.9883 27.9302 86.5123 27.9302 87.08 27.9188L88.1061 27.8847L88.1825 27.4291C88.248 26.9849 88.3572 26.2788 88.5973 24.6956C88.6628 24.2514 88.7611 23.6136 88.8157 23.2719C88.8702 22.9302 88.9794 22.2696 89.0558 21.8027L89.1868 20.9712L90.202 21.0168C91.0426 21.051 91.2391 21.0965 91.381 21.2788C91.5666 21.5407 91.5993 22.6911 91.4465 23.42C91.3919 23.6819 91.2827 24.3084 91.2172 24.8095C91.1517 25.3106 91.0535 25.9485 90.9989 26.2332C90.9443 26.5179 90.8788 27.0191 90.8352 27.338L90.7697 27.9416H91.905H93.0403L93.1603 27.2924C93.2258 26.928 93.335 26.2674 93.4005 25.8346C93.466 25.3904 93.5752 24.707 93.6297 24.297C93.6952 23.887 93.7826 23.3061 93.8481 23.01C94.0009 22.1785 93.9681 20.846 93.7826 20.3904C93.5642 19.8437 92.9529 19.3539 92.2979 19.1945C91.9268 19.1034 90.2239 19.0578 87.0035 19.0578H82.2659L82.2222 19.3084Z"
      fill="#3190FF"
    />
    <path
      d="M98.6804 19.217C97.1194 19.6157 96.0605 20.7205 95.3401 22.7022C95.2418 22.9528 95.1872 23.5564 95.1982 24.2968C95.1982 25.6977 95.4056 26.2899 96.137 26.9847C97.0103 27.7933 97.3159 27.8845 99.783 27.9186L101.977 27.9528L102.108 27.4403C102.174 27.1555 102.228 26.7455 102.228 26.5291V26.1191H100.951C99.5756 26.1191 98.4185 25.971 98.0801 25.7432C97.8181 25.5838 97.6107 25.0826 97.6652 24.7296L97.698 24.4676L100.121 24.4334C102.861 24.3993 102.588 24.5132 102.719 23.34C102.774 22.8389 102.752 22.6453 102.643 22.5769C102.567 22.52 101.508 22.4744 100.296 22.4744C98.1237 22.4744 98.0801 22.4744 98.0801 22.2353C98.0801 21.9391 98.4512 21.381 98.7678 21.1874C98.9206 21.0963 99.7066 21.028 101.017 20.9938L103.036 20.9369L103.178 20.3674C103.254 20.0485 103.32 19.6271 103.32 19.4221V19.0576L101.279 19.069C100.067 19.0804 98.997 19.1373 98.6804 19.217Z"
      fill="#3190FF"
    />
    <path
      d="M104.652 19.3081C104.631 19.4562 104.543 19.9574 104.478 20.4243C104.412 20.8913 104.314 21.5747 104.248 21.9277C104.194 22.2922 104.107 22.8845 104.052 23.2375C103.986 23.602 103.888 24.2854 103.823 24.7523C103.746 25.2193 103.648 25.8571 103.604 26.176C103.55 26.4835 103.473 27.0189 103.441 27.3491L103.364 27.9414H104.51H105.646L105.766 27.2922C105.897 26.5519 106.05 25.561 106.224 24.2968C106.301 23.8298 106.399 23.2261 106.454 22.9642C106.508 22.7022 106.552 22.3491 106.552 22.1669C106.552 21.9961 106.606 21.6544 106.672 21.4152L106.781 20.9938H108.036C109.237 20.9938 109.303 21.0052 109.576 21.2899C109.816 21.5405 109.849 21.643 109.794 22.053C109.685 22.8503 109.412 24.6498 109.161 26.2899C109.095 26.6658 109.019 27.1897 108.986 27.4517L108.921 27.9414H110.089C111.41 27.9414 111.246 28.1009 111.453 26.5747C111.595 25.6293 111.704 24.889 111.923 23.6134C111.999 23.1464 112.065 22.2466 112.065 21.6088C112.065 20.5154 112.054 20.4471 111.748 20.0371C111.573 19.7979 111.202 19.4904 110.929 19.3537C110.46 19.1373 110.22 19.1145 107.567 19.0804L104.707 19.0462L104.652 19.3081Z"
      fill="#3190FF"
    />
    <path
      d="M121.922 19.2856C120.296 19.9462 119.543 22.2355 120.514 23.5908C120.984 24.24 121.278 24.3311 123.287 24.4109C124.564 24.4564 125.154 24.5248 125.285 24.6273C125.492 24.8095 125.514 25.5043 125.317 25.7093C125.044 26.0054 124.018 26.1193 121.813 26.1193H119.586L119.466 26.6204C119.401 26.9052 119.346 27.3152 119.346 27.5316V27.9416H122.272C124.029 27.9416 125.35 27.8961 125.612 27.8163C126.213 27.6455 126.813 27.2013 127.162 26.6774C127.49 26.1763 127.861 25.1056 127.861 24.6387C127.861 23.9211 127.304 23.1125 126.595 22.8277C126.409 22.748 125.492 22.6569 124.564 22.6113C123.407 22.5544 122.807 22.4747 122.687 22.3721C122.446 22.1671 122.468 21.6432 122.719 21.3243C122.927 21.051 122.959 21.051 125.47 20.994L128.014 20.9371L128.145 20.3106C128.221 19.969 128.287 19.5475 128.298 19.3653V19.0578H125.383C122.763 19.0692 122.403 19.092 121.922 19.2856Z"
      fill="#3190FF"
    />
    <path
      d="M130.29 19.4868H129.81L129.88 19.2174H131.171L131.1 19.4868H130.615L130.286 20.7622H129.962L130.29 19.4868Z"
      fill="#3190FF"
    />
    <path
      d="M131.323 19.2153H131.786L131.858 20.4024L132.547 19.2153H133.008L132.608 20.7622H132.3L132.635 19.5579L131.942 20.7622H131.609L131.527 19.5449L131.244 20.7622H130.924L131.323 19.2153Z"
      fill="#3190FF"
    />
    <path
      d="M24.9007 10.4799L25.8456 4.32248H12.704C9.21018 4.32248 1.80105 7.41028 0.114737 19.7615C-0.389219 24.0282 0.507241 32.5616 8.12473 32.5616H21.2082L22.1095 26.5255H10.0436C8.34279 26.5255 5.47896 25.3577 5.98777 20.8383L6.87454 15.3785C7.49963 12.6638 9.85466 10.4799 13.0965 10.4799H24.9007Z"
      fill="#3190FF"
    />
  </svg>
);

import { FC, useEffect } from 'react';
import styles from './SwitchAutoRefresh.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { updateAutoUpdate } from '../../store/reducers/autoUpdateReducer/autoUpdateReducer';

interface ISwitchAutoRefreshProps {
  refetch: () => void;
}
export const SwitchAutoRefresh: FC<ISwitchAutoRefreshProps> = ({ refetch }) => {
  const active = useSelector((state: RootState) => {
    return state.autoUpdate;
  }).active;
  const dispatch = useDispatch<AppDispatch>();
  function handleKeyPress() {
    dispatch(updateAutoUpdate({ active: !active }));
  }
  useEffect(() => {
    if (active) {
      const interval = setInterval(refetch, 60000);
      return () => clearInterval(interval);
    }
  }, [active, refetch]);

  return (
    <>
      <label className={styles.toggle}>
        <input type={'checkbox'} onChange={handleKeyPress} checked={active} />
        <span className={styles.switch} />
      </label>
      <span className={styles.text}>Auto updating</span>
    </>
  );
};

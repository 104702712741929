import { FC } from 'react';
import { LogoIcon } from '../../../assets/icons/LogoIcon';
import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import styles from './Header.module.scss';
import { NavMenu } from '../../NavMenu/NavMenu';
import { useNavigate } from 'react-router';
import { APP_ROUTES } from '../../../routes/routes';

interface IHeaderProps {
  noNav?: boolean;
}

export const Header: FC<IHeaderProps> = ({ noNav = false }) => {
  const nav = useNavigate();

  return (
    <header className={styles.header}>
      <section className={styles.headerLeft}>
        <LogoIcon
          className={styles.icon}
          onClick={!noNav ? () => nav(APP_ROUTES.DASHBOARD) : () => void 0}
        />
        {!noNav && <NavMenu />}
      </section>
      <HeaderMenu />
    </header>
  );
};

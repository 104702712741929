import { ChangeEvent, FC, useState } from 'react';
import { useChangeMyPasswordCallback } from '../../../core/hooks/users/changeMyPassword';
import { Heading } from '../../Heading/Heading';
import { Input } from '../../Input/Input';
import { customToast } from '../../Toast/Toast';
import { ModalButtons } from '../ModalButtons/ModalButtons';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import styles from './ModalNewPassword.module.scss';

interface IModalNewPassword {
  enabled2FA?: boolean;

  onClose: () => void;
}

export const ModalNewPassword: FC<IModalNewPassword> = ({ enabled2FA, onClose }) => {
  const [innerLoad, setInnerLoad] = useState(false);
  const [user, setUser] = useState({
    enabled2FA: enabled2FA,
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    twoFACode: '',
  });
  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUser((o) => {
      return { ...o, [e.target.id]: e.target.value };
    });
  const onResult = async () => {
    customToast('Password changed');
    onClose();
  };
  const onError = (e: string) => customToast(e);

  const changePassword = useChangeMyPasswordCallback({
    onResult: onResult,
    setLoading: setInnerLoad,
    onError: onError,
  });

  return (
    <ModalWrapper onClose={onClose} className={styles.modal}>
      <Heading variant="modal" headType="h2">
        Change password
      </Heading>
      <Input
        variant="secondary"
        placeholder="Old password"
        value={user.oldPassword}
        id="oldPassword"
        onChange={onChange}
        disabled={innerLoad}
      />
      <Input
        variant="secondary"
        placeholder="New password"
        value={user.newPassword}
        id="newPassword"
        onChange={onChange}
        disabled={innerLoad}
      />
      <Input
        variant="secondary"
        placeholder="Repeat new password"
        value={user.repeatNewPassword}
        id="repeatNewPassword"
        onChange={onChange}
        disabled={innerLoad}
      />
      {user.enabled2FA && (
        <Input
          variant="secondary"
          placeholder="2FA code"
          value={user.twoFACode}
          id="twoFACode"
          onChange={onChange}
          disabled={innerLoad}
        />
      )}
      <ModalButtons
        actionButtonName={'Save'}
        loading={innerLoad}
        onClose={onClose}
        icon={false}
        onAction={() => {
          if (user.newPassword !== user.repeatNewPassword) {
            customToast('Passwords do not match');
            return;
          }
          changePassword({
            newPassword: user.newPassword,
            oldPassword: user.oldPassword,
            twoFACode: user.twoFACode,
          });
        }}
      />
    </ModalWrapper>
  );
};

import { IUser } from '../../backend/_models/user';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'users/set-is-banned';

export interface SetIsBannedInput {
  userId: number;
  isBanned: boolean;
}

export const useSetBannedCallback = (params: IPostCallbackParams<IUser>) =>
  usePostCallback<SetIsBannedInput, IUser>(path, params);

import { FC } from 'react';
import { useGetMainStatistics } from '../../../core/hooks/stats/getMainStatistics';
import { MainInfoChart } from './MainInfoChart/MainInfoChart';
import styles from './DashboardMainInfo.module.scss';
import { MainInfoCards } from './MainInfoCards/MainInfoCards';
import { IDateRangeProps } from '../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useReloader } from './useReloader';
import { USER_ROLE } from '../../../core/backend/_models/user';
import { timeToUtc } from '../../../utils/timeToUtc';
import { customToast } from '../../../components/Toast/Toast';

export const DashboardMainInfo: FC<IDateRangeProps & { role: USER_ROLE | undefined }> = ({
  beginDate,
  endDate,
  reloader,
  role,
}) => {
  const merchantId = useSelector((state: RootState) => state.merchant.id);
  const rightTime = timeToUtc({ from: beginDate, to: endDate });

  const { data, loading, error, reload } = useGetMainStatistics({
    beginDate: rightTime.beginDate ?? beginDate,
    endDate: rightTime.endDate ?? endDate,
    currency: 'BTC',
    userId: merchantId,
  });

  useReloader(reloader, reload);

  if (!loading && error) customToast(error);

  return (
    <section className={styles.wrapper}>
      <MainInfoChart stats={data} loading={loading} role={role} />
      <MainInfoCards stats={data} loading={loading} />
    </section>
  );
};

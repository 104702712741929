import { IUser } from '../../backend/_models/user';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'users/changePassword';

export interface IChangePasswordInput {
  userId: number;
  password: string;
}

export const useChangePasswordCallback = (params: IPostCallbackParams<IUser>) =>
  usePostCallback<IChangePasswordInput, IUser>(path, params);

import { FC, HTMLAttributes, ReactNode } from 'react';
import styles from './ScrollBar.module.scss';
import cx from 'classnames';

interface IScrollBarProps extends HTMLAttributes<HTMLDivElement> {
  orientation?: 'vertical' | 'horizontal';
  variant?: 'primary' | 'secondary';
  color?: 'white' | 'blue';
  hidden?: boolean;
  maxHeight?: string;
  noPaddingBottom?: boolean;
  children: ReactNode | JSX.Element;
}

export const ScrollBar: FC<IScrollBarProps> = ({
  orientation,
  variant = 'primary',
  color = 'white',
  maxHeight,
  children,
  hidden,
  noPaddingBottom = false,
  className,
  ...props
}) => {
  const scrollbarCx = cx(
    className,
    styles.scroll,
    styles[color],
    { [styles[`${orientation}`]]: orientation },
    { [styles[`${variant}`]]: variant },
    { [styles['hidden']]: hidden },
    { [styles[`${variant}Padded`]]: !noPaddingBottom },
  );
  return (
    <div style={{ maxHeight: maxHeight }} className={scrollbarCx} {...props}>
      {children}
    </div>
  );
};

import { FC } from 'react';
import { IIcon } from '../types';

export const CloseIcon: FC<IIcon> = ({ width = 12, height = 12, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.617073 12C0.459 12 0.300927 11.9399 0.180827 11.8189C-0.0602758 11.5778 -0.0602758 11.1869 0.180827 10.9458L10.9461 0.180823C11.1872 -0.0602742 11.5781 -0.0602742 11.8192 0.180823C12.0603 0.42192 12.0603 0.812798 11.8192 1.05405L1.05407 11.8189C0.933069 11.9391 0.774996 12 0.617073 12Z"
      fill="white"
    />
    <path
      d="M11.3831 12C11.225 12 11.0671 11.9399 10.9468 11.8189L0.180827 1.05405C-0.0602758 0.812798 -0.0602758 0.42192 0.180827 0.180823C0.42193 -0.0602742 0.812819 -0.0602742 1.05407 0.180823L11.8192 10.9458C12.0603 11.1869 12.0603 11.5778 11.8192 11.8189C11.6982 11.9391 11.5402 12 11.3831 12Z"
      fill="white"
    />
  </svg>
);

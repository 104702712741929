import { FC, SyntheticEvent } from 'react';
import cx from 'classnames';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { renderCustomHeader } from '../common/DatepickerHeader/DatepickerHeader';
import { IDateProps } from '../common/types';
import '../common/ReactDatePicker.scss';
import { DatepickerWrapper } from '../common/DatepickerWrapper/DatepickerWrapper';
import styles from './Datepicker.module.scss';
import { getMaxDate } from '../common/getMaxDate';

interface IDatepickerProps extends IDateProps {
  onChange: (date: Date | null, event: SyntheticEvent<any, Event> | undefined) => void;
  date?: Date | string | null;
  isTableView?: boolean;
}

export const Datepicker: FC<IDatepickerProps> = ({
  id,
  date,
  maxWidth,
  disabled,
  onChange,
  className,
  placeholder,
  isTableView = false,
  isFilter,
}) => {
  const selected = date ? new Date(date) : null;
  const dpClassName = cx(
    styles.datepicker,
    className,
    { [styles.tableView]: isTableView },
    { [styles.filterView]: isFilter },
  );
  const maxDate = getMaxDate();
  return (
    <DatepickerWrapper id={id} maxWidth={maxWidth} isTableView isFilter={isFilter}>
      <ReactDatePicker
        id={id}
        className={dpClassName}
        selected={selected}
        onChange={onChange}
        shouldCloseOnSelect={false}
        disabled={disabled}
        dateFormat="yyyy-MM-dd"
        placeholderText={placeholder}
        renderCustomHeader={renderCustomHeader}
        formatWeekDay={(day) => day.substring(0, 1)}
        maxDate={maxDate}
      />
    </DatepickerWrapper>
  );
};

import { HTMLAttributes, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ModalContent } from './ModalContent/ModalContent';

export interface IModal {
  onClose: () => void;
}

export interface IModalWrapperProps extends IModal, HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | ReactNode;
}

export const ModalWrapper: React.FC<IModalWrapperProps> = ({ onClose, children, className }) => {
  const content = (
    <ModalContent onClose={onClose} className={className}>
      {children}
    </ModalContent>
  );
  return createPortal(content, document.body);
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCachedUser } from '../../../core/localStorage/cachedUser';
import { userLoadAction, userLoginAction, userLogoutAction } from '../../actions/userActions';
import { UserReducerState, USER_STATE } from './types';

const cachedUser = getCachedUser();
const initialState: UserReducerState = {
  info: cachedUser,
  state: cachedUser ? USER_STATE.CACHED : USER_STATE.LOADING,
  error: undefined,
};

export const userLoad = createAsyncThunk('users/load', userLoadAction);
export const userLogin = createAsyncThunk('users/login', userLoginAction);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userLogout: userLogoutAction,
  },
  extraReducers: (builder) => {
    builder.addCase(userLoad.fulfilled, (_, { payload }) => {
      return payload;
    });
    builder.addCase(userLogin.fulfilled, (_, { payload }) => {
      return payload;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload as string;
      } else {
        state.info = undefined;
        state.state = USER_STATE.UNLOGGED;
        state.error = action.error.message;
      }
    });
  },
});

export const { userLogout } = usersSlice.actions;
export const userReducer = usersSlice.reducer;

import { ITableColumn } from '../../Table/Table';

export const columns: ITableColumn[] = [
  {
    input: 'text',
    placeholder: 'user',
    width: 140,
    maxWidth: 140,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'email',
    width: 140,
    maxWidth: 140,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'enabled',
    width: 80,
    isFilter: false,
  },
  {
    input: 'none',
    width: 80,
    isFilter: false,
  },
  {
    input: 'none',
    width: 80,
    isFilter: false,
  },
];

import { IAdmin } from '../../backend/_models/user';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'users/create-admin-by-admin';

export interface ICreateAdmin {
  login: string;
  password: string;
  email: string;
}

export const useCreateAdmin = (params: IPostCallbackParams<any>) =>
  usePostCallback<ICreateAdmin, IAdmin>(path, params);

import { ITransactionItem } from '../../backend/_models/stats';
import { usePostRequest } from '../usePostRequest';

const path = 'statistics/get-request-statistic';

export interface IGetRequestStatistics {
  status: 'YEARLY' | 'MONTHLY' | 'DAILY';
  userId?: number;
}

export const useGetRequestStatistics = (input: IGetRequestStatistics) =>
  usePostRequest<IGetRequestStatistics, ITransactionItem[]>(path, input);

import { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../../../assets/icons/CopyIcon';
import { IPopUpMultipleItem } from '../PopUpMultiple';
import styles from './PopUpMultipleItem.module.scss';

export const PopUpMultipleItem: FC<{ item: IPopUpMultipleItem }> = ({ item }) => {
  return (
    <section className={styles.item}>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{item.label}:</span>
      </div>
      <div className={styles.valueWrapper}>
        <span className={styles.value}>{item.value}</span>
        <CopyToClipboard text={item.value} onCopy={item.onCopy}>
          <div className={styles.copyIcon}>
            <CopyIcon />
          </div>
        </CopyToClipboard>
      </div>
    </section>
  );
};

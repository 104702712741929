import { FC, useState } from 'react';
import {
  Bar,
  BarChart as RCBarChart,
  BarProps,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import './BarChart.scss';
import { addGradient, getXAxisColor } from './getColors';
import { BarChartColor } from './types';

export interface IBarData {
  key: string;
  value: number;
}

interface IBarChart extends Partial<BarProps> {
  id: string;
  width: number;
  height: number;
  color?: BarChartColor;
  data?: IBarData[];
  rotateXAxis?: boolean;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="value">{payload[0].value.toLocaleString()}</p>
        <p className="label">{payload[0].payload.label}</p>
      </div>
    );
  }

  return null;
};

export const BarChart: FC<IBarChart> = ({
  id,
  width,
  height,
  data,
  color = 'orange',
  rotateXAxis = false,
}) => {
  const formatXTick = (tick: any) => {
    const tickDivided = tick / 1000;
    if (tickDivided > 1) return String(tickDivided) + 'K';
    return tick;
  };
  const [focusBar, setFocusBar] = useState<number | null>(null);
  const [mouseLeave, setMouseLeave] = useState(true);

  return (
    <RCBarChart
      width={width}
      height={height}
      data={data}
      barCategoryGap={21}
      barSize={21}
      margin={{ bottom: rotateXAxis ? 26 : 0 }}
      onMouseLeave={(state) => {
        setFocusBar(null);
        setMouseLeave(true);
      }}
      onMouseMove={(state) => {
        if (state.isTooltipActive) {
          setFocusBar(state.activeTooltipIndex ? state.activeTooltipIndex : null);
          setMouseLeave(false);
        } else {
          setFocusBar(null);
          setMouseLeave(true);
        }
      }}
    >
      <CartesianGrid vertical={false} stroke="#F2F2F2" />
      <XAxis
        dataKey={'key'}
        angle={rotateXAxis ? -45 : undefined}
        dx={rotateXAxis ? -4 : 0}
        textAnchor={rotateXAxis ? 'end' : 'middle'}
        interval={0}
        tick={{ fill: '#000000' }}
        tickLine={false}
        stroke={getXAxisColor(color)}
      />
      <YAxis
        tick={{ dx: -12, fill: '#000000' }}
        tickLine={false}
        stroke="#F2F2F2"
        tickFormatter={formatXTick}
        axisLine={false}
      />
      <Tooltip cursor={false} content={<CustomTooltip />} />
      <defs>{addGradient(color, id)}</defs>
      <defs>{addGradient(color, id, 0.3)}</defs>
      <Bar dataKey={'value'} fill={`url(#${id})`} radius={[5, 5, 0, 0]}>
        {data &&
          data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index || mouseLeave ? `url(#${id})` : `url(#${id}Op)`}
            />
          ))}
      </Bar>
    </RCBarChart>
  );
};

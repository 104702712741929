import { FC } from 'react';
import { IIcon } from '../types';

export const LogoIconLarge: FC<IIcon> = ({ width = '173', height = '56', className, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M54.1608 5.50146H38.0183L36.9179 13.4218C36.9179 13.4218 50.1229 13.9875 52.8097 13.9875C56.1752 13.9875 57.8258 16.2505 57.2756 20.2106L56.6753 25.3133C56.0748 29.9258 52.8097 33.7884 47.2364 33.7884H33.0664L29.7651 53.0234L38.1165 47.2567L38.961 41.7087H48.1184C51.909 41.7087 61.9671 38.7264 64.1439 27.3397L65.3073 19.9287C66.208 13.4218 63.2056 5.50146 54.1608 5.50146Z"
      fill="#DAEBFE"
    />
    <path
      d="M117.926 3.98401C117.728 4.11447 117.686 4.3609 117.362 6.7237C117.277 7.30353 117.179 7.85437 117.137 7.92685C117.08 7.99933 116.643 8.05731 116.15 8.05731C115.319 8.05731 115.234 8.0863 115.178 8.37622C114.798 10.5361 114.741 10.4056 115.939 10.5216C116.615 10.5796 116.714 10.6376 116.686 10.884C116.615 11.4058 116.348 13.1888 116.221 14.0006C116.136 14.4354 116.038 15.3922 115.995 16.1314C115.911 17.3926 115.925 17.494 116.263 18.1029C116.841 19.1031 117.348 19.2915 119.461 19.2915H121.223L121.307 18.7117C121.349 18.3928 121.434 17.8854 121.504 17.581L121.603 17.0447H121.096C120.194 17.0447 119.377 16.7548 119.18 16.3924C118.996 16.0445 119.025 15.2907 119.306 13.5657C119.377 13.1308 119.504 12.3336 119.574 11.7972C119.659 11.2609 119.757 10.7535 119.814 10.6665C119.856 10.5941 120.419 10.5216 121.068 10.5216C121.786 10.5216 122.307 10.4491 122.448 10.3331C122.575 10.2462 122.702 9.82579 122.758 9.4344C122.801 9.02852 122.857 8.57916 122.871 8.4197C122.913 8.14428 122.815 8.12979 121.518 8.0863L120.138 8.04281L120.222 7.50647C120.532 5.73799 120.631 5.0277 120.645 4.50585L120.659 3.92602L119.391 3.88254C118.7 3.86804 118.038 3.91153 117.926 3.98401Z"
      fill="#3190FF"
    />
    <path
      d="M128.503 8.0429C125.938 8.43429 124.022 10.1448 123.205 12.7685C122.923 13.6528 122.965 16.262 123.261 16.9433C123.642 17.784 124.445 18.6103 125.318 19.0307C126.051 19.3931 126.248 19.4221 127.869 19.4076C129.292 19.4076 129.771 19.3351 130.376 19.1032C132.095 18.4074 133.237 17.4071 133.913 15.9721C134.533 14.653 134.632 14.1891 134.632 12.667C134.632 10.6521 134.209 9.70991 132.87 8.79668C132.56 8.57924 132.081 8.33282 131.814 8.24584C131.165 8.01391 129.376 7.91244 128.503 8.0429ZM130.588 10.5507C131.377 10.9855 131.588 11.5074 131.574 12.9859C131.546 14.8559 130.884 16.1895 129.672 16.8418C129.066 17.1607 127.474 17.1607 126.981 16.8418C126.248 16.349 126.107 15.9286 126.121 14.4355C126.136 13.3049 126.206 12.9135 126.488 12.2612C126.84 11.4639 127.291 10.9276 127.897 10.5942C128.305 10.3477 130.193 10.3187 130.588 10.5507Z"
      fill="#3190FF"
    />
    <path
      d="M73.7276 8.27487C71.1351 8.92718 69.8248 10.5797 69.1485 14.0297C68.9935 14.8559 69.0076 15.0154 69.4585 16.6099C69.6557 17.3492 70.2757 18.2045 70.9097 18.6538C71.7128 19.2192 72.9245 19.3931 75.7706 19.3351L78.2222 19.2916L78.3913 18.3494C78.4899 17.8276 78.5744 17.3202 78.5744 17.2187C78.5885 17.0883 78.2081 17.0448 77.1514 17.0448C75.5029 17.0448 73.8967 16.8419 73.3754 16.5809C72.4736 16.1171 72.1355 15.2183 72.3186 13.7543C72.4314 12.7975 72.685 12.1597 73.164 11.5654C73.8544 10.7247 74.2067 10.6232 76.8273 10.5507C78.1517 10.5217 79.3212 10.4347 79.4339 10.3768C79.6312 10.2608 80.0257 8.39084 79.8989 8.15891C79.8002 7.98496 74.5025 8.07193 73.7276 8.27487Z"
      fill="#3190FF"
    />
    <path
      d="M81.5105 8.37649C81.4823 8.56493 81.3696 9.20274 81.285 9.79707C81.2005 10.3914 81.0737 11.2611 80.9892 11.7105C80.9187 12.1744 80.806 12.9281 80.7355 13.3775C80.651 13.8414 80.5242 14.7111 80.4397 15.3055C80.341 15.8998 80.2142 16.7115 80.1579 17.1174C80.0874 17.5088 79.9888 18.1901 79.9465 18.6105L79.8479 19.3643H81.3273H82.7926L82.9476 18.538C83.1167 17.5958 83.314 16.3346 83.5394 14.7256C83.638 14.1313 83.7648 13.363 83.8353 13.0296C83.9057 12.6962 83.9621 12.2468 83.9621 12.0149C83.9621 11.7975 84.0325 11.3626 84.103 11.0727C84.272 10.4639 84.4975 10.3914 85.5824 10.5508C86.5546 10.6958 86.7941 11.0582 86.7518 12.1889L86.7096 13.0586H88.1185H89.5275L89.6966 11.899C89.9361 10.4204 89.7529 9.53615 89.1189 8.86934C88.4567 8.20254 87.6536 8.05758 84.3707 8.05758C81.5809 8.05758 81.5668 8.05758 81.5105 8.37649Z"
      fill="#3190FF"
    />
    <path
      d="M91.0131 8.81115C90.8722 9.62291 90.689 10.7826 90.4354 12.5511C90.3368 13.1454 90.21 13.9136 90.1395 14.2471C90.0691 14.5805 90.0127 15.3777 90.0127 16.0155C90.0127 16.9288 90.0831 17.3057 90.3086 17.726C90.7172 18.4943 90.844 18.6103 91.3371 18.8422C92.2952 19.2771 92.8024 19.364 94.5355 19.364C95.5217 19.364 96.3671 19.4075 96.3953 19.48C96.4798 19.6395 96.1276 20.4512 95.8458 20.6832C95.6626 20.8426 94.93 20.9006 92.4643 20.9296C90.055 20.9731 89.2941 21.0166 89.1673 21.176C89.0828 21.2775 88.9701 21.8283 88.9278 22.3937L88.8433 23.4229H91.8866C95.9585 23.4229 97.2548 23.1039 98.3678 21.8428C99.1005 21.0166 99.3682 20.2048 99.7204 17.9145C99.805 17.3201 99.9459 16.4649 100.016 16.03C100.087 15.5952 100.214 14.7399 100.298 14.1456C100.467 13.0149 100.608 12.0872 100.848 10.5941C100.918 10.1158 101.031 9.34749 101.087 8.88363L101.172 8.05737H99.7063H98.2269L98.0579 9.10107C97.7338 11.1015 97.6352 11.7538 97.4802 12.841C97.3816 13.4353 97.2548 14.2036 97.1843 14.537C97.1139 14.8704 97.0575 15.3487 97.0575 15.5952C97.0575 15.8416 96.9589 16.262 96.8602 16.5374L96.6489 17.0447H95.6485C94.2959 17.0447 93.4787 16.8128 93.2533 16.3924C93.0561 16.0155 93.1265 14.4065 93.3942 12.7685C93.8169 10.3187 94.0705 8.3038 94.0001 8.15884C93.9719 8.10086 93.3097 8.05737 92.5488 8.05737H91.154L91.0131 8.81115Z"
      fill="#3190FF"
    />
    <path
      d="M103.473 8.37634C103.417 8.72424 103.234 9.8694 102.966 11.6089C102.6 14.0442 102.515 14.5515 102.403 15.3053C102.332 15.7402 102.205 16.5519 102.121 17.1173C102.036 17.6681 101.909 18.4944 101.839 18.9292C101.769 19.3641 101.642 20.2194 101.557 20.8137C101.473 21.408 101.332 22.1763 101.261 22.5097C101.022 23.6114 100.965 23.5679 102.614 23.5679C103.685 23.5679 104.122 23.5099 104.15 23.3794C104.178 23.2925 104.277 22.7126 104.375 22.1183C104.46 21.524 104.601 20.6542 104.685 20.1904L104.84 19.3641H106.77C108.348 19.3641 108.856 19.3061 109.588 19.0597C111.744 18.3349 112.97 16.8419 113.618 14.1456C114.28 11.3335 113.083 8.78222 110.828 8.23138C110.335 8.11542 108.912 8.05743 106.799 8.05743H103.53L103.473 8.37634ZM109.771 10.8696C109.997 11.0291 110.293 11.3915 110.448 11.6959C111.18 13.1744 110.279 15.8416 108.813 16.5374C107.954 16.9433 105.798 17.1898 105.404 16.9288C105.221 16.8129 105.263 16.1751 105.643 13.8557C105.728 13.3339 105.855 12.4931 105.925 11.9713C106.136 10.3912 106.024 10.4782 107.813 10.5507C109.053 10.5942 109.461 10.6522 109.771 10.8696Z"
      fill="#3190FF"
    />
    <path
      d="M149.172 20.1818C148.975 20.3122 148.933 20.5586 148.608 22.9215C148.524 23.5013 148.425 24.0521 148.383 24.1246C148.327 24.1971 147.89 24.2551 147.396 24.2551C146.565 24.2551 146.48 24.2841 146.424 24.574C146.043 26.7338 145.987 26.6034 147.185 26.7193C147.862 26.7773 147.96 26.8353 147.932 27.0817C147.862 27.6036 147.594 29.3866 147.467 30.1983C147.382 30.6332 147.284 31.5899 147.241 32.3292C147.157 33.5903 147.171 33.6918 147.509 34.3006C148.087 35.3008 148.594 35.4893 150.708 35.4893H152.47L152.555 34.9094C152.597 34.5905 152.682 34.0832 152.752 33.7788L152.851 33.2424H152.343C151.441 33.2424 150.624 32.9525 150.427 32.5901C150.243 32.2422 150.272 31.4884 150.553 29.7634C150.624 29.3286 150.751 28.5313 150.821 27.995C150.906 27.4586 151.004 26.9513 151.061 26.8643C151.103 26.7918 151.667 26.7193 152.315 26.7193C153.034 26.7193 153.555 26.6469 153.696 26.5309C153.823 26.4439 153.95 26.0235 154.006 25.6322C154.049 25.2263 154.105 24.7769 154.119 24.6175C154.161 24.342 154.063 24.3275 152.766 24.2841L151.385 24.2406L151.469 23.7042C151.78 21.9357 151.878 21.2255 151.892 20.7036L151.906 20.1238L150.638 20.0803C149.947 20.0658 149.285 20.1093 149.172 20.1818Z"
      fill="#3190FF"
    />
    <path
      d="M67.9098 24.5741C67.8534 24.922 67.6702 26.0672 67.4025 27.8066C67.036 30.2419 66.9515 30.7493 66.8387 31.5031C66.7682 31.9379 66.6414 32.7497 66.5568 33.315C66.4723 33.8659 66.3454 34.6921 66.275 35.127C66.2045 35.5619 66.0777 36.4171 65.9931 37.0114C65.9086 37.6058 65.7676 38.374 65.6971 38.7074C65.4576 39.8091 65.4012 39.7656 67.0501 39.7656C68.1212 39.7656 68.5581 39.7077 68.5863 39.5772C68.6145 39.4902 68.7131 38.9104 68.8118 38.3161C68.8964 37.7217 69.0373 36.852 69.1218 36.3881L69.2769 35.5619H71.2077C72.7861 35.5619 73.2935 35.5039 74.0264 35.2575C76.1826 34.5327 77.4088 33.0396 78.0571 30.3434C78.7195 27.5312 77.5215 24.98 75.2666 24.4291C74.7733 24.3132 73.3499 24.2552 71.2359 24.2552H67.9662L67.9098 24.5741ZM74.2096 27.0674C74.4351 27.2268 74.731 27.5892 74.886 27.8936C75.6189 29.3722 74.7169 32.0394 73.2512 32.7352C72.3915 33.1411 70.2352 33.3875 69.8406 33.1266C69.6574 33.0106 69.6997 32.3728 70.0802 30.0535C70.1648 29.5316 70.2916 28.6909 70.3621 28.169C70.5735 26.589 70.4607 26.676 72.2506 26.7485C73.4908 26.7919 73.8995 26.8499 74.2096 27.0674Z"
      fill="#3190FF"
    />
    <path
      d="M80.576 25.3279C80.4774 25.9223 80.4492 26.4731 80.4915 26.5601C80.562 26.6615 81.5203 26.7195 83.4652 26.7195C87.059 26.7195 87.4678 26.85 87.4818 28.0531V28.604H85.692C82.1263 28.604 80.576 28.9809 79.7304 30.0246C79.0258 30.9088 78.7439 31.6916 78.7439 32.7642C78.7439 33.9529 79.1667 34.8081 79.97 35.214C80.4492 35.4605 80.9284 35.4894 84.945 35.5329L89.3845 35.5764L89.5677 34.5182C89.6663 33.9384 89.8073 33.0397 89.8918 32.5178C89.9764 31.996 90.0891 31.2422 90.1596 30.8508C90.8079 27.0384 90.6106 25.7193 89.2576 24.9221C88.3415 24.3712 87.4114 24.2553 83.9726 24.2553H80.7593L80.576 25.3279ZM86.8617 31.2422C87.045 31.5466 86.904 32.7208 86.6644 33.0107C86.453 33.2426 86.2416 33.2716 84.4518 33.2136C82.1827 33.1556 81.8445 33.0397 81.8445 32.3149C81.8445 31.7785 82.2814 31.2712 82.831 31.1697C83.6766 31.0103 86.7631 31.0682 86.8617 31.2422Z"
      fill="#3190FF"
    />
    <path
      d="M93.6504 25.0089C93.5094 25.8207 93.3262 26.9803 93.0725 28.7488C92.9739 29.3431 92.847 30.1114 92.7766 30.4448C92.7061 30.7782 92.6497 31.5755 92.6497 32.2133C92.6497 33.1265 92.7202 33.5034 92.9457 33.9238C93.3544 34.6921 93.4812 34.808 93.9745 35.0399C94.9329 35.4748 95.4402 35.5618 97.1737 35.5618C98.1603 35.5618 99.0059 35.6053 99.034 35.6778C99.1186 35.8372 98.7663 36.649 98.4844 36.8809C98.3012 37.0404 97.5683 37.0983 95.102 37.1273C92.692 37.1708 91.931 37.2143 91.8041 37.3738C91.7196 37.4752 91.6068 38.0261 91.5645 38.5914L91.48 39.6206H94.5242C98.5971 39.6206 99.8937 39.3017 101.007 38.0406C101.74 37.2143 102.008 36.4026 102.36 34.1122C102.445 33.5179 102.586 32.6626 102.656 32.2278C102.727 31.7929 102.853 30.9377 102.938 30.3433C103.107 29.2127 103.248 28.2849 103.488 26.7919C103.558 26.3135 103.671 25.5452 103.727 25.0814L103.812 24.2551H102.346H100.866L100.697 25.2988C100.373 27.2992 100.274 27.9515 100.119 29.0387C100.021 29.633 99.8937 30.4013 99.8233 30.7347C99.7528 31.0681 99.6964 31.5465 99.6964 31.7929C99.6964 32.0393 99.5978 32.4597 99.4991 32.7351L99.2877 33.2425H98.2871C96.9341 33.2425 96.1167 33.0105 95.8912 32.5902C95.6939 32.2133 95.7644 30.6043 96.0321 28.9662C96.455 26.5165 96.7086 24.5015 96.6382 24.3566C96.61 24.2986 95.9476 24.2551 95.1865 24.2551H93.7913L93.6504 25.0089Z"
      fill="#3190FF"
    />
    <path
      d="M106.152 24.5742C106.124 24.7627 106.011 25.4005 105.927 25.9948C105.842 26.5891 105.715 27.4589 105.631 27.9083C105.56 28.3721 105.447 29.1259 105.377 29.5753C105.292 30.0391 105.165 30.9089 105.081 31.5032C104.982 32.0975 104.855 32.9093 104.799 33.3152C104.729 33.7066 104.63 34.3879 104.588 34.8082L104.489 35.562H105.969H107.435L107.59 34.7358C107.759 33.7935 107.956 32.5324 108.181 30.9234C108.28 30.329 108.407 29.5608 108.477 29.2274C108.548 28.894 108.604 28.4446 108.604 28.2127C108.604 27.9952 108.675 27.5604 108.759 27.2559L108.9 26.7196H110.465H112.015L111.944 27.4734C111.86 28.2417 111.705 29.3288 111.423 31.0683C111.282 31.9671 111.155 32.7208 110.887 34.5908C110.803 35.2141 110.803 35.4605 110.93 35.504C111.014 35.5475 111.691 35.5475 112.424 35.533L113.748 35.4895L113.847 34.9097C113.932 34.3444 114.073 33.4456 114.383 31.4307C114.467 30.8654 114.594 30.0536 114.664 29.6188C114.735 29.1839 114.876 28.3431 114.975 27.7488L115.144 26.6906L116.454 26.7486C117.54 26.7921 117.793 26.8501 117.976 27.082C118.216 27.4154 118.258 28.8795 118.061 29.8072C117.991 30.1406 117.85 30.9379 117.765 31.5757C117.68 32.2135 117.554 33.0253 117.483 33.3876C117.413 33.75 117.328 34.3879 117.272 34.7937L117.187 35.562H118.653H120.119L120.274 34.7358C120.358 34.2719 120.499 33.4311 120.584 32.8803C120.668 32.315 120.809 31.4452 120.88 30.9234C120.964 30.4015 121.077 29.6622 121.162 29.2853C121.359 28.2272 121.317 26.5312 121.077 25.9513C120.795 25.2555 120.006 24.6322 119.16 24.4293C118.681 24.3133 116.483 24.2553 112.325 24.2553H106.208L106.152 24.5742Z"
      fill="#3190FF"
    />
    <path
      d="M127.401 24.4582C125.386 24.9655 124.018 26.3716 123.088 28.8939C122.961 29.2128 122.891 29.9811 122.905 30.9233C122.905 32.7063 123.173 33.46 124.117 34.3443C125.245 35.3735 125.639 35.4894 128.824 35.5329L131.657 35.5764L131.826 34.9241C131.911 34.5617 131.981 34.0399 131.981 33.7645V33.2426H130.332C128.557 33.2426 127.063 33.0542 126.626 32.7642C126.288 32.5613 126.02 31.9235 126.09 31.4741L126.132 31.1407L129.261 31.0972C132.799 31.0537 132.446 31.1987 132.615 29.7056C132.686 29.0678 132.658 28.8214 132.517 28.7344C132.418 28.662 131.051 28.604 129.487 28.604C126.682 28.604 126.626 28.604 126.626 28.2996C126.626 27.9227 127.105 27.2124 127.514 26.966C127.711 26.85 128.726 26.763 130.417 26.7195L133.024 26.647L133.207 25.9223C133.306 25.5164 133.391 24.98 133.391 24.7191V24.2553L130.755 24.2698C129.191 24.2842 127.81 24.3567 127.401 24.4582Z"
      fill="#3190FF"
    />
    <path
      d="M135.111 24.574C135.083 24.7625 134.97 25.4003 134.885 25.9946C134.801 26.5889 134.674 27.4587 134.589 27.9081C134.519 28.3719 134.406 29.1257 134.336 29.5751C134.251 30.0389 134.124 30.9087 134.04 31.503C133.941 32.0973 133.814 32.9091 133.758 33.315C133.687 33.7064 133.589 34.3877 133.546 34.808L133.448 35.5618H134.928H136.393L136.548 34.7356C136.717 33.7933 136.915 32.5322 137.14 30.9232C137.239 30.3288 137.366 29.5606 137.436 29.2272C137.507 28.8938 137.563 28.4444 137.563 28.2125C137.563 27.995 137.633 27.5602 137.718 27.2557L137.859 26.7194H139.48C141.03 26.7194 141.115 26.7339 141.467 27.0963C141.777 27.4152 141.819 27.5457 141.749 28.0675C141.608 29.0822 141.255 31.3725 140.931 33.4599C140.847 33.9383 140.748 34.6051 140.706 34.9385L140.621 35.5618H142.129C143.835 35.5618 143.623 35.7647 143.891 33.8223C144.074 32.6192 144.215 31.6769 144.497 30.0534C144.596 29.4591 144.68 28.3139 144.68 27.5022C144.68 26.1106 144.666 26.0236 144.271 25.5018C144.046 25.1973 143.567 24.806 143.214 24.632C142.608 24.3566 142.298 24.3276 138.874 24.2841L135.181 24.2406L135.111 24.574Z"
      fill="#3190FF"
    />
    <path
      d="M157.407 24.5452C155.307 25.386 154.335 28.2996 155.589 30.0246C156.195 30.8509 156.576 30.9669 159.169 31.0683C160.818 31.1263 161.579 31.2133 161.748 31.3437C162.016 31.5757 162.044 32.4599 161.79 32.7208C161.438 33.0977 160.113 33.2427 157.266 33.2427H154.391L154.236 33.8805C154.152 34.2429 154.081 34.7647 154.081 35.0402V35.562H157.858C160.127 35.562 161.833 35.504 162.171 35.4026C162.946 35.1851 163.721 34.6198 164.172 33.953C164.595 33.3152 165.074 31.9526 165.074 31.3582C165.074 30.445 164.355 29.4158 163.439 29.0534C163.2 28.9519 162.016 28.836 160.818 28.778C159.324 28.7055 158.549 28.604 158.394 28.4736C158.084 28.2127 158.112 27.5459 158.436 27.14C158.704 26.7921 158.746 26.7921 161.988 26.7196L165.271 26.6471L165.441 25.8499C165.539 25.415 165.624 24.8786 165.638 24.6467V24.2553H161.875C158.493 24.2698 158.027 24.2988 157.407 24.5452Z"
      fill="#3190FF"
    />
    <path
      d="M168.211 24.8013H167.59L167.681 24.4586H169.348L169.256 24.8013H168.63L168.205 26.4246H167.786L168.211 24.8013Z"
      fill="#3190FF"
    />
    <path
      d="M169.544 24.4558H170.141L170.235 25.9667L171.125 24.4558H171.719L171.203 26.4246H170.805L171.237 24.8918L170.342 26.4246H169.912L169.808 24.8754L169.442 26.4246H169.028L169.544 24.4558Z"
      fill="#3190FF"
    />
    <path
      d="M32.1479 13.3382L33.3678 5.50146H16.4014C11.8908 5.50146 2.32524 9.43139 0.148131 25.1511C-0.502499 30.5814 0.654871 41.4421 10.4894 41.4421H27.3808L28.5444 33.7599H12.9668C10.7709 33.7599 7.07359 32.2736 7.73047 26.5215L8.87533 19.5728C9.68236 16.1177 12.7228 13.3382 16.9081 13.3382H32.1479Z"
      fill="#3190FF"
    />
  </svg>
);

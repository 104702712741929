import { Button } from '../../../components/Button/Button';
import { IAdmin } from '../../../core/backend/_models/user';
import { setAdminBannedCB } from './AdminsPage';

type setIdCB = (id: number) => void;
type setEnabled2FACB = (value: boolean) => void;

export const mapAdmin =
  (setAdminBanned: setAdminBannedCB, securityId: setIdCB, setEnabled2FACB: setEnabled2FACB) =>
  (admin: IAdmin) => {
    const isBanned = admin.isBanned;
    return [
      <>{admin.id}</>,
      <>{admin.login}</>,
      <span key="isBanned">{isBanned ? 'False' : 'True'}</span>,
      <Button
        key="ban"
        size="largerXs"
        onClick={() => setAdminBanned({ isBanned: !isBanned, userId: admin.id })}
      >
        {isBanned ? 'Enable' : 'Disable'}
      </Button>,
      <Button
        key="secure"
        size="largerXs"
        variant="secondary"
        onClick={() => {
          securityId(admin.id);
          setEnabled2FACB(admin.enabled2FA ?? false);
        }}
      >
        Security
      </Button>,
    ];
  };

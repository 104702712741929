export const formatNumber = (
  number: number | string,
  isCurrency: boolean,
  noCurrencySign?: boolean,
) => {
  const formatObj = isCurrency
    ? Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : Intl.NumberFormat('en-US');
  const formattedNumber = formatObj.format(Number(number));
  return isCurrency && noCurrencySign ? formattedNumber.substring(1) : formattedNumber;
};

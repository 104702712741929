import { Key } from 'react';
import { BTCIcon } from '../../assets/icons/BTCIcon';
import { PopUp } from '../../components/PopUps/PopUp/PopUp';
import { PopUpController } from '../../components/PopUps/PopUpController/PopUpController';
import {
  IPopUpMultipleItem,
  PopUpMultiple,
} from '../../components/PopUps/PopUpMultiple/PopUpMultiple';
import { customToast } from '../../components/Toast/Toast';
import {
  ITrxRequestFull,
  ITrxRequestMerchant,
} from '../../core/backend/_models/transactions/requests';
import { formatDateTime } from '../../utils/formatDateTime';
import { formatNumber } from '../../utils/formatNumber';
import { IPopUpItem } from './RequestsPage';
import styles from './RequestsPage.module.scss';

const renderDateTime = (dateTime: Date, key: Key) => {
  if (!dateTime) return '–';
  const formattedDateTime = formatDateTime(dateTime);
  return (
    <p className={styles.dateTime} key={key}>
      <span>{formattedDateTime.date}</span>
      <span className={styles.time}>{formattedDateTime.time}</span>
    </p>
  );
};

const renderBlockInfo = (blockNumber: string, dateTime: Date) => {
  if (!dateTime) return '-';
  const formattedDateTime = formatDateTime(dateTime);
  return (
    <p className={styles.dateTime}>
      <span>{blockNumber}</span>
      <span className={styles.time}>{formattedDateTime.date}</span>
      <span className={styles.time}>{formattedDateTime.time}</span>
    </p>
  );
};

const renderItem = (item: number | string | JSX.Element) =>
  item ? (typeof item === 'number' ? formatNumber(item, false) : item) : '–';

const renderStatus = (status: string) =>
  status ? (status === 'REJECTED' ? 'removed' : status) : status;

const getPopUpIndex = (iRow: number | string, iItem: number | string) => `${iRow}_${iItem}`;

export const mapRequestAdmin = (
  openedPopUp: IPopUpItem | undefined,
  setOpenedPopUp: React.Dispatch<React.SetStateAction<IPopUpItem | undefined>>,
) => {
  const popup = openedPopUp ? (
    <PopUp
      text={openedPopUp.text}
      onClose={() => setOpenedPopUp(undefined)}
      onCopy={() => customToast(openedPopUp.successText)}
    />
  ) : (
    <></>
  );

  return (transaction: ITrxRequestFull, index: number) => {
    const handleCopy = (item: string) => customToast(`${item} copied`);

    const popupItems: IPopUpMultipleItem[] = [
      {
        label: 'Trx id',
        value: transaction.transactionResearch.txid,
        onCopy: () => handleCopy('Trx id'),
      },
      {
        label: 'Address',
        value: transaction.transactionResearch.targetAddress,
        onCopy: () => handleCopy('Address'),
      },
      {
        label: 'Api key',
        value: transaction.apiKey.apiKey,
        onCopy: () => handleCopy('Api key'),
      },
      {
        label: 'IP address',
        value: transaction.requestorIpAddress,
        onCopy: () => handleCopy('IP address'),
      },
    ];

    const popup = openedPopUp ? (
      <PopUpMultiple
        title="Properties"
        items={popupItems}
        onClose={() => setOpenedPopUp(undefined)}
      />
    ) : (
      <></>
    );

    const openedMyPopup = (serialNum: number) =>
      openedPopUp && openedPopUp.index === getPopUpIndex(index, serialNum);

    return [
      transaction.id,
      renderDateTime(transaction.createdAt, 'createdAt'),
      <div className={styles.currency} key="currency">
        <BTCIcon />
        <span> BTC </span>
      </div>,
      transaction.transactionResearch.amount,
      formatNumber(Number(transaction.transactionResearch.usdAmount), true, true),
      formatNumber(transaction.transactionResearch.rate, true, true),
      transaction.transactionResearch.dateStatuses.feeRate
        ? formatNumber(transaction.transactionResearch.dateStatuses.feeRate, false)
        : renderItem(transaction.transactionResearch.dateStatuses.feeRate),
      renderStatus(transaction.responseState).toLowerCase(),
      renderDateTime(transaction.transactionResearch.firstSeenAt, 'firstSeenAt'),
      renderDateTime(transaction.transactionResearch.dateStatuses.deniedDate, 'deniedAt'),
      renderItem(transaction.transactionResearch.dateStatuses.denyReason),
      renderDateTime(transaction.transactionResearch.dateStatuses.approvedDate, 'approvedAt'),
      renderItem(transaction.transactionResearch.dateStatuses.approvedReason),
      renderDateTime(transaction.transactionResearch.dateStatuses.confirmedDate, 'confirmedAt'),
      renderBlockInfo(
        transaction.transactionResearch.block,
        transaction.transactionResearch.blockTimestamp,
      ),

      <PopUpController
        key={'properties'}
        popup={openedMyPopup(1) ? popup : <></>}
        onClick={
          openedMyPopup(1)
            ? () => setOpenedPopUp(undefined)
            : () => {
                setOpenedPopUp({
                  index: getPopUpIndex(index, 1),
                  text: transaction.transactionResearch.txid,
                  successText: 'Txid copied successfully!',
                });
              }
        }
      />,
    ];
  };
};

export const mapRequestMerchant = (
  openedPopUp: IPopUpItem | undefined,
  setOpenedPopUp: React.Dispatch<React.SetStateAction<IPopUpItem | undefined>>,
) => {
  const popup = openedPopUp ? (
    <PopUp
      text={openedPopUp.text}
      onClose={() => setOpenedPopUp(undefined)}
      onCopy={() => customToast(openedPopUp.successText)}
    />
  ) : (
    <></>
  );

  return (transaction: ITrxRequestMerchant, index: number) => {
    const handleCopy = (item: string) => customToast(`${item} copied`);

    const popupItems: IPopUpMultipleItem[] = [
      {
        label: 'Trx id',
        value: transaction.trxId,
        onCopy: () => handleCopy('Trx id'),
      },
      {
        label: 'Address',
        value: transaction.targetAddress,
        onCopy: () => handleCopy('Address'),
      },
      {
        label: 'Trx hash',
        value: transaction.trxHash,
        onCopy: () => handleCopy('Trx hash'),
      },
    ];

    const popup = openedPopUp ? (
      <PopUpMultiple
        title="Properties"
        items={popupItems}
        onClose={() => setOpenedPopUp(undefined)}
      />
    ) : (
      <></>
    );

    const openedMyPopup = (serialNum: number) =>
      openedPopUp && openedPopUp.index === getPopUpIndex(index, serialNum);

    return [
      transaction.requestId,
      renderDateTime(transaction.requestDate, 'requestDate'),
      <div className={styles.currency} key="currency">
        <BTCIcon />
        <span> BTC </span>
      </div>,
      transaction.amount,
      formatNumber(transaction.usdAmount, true, true),
      formatNumber(transaction.rate, true, true),
      renderStatus(transaction.result).toLowerCase(),
      renderDateTime(transaction.approvedDate, 'approvedAt'),
      renderDateTime(transaction.confirmedDate, 'confirmedAt'),
      renderBlockInfo(transaction.block, transaction.blockTimestamp),

      renderItem(transaction.block),
      <PopUpController
        key={'properties'}
        popup={openedMyPopup(1) ? popup : <></>}
        onClick={
          openedMyPopup(1)
            ? () => setOpenedPopUp(undefined)
            : () => {
                setOpenedPopUp({
                  index: getPopUpIndex(index, 1),
                  text: transaction.trxId,
                  successText: 'Txid copied successfully!',
                });
              }
        }
      />,
    ];
  };
};

import { IUser } from '../../backend/_models/user';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'users/changeAdminPassword';

export interface IChangeAdminPasswordInput {
  userId: number;
  password: string;
}

export const useChangeAdminPasswordCallback = (params: IPostCallbackParams<IUser>) =>
  usePostCallback<IChangeAdminPasswordInput, IUser>(path, params);

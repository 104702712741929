import { Id, toast, ToastContainer, ToastContent } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss';

export const Toast = () => {
  return <ToastContainer autoClose={2500} hideProgressBar={true} closeButton={false} />;
};

export function customToast(content: ToastContent): Id {
  const toastId = content ? content.toString() : undefined;
  return toast(content, { toastId });
}

import { FC } from 'react';
import { IconColor, IIcon } from '../types';

export const DashIcon: FC<IIcon> = ({ width = 14, height = 3, fill = '#3190FF' }) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 0.106533V2.68182H0V0.106533H14Z" fill="#3190FF" />
  </svg>
);

import { ITableColumn } from '../../../components/Table/Table';

export const columns: ITableColumn[] = [
  {
    input: 'text',
    placeholder: 'id',
    width: 100,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'login',
    width: 140,
    isFilter: false,
  },
  {
    input: 'text',
    placeholder: 'enabled',
    width: 140,
    isFilter: false,
  },
  {
    input: 'none',
    width: 70,
    isFilter: false,
  },
  {
    input: 'none',
    width: 80,
    isFilter: false,
  },
];

import { FC, HTMLAttributes, Key, ReactNode } from 'react';
import styles from './StatisticsCard.module.scss';
import cx from 'classnames';
import { Heading } from '../Heading/Heading';
import { DashIcon } from '../../assets/icons/DashIcon';
import { ScrollBar } from '../ScrollBar/ScrollBar';

export interface IStatisticsCardProps extends HTMLAttributes<HTMLDivElement> {
  heading: string;
  headingSize?: 'sm' | 'lg';
  statValue?: number | string | JSX.Element | React.ReactNode;
  subTotal?: number | string;
  icon?: ReactNode | JSX.Element;
  key?: Key;
}

export const StatisticsCard: FC<IStatisticsCardProps> = ({
  heading,
  headingSize = 'sm',
  statValue = <DashIcon />,
  subTotal,
  icon,
  className,
  ...props
}) => {
  return (
    <div className={cx(className, styles.card, { [styles.cardIcon]: icon })} {...props}>
      <ScrollBar orientation="horizontal">
        {icon}
        <div className={styles.text}>
          <Heading headType={headingSize === 'sm' ? 'h4' : 'h3'} variant="secondary">
            {heading}
          </Heading>
          <span className={styles.statValue}>{statValue}</span>
          {subTotal && <span className={styles.subTotal}>{subTotal}</span>}
        </div>
      </ScrollBar>
    </div>
  );
};

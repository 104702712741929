import { FC } from 'react';
import { IconColor, IIcon } from '../types';

export const FiltersIcon: FC<IIcon> = ({
  width = 16,
  height = 16,
  color = IconColor.LIGHT_BLUE,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_1469)">
        <path
          d="M9.33333 5.33332C10.0697 5.33332 10.6667 4.73637 10.6667 3.99999C10.6667 3.26361 10.0697 2.66666 9.33333 2.66666C8.59695 2.66666 8 3.26361 8 3.99999C8 4.73637 8.59695 5.33332 9.33333 5.33332Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66667 4H8.00001"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6667 4H13.3333"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33333 9.33332C6.06971 9.33332 6.66667 8.73637 6.66667 7.99999C6.66667 7.26361 6.06971 6.66666 5.33333 6.66666C4.59695 6.66666 4 7.26361 4 7.99999C4 8.73637 4.59695 9.33332 5.33333 9.33332Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66667 8H4.00001"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66667 8H13.3333"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 13.3333C12.0697 13.3333 12.6667 12.7364 12.6667 12C12.6667 11.2636 12.0697 10.6667 11.3333 10.6667C10.597 10.6667 10 11.2636 10 12C10 12.7364 10.597 13.3333 11.3333 13.3333Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66667 12H10"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6667 12H13.3333"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1469">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

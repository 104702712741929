import { IUser } from '../../backend/_models/user';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'auth/disableAdmin2FA';

export interface ISetDisableAdminTwoFAInput {
  id: number;
}

export const useSetDisableAdminTwoFACallback = (params: IPostCallbackParams<IUser>) =>
  usePostCallback<ISetDisableAdminTwoFAInput, IUser>(path, params);

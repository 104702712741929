import { useCallback, useEffect, useMemo, useState } from 'react';
import { sendPost } from '../backend/sendPost';
import hash from 'object-hash';

export function usePostRequest<Input, Output>(path: string, input: Input) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Output | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const [reloader, setReloader] = useState(0);

  const inpHash = useMemo(() => {
    return hash(input ?? '');
  }, [input]);

  const asyncAct = async (final?: () => void) => {
    try {
      const res = await sendPost<Input, Output>(path, input);
      setData(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
    } finally {
      if (final) final();
    }
  };

  useEffect(() => {
    const act = async () => {
      asyncAct(() => setLoading(false));
    };
    setLoading(true);
    setData(undefined);
    act();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, inpHash, reloader]);

  const reload = useCallback(() => {
    setReloader((old) => old + 1);
  }, []);

  return {
    reloadAsync: asyncAct,
    reload,
    loading,
    data,
    error,
  };
}

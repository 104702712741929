import { IApiKey } from '../../backend/_models/apiKey';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'security/createApiKey';

export interface ICreateApiKey {
  userId: number;
}

export const useCreateApiKey = (params: IPostCallbackParams<IApiKey>) =>
  usePostCallback<ICreateApiKey, IApiKey>(path, params);

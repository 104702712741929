import { FC, ReactNode } from 'react';
import { ITopItem } from '../../core/backend/_models/stats';
import styles from './CardTable.module.scss';
import { CardTableBody } from './CardTableBody/CardTableBody';
import { CardTableHeader } from './CardTableHeader/CardTableHeader';

export interface ICardTableProps {
  title: string;
  maxWidth?: number;
  icon: ReactNode | JSX.Element;
  rows: ITopItem[] | undefined;
  isAdmin: boolean;
}

export const CardTable: FC<ICardTableProps> = ({ title, maxWidth, icon, rows, isAdmin }) => {
  return (
    <section className={styles.cardTable} style={{ maxWidth: maxWidth }}>
      <CardTableHeader title={title} icon={icon} />
      <CardTableBody rows={rows} isAdmin={isAdmin} />
    </section>
  );
};

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMerchants } from '../../core/hooks/users/getMerchants';
import { RootState, AppDispatch } from '../../store';
import { updateMerchant } from '../../store/reducers/merchantReducer/merchantReducer';
import { ISelectProps, ISelectValue, Select } from '../Select/Select';

type IMerchantSelectProps = Partial<ISelectProps>;

export const MerchantSelect: FC<IMerchantSelectProps> = ({ variant, size = 'lg', isFilter }) => {
  const { data: merchants, loading, error } = useGetMerchants({});

  const options: ISelectValue[] = [
    {
      value: 'none',
      label: 'none',
    },
  ];
  merchants?.values.map((merchant) => {
    options.push({ value: merchant.id.toString(), label: merchant.login });
  });

  const value: ISelectValue | null = useSelector((store: RootState) => {
    return store.merchant.id && store.merchant.login
      ? {
          value: store.merchant.id.toString(),
          label: store.merchant.login,
        }
      : null;
  });
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (newValue: ISelectValue | undefined) => {
    const id = newValue && newValue.value !== 'none' ? newValue.value : undefined;
    const login = newValue && newValue.value !== 'none' ? newValue.label : undefined;
    dispatch(
      updateMerchant({
        id: Number(id),
        login: login,
      }),
    );
  };

  return (
    <Select
      variant={variant}
      size={size}
      value={value}
      placeholder="Merchant"
      options={options}
      onChange={handleChange}
      disabled={loading}
      onClear={() => handleChange(undefined)}
      isFilter={isFilter}
    />
  );
};

import { FC } from 'react';
import { BarChart, IBarData } from '../../../../components/BarChart/BarChart';
import { Heading } from '../../../../components/Heading/Heading';
import { Loader } from '../../../../components/Loader/Loader';
import styles from './DashboardBarChart.module.scss';

interface IDashboardBarChartProps {
  title: string;
  id: string;
  data: IBarData[];
  loading: boolean;
  color?: 'crimson' | 'orange' | 'blue';
  rotateXAxis?: boolean;
}

export const DashboardBarChart: FC<IDashboardBarChartProps> = ({
  title,
  id,
  data,
  loading,
  color,
  rotateXAxis,
}) => {
  return (
    <div className={styles.chart}>
      <Heading headType={'h2'} variant="secondary">
        {title}
      </Heading>
      {!loading ? (
        <BarChart
          id={id}
          width={585}
          height={333}
          color={color}
          data={data}
          rotateXAxis={rotateXAxis}
        />
      ) : (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      )}
    </div>
  );
};

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { USER_ROLE } from '../../core/backend/_models/user';
import { APP_ROUTES } from '../../routes/routes';
import { RootState } from '../../store/store';
import styles from './NavMenu.module.scss';

export const NavMenu: FC = () => {
  const userRole = useSelector((state: RootState) => state.user.info?.role);
  return (
    <nav>
      <ul className={styles.ul}>
        <li>
          <NavLink
            to={APP_ROUTES.DASHBOARD}
            className={({ isActive }) => (isActive ? styles.activeStyle : styles.nonActive)}
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to={APP_ROUTES.TRANSACTIONS}
            className={({ isActive }) => (isActive ? styles.activeStyle : styles.nonActive)}
          >
            Transactions
          </NavLink>
        </li>
        {userRole === USER_ROLE.SUPER_ADMIN && (
          <li>
            <NavLink
              to={APP_ROUTES.REQUESTS}
              className={({ isActive }) => (isActive ? styles.activeStyle : styles.nonActive)}
            >
              Requests
            </NavLink>
          </li>
        )}
        {userRole === USER_ROLE.SUPER_ADMIN && (
          <li>
            <NavLink
              to={APP_ROUTES.MERCHANTS}
              className={({ isActive }) => (isActive ? styles.activeStyle : styles.nonActive)}
            >
              Merchants
            </NavLink>
          </li>
        )}
        {userRole === USER_ROLE.SUPER_ADMIN && (
          <li>
            <NavLink
              to={APP_ROUTES.ADMINS}
              className={({ isActive }) => (isActive ? styles.activeStyle : styles.nonActive)}
            >
              Admins
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

import { MerchantReducerState } from '../reducers/merchantReducer/types';

export const updateMerchantAction = (
  _: MerchantReducerState,
  { payload }: { payload: Partial<MerchantReducerState> },
) => {
  return {
    id: payload.id,
    login: payload.login,
  };
};

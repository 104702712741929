import { FC } from 'react';
import { useSelector } from 'react-redux';
import { customToast } from '../../../../components/Toast/Toast';
import { useGetRequestStatistics } from '../../../../core/hooks/stats/getRequestStatistics';
import { RootState } from '../../../../store';
import { useReloader } from '../../DashboardMainInfo/useReloader';
import { PERIODS } from '../constants';
import { DashboardBarChart } from '../DashboardBarChart/DashboardBarChart';
import { getStats } from '../getStats';
import { matchPeriod } from '../matchPeriod';
import { IChartProps } from '../types';

export const RequestStatsChart: FC<IChartProps> = ({ period, reloader }) => {
  const merchantId = useSelector((state: RootState) => state.merchant.id);
  const { data, loading, error, reload } = useGetRequestStatistics({
    status: period,
    userId: merchantId,
  });

  if (!loading && error) customToast(error);

  useReloader(reloader, reload);

  return (
    <DashboardBarChart
      title={`${matchPeriod(period)} Requests`}
      id={'requestsStatsChart'}
      data={getStats(data, period)}
      loading={loading}
      color={'blue'}
      rotateXAxis={period === PERIODS.DAILY}
    />
  );
};

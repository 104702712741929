export const timeToUtc = (range: { from: Date; to: Date } | undefined) => {
  const timeOffset = new Date().getTimezoneOffset() * 60000;
  const resRange: { beginDate?: Date; endDate?: Date } = {
    beginDate: undefined,
    endDate: undefined,
  };
  resRange.beginDate = range ? new Date(range.from.getTime() - timeOffset) : undefined;
  resRange.endDate = range ? new Date(range.to.getTime() - timeOffset) : undefined;
  return resRange;
};

import { FC, useEffect } from 'react';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { Heading } from '../../Heading/Heading';
import styles from './PopUpMultiple.module.scss';
import { PopUpMultipleItem } from './PopUpMultipleItem/PopUpMultipleItem';

export interface IPopUpMultipleItem {
  label: string;
  value: string;

  onCopy: () => void;
}

interface IPopUpMultipleProps {
  title: string;
  items: IPopUpMultipleItem[];

  onClose: () => void;
}

export const PopUpMultiple: FC<IPopUpMultipleProps> = ({ title, items, onClose }) => {
  useEffect(() => {
    document.body.addEventListener('mousedown', onClose);
    return () => document.body.removeEventListener('mousedown', onClose);
  }, [onClose]);

  return (
    <div className={styles.popup} onMouseDown={(e) => e.stopPropagation()}>
      <section className={styles.header}>
        <Heading headType="h4" variant="cardName">
          {title}
        </Heading>
        <CloseIcon className={styles.closeIcon} onClick={onClose} />
      </section>
      {items.map((item) => (
        <PopUpMultipleItem item={item} key={item.label} />
      ))}
    </div>
  );
};

import { FC } from 'react';
import { LogoIconLarge } from '../../../assets/icons/LogoIconLarge';

export const Header: FC = () => {
  return (
    <header>
      <LogoIconLarge />
    </header>
  );
};

import { sendPost } from '../../backend/sendPost';
import { IIpAddress } from '../../backend/_models/ip';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'security/setIpAddressEnabled';

export interface ISetIpAddressEnabled {
  userId: number;
  ipAddress: string;
  enabled: boolean;
}

export const useSetIpAddressEnabled = (params: IPostCallbackParams<IIpAddress>) =>
  usePostCallback<ISetIpAddressEnabled, IIpAddress>(path, params);

import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { STANDARD_BACKLINK } from './constants';
import styles from './BackLink.module.scss';

interface IBackLinkProps {
  children?: JSX.Element | ReactNode;

  onClick?: () => void;
}

export const BackLink: FC<IBackLinkProps> = ({ children = STANDARD_BACKLINK, onClick }) => {
  const nav = useNavigate();
  return (
    <a className={styles.link} onClick={onClick ? onClick : () => nav(-1)}>
      {children}
    </a>
  );
};

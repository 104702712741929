import axios, { AxiosRequestConfig } from 'axios';
import { getAccessToken, setAccessToken } from '../localStorage/accessToken';
import { setCachedUser } from '../localStorage/cachedUser';

export async function sendPost<Input, Output>(path: string, input: Input): Promise<Output> {
  const base = process.env.REACT_APP_BASE;
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${base}/${path}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${getAccessToken()}`,
    },
    data: input,
    timeout: 10000,
  };

  try {
    const response = await axios(options);
    const data = await response.data;
    return data as Output;
  } catch (err: any) {
    if (err.response?.status === 429) {
      throw new Error('Слишком большое количество запросов');
    }
    if (err.response?.status === 401 || (err.response?.status === 403 && path !== 'auth/login')) {
      setAccessToken(null);
      setCachedUser();
      window.location.reload();
    }
    if (err.response?.data?.message) {
      const errMsg = err.response?.data?.message;
      if (errMsg == 'Internal server error') {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unknown error. Contact support if error occurs again');
      }
      if (Array.isArray(errMsg)) {
        throw new Error(errMsg[0]);
      } else {
        throw new Error(errMsg);
      }
    } else {
      if (!err.response && err.code === 'ECONNABORTED') throw new Error('Server does not respond');
      throw new Error(err.message);
    }
  }
}

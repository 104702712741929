import { configureStore } from '@reduxjs/toolkit';
import { autoUpdateReducer } from './reducers/autoUpdateReducer/autoUpdateReducer';
import { dateRangeReducer } from './reducers/dateRangeReducer/dateRangeReducer';
import { merchantReducer } from './reducers/merchantReducer/merchantReducer';
import { userReducer } from './reducers/userReducer/userReducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    dateRange: dateRangeReducer,
    merchant: merchantReducer,
    autoUpdate: autoUpdateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

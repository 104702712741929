import { FC, HTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Badge.module.scss';

interface IBadgeProps extends HTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'lg';
  color?: 'crimson' | 'lightCrimson' | 'yellow' | 'green' | 'lightBlue';
  children: ReactNode | JSX.Element;
}

export const Badge: FC<IBadgeProps> = ({
  size = 'sm',
  color = 'crimson',
  className,
  children,
  ...props
}) => {
  const badgeClassName = cx(styles.badge, styles[size], styles[color], className);
  return (
    <div className={badgeClassName} {...props}>
      {children}
    </div>
  );
};

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StatisticsCard } from '../../../../components/StatisticsCard/StatisticsCard';
import { RootState } from '../../../../store/store';
import styles from './MainInfoCards.module.scss';
import { getCards } from './getCards';
import { IMainInfoProps } from '../types';
import { Loader } from '../../../../components/Loader/Loader';

export const MainInfoCards: FC<IMainInfoProps> = ({ stats, loading }) => {
  const userRole = useSelector((state: RootState) => state.user.info?.role);

  const cards = useMemo(() => {
    if (!userRole) return [];
    return getCards(stats, userRole);
  }, [stats, userRole]);

  if (loading) return <Loader />;
  return (
    <section className={styles.cards}>
      {cards.map((row, i) => (
        <div className={styles.row} key={i}>
          {row.map((card) => (
            <StatisticsCard
              className={styles.card}
              heading={card.heading}
              statValue={card.statValue}
              subTotal={card.subTotal}
              key={card.key}
            />
          ))}
        </div>
      ))}
    </section>
  );
};

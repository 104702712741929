import { FC } from 'react';
import { IIcon } from '../types';

export const UserIcon: FC<IIcon> = ({ width = 24, height = 24, fill = '#343D5B' }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12 4C16.418 4 20 7.582 20 12C20 13.5973 19.5254 15.0811 18.7188 16.3301L17.9492 15.7344C16.3972 14.5374 13.537 14 12 14C10.463 14 7.60178 14.5374 6.05078 15.7344L5.28125 16.332C4.47404 15.0828 4 13.5979 4 12C4 7.582 7.582 4 12 4ZM12 5.75C10.208 5.75 8.75 7.208 8.75 9C8.75 10.792 10.208 12.25 12 12.25C13.792 12.25 15.25 10.792 15.25 9C15.25 7.208 13.792 5.75 12 5.75ZM12 7.75C12.689 7.75 13.25 8.311 13.25 9C13.25 9.689 12.689 10.25 12 10.25C11.311 10.25 10.75 9.689 10.75 9C10.75 8.311 11.311 7.75 12 7.75ZM12 16C15.1007 16 16.7681 17.1685 17.5488 17.7539C16.11 19.1418 14.1569 20 12 20C9.84315 20 7.89002 19.1418 6.45117 17.7539C7.2319 17.1685 8.89929 16 12 16ZM6.05469 17.3398C6.17566 17.4731 6.29727 17.6059 6.42578 17.7305C6.29711 17.6053 6.17473 17.4734 6.05469 17.3398ZM17.9121 17.375C17.8024 17.4955 17.6929 17.6168 17.5762 17.7305C17.6926 17.6175 17.8015 17.495 17.9121 17.375Z"
      fill={fill}
    />
  </svg>
);

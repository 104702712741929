import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'users/create-merchant';

export interface CreateMerchantInput {
  login: string;
}

export const useCreateMerchantCallback = (params: IPostCallbackParams<undefined>) =>
  usePostCallback<CreateMerchantInput, undefined>(path, params);

import { ChangeEvent, FC, useState } from 'react';
import { useCreateMerchantCallback } from '../../../core/hooks/users/createMerchant';
import { Heading } from '../../Heading/Heading';
import { Input } from '../../Input/Input';
import { ModalButtons } from '../ModalButtons/ModalButtons';
import { IModal, ModalWrapper } from '../ModalWrapper/ModalWrapper';
import styles from './ModalNewMerchant.module.scss';
import { customToast } from '../../Toast/Toast';

export interface IModalNewMerchantProps extends IModal {
  login?: string;

  reload: () => void;
}

export const ModalNewMerchant: FC<IModalNewMerchantProps> = ({ login, onClose, reload }) => {
  const [user, setUser] = useState({ login: login ?? '' });
  const [innerLoad, setInnerLoad] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUser((o) => {
      return { ...o, [e.target.id]: e.target.value };
    });

  const onResult = async () => {
    await reload();
    onClose();
  };
  const onError = (e: string) => customToast(e);

  const createMerchant = useCreateMerchantCallback({
    onResult: onResult,
    setLoading: setInnerLoad,
    onError: onError,
  });

  return (
    <ModalWrapper onClose={onClose}>
      <Heading variant="modal" headType="h2">
        New merchant
      </Heading>
      <Input
        width={224}
        variant="secondary"
        placeholder="Name"
        value={user.login}
        id="login"
        onChange={onChange}
        disabled={innerLoad}
      />
      <ModalButtons
        actionButtonName={'Create'}
        loading={innerLoad}
        onClose={onClose}
        onAction={() =>
          createMerchant({
            login: user.login,
          })
        }
      />
      <span className={styles.explanation}>
        You should send the user his credentials so that he can login
      </span>
    </ModalWrapper>
  );
};

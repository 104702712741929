import { FC, useState } from 'react';
import { useCreateIpAddress } from '../../../../core/hooks/ip/createIpAddress';
import { Input } from '../../../Input/Input';
import { customToast } from '../../../Toast/Toast';
import { ModalButtons } from '../../ModalButtons/ModalButtons';
import { INPUT_MAX_WIDTH } from '../constants';
import styles from './AddIPSection.module.scss';

interface AddIPSectionProps {
  userId: number;
  closeModal: () => void;
  reloadAsync: () => Promise<void>;
}

export const AddIPSection: FC<AddIPSectionProps> = ({ userId, reloadAsync, closeModal }) => {
  const [value, setValue] = useState<string>();

  const [loading, setLoading] = useState(false);
  const createIpAddress = useCreateIpAddress({
    onResult: async () => {
      setValue('');
      await reloadAsync();
    },
    setLoading,
    onError: (e) => customToast(e),
  });

  const addIP = (ipAddress?: string) => {
    if (!userId || !ipAddress) return;
    createIpAddress({ userId: userId, ipAddress: ipAddress });
  };

  return (
    <div className={styles.wrapper}>
      <Input
        width={INPUT_MAX_WIDTH}
        variant="secondary"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={'New IP'}
      />
      <ModalButtons
        onClose={closeModal}
        actionButtonName={'Add IP'}
        onAction={() => addIP(value)}
        maxWidth={INPUT_MAX_WIDTH}
        loading={loading}
      />
    </div>
  );
};

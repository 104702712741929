import { FC, useState } from 'react';
import { Caption } from '../../Caption/Caption';
import { Datepicker } from '../../Datepickers/Datepicker/Datepicker';
import { Input } from '../../Input/Input';
import { ISelectValue, Select } from '../../Select/Select';
import { ITableProps } from '../Table';
import styles from './TableContent.module.scss';

type ITableContentProps = Pick<
  ITableProps,
  'columns' | 'isMerchantsPage' | 'data' | 'status' | 'setStatus' | 'statusFull'
>;

export const TableContent: FC<ITableContentProps> = ({
  status,
  setStatus,
  statusFull,
  columns,
  isMerchantsPage,
  data,
}) => {
  const selectOptions: ISelectValue[] = statusFull
    ? [
        { value: 'denied', label: 'denied' },
        { value: 'approved', label: 'approved' },
        { value: 'confirmed', label: 'confirmed' },
        { value: 'rejected', label: 'rejected' },
        { value: 'falseRejected', label: 'false rejected' },
        { value: 'truethRejected', label: 'trueth rejected' },
        { value: 'undefined', label: 'undefined' },
        { value: 'pending', label: 'pending' },
        { value: 'none', label: 'none' },
      ]
    : [
        { value: 'denied', label: 'denied' },
        { value: 'approved', label: 'approved' },
        { value: 'confirmed', label: 'confirmed' },
        { value: 'rejected', label: 'rejected' },
        { value: 'none', label: 'none' },
      ];
  const widths = columns.map((column) => {
    return column.width;
  });

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {columns.map((column) => {
            if (column.input === 'text') {
              return (
                <th style={{ width: column.width }} key={column.placeholder}>
                  {column.value && <Caption>{column.placeholder}</Caption>}
                  <Input
                    width={column.width}
                    placeholder={column.placeholder}
                    value={column.value as string}
                    onChange={(e) => {
                      const delayDebounceFn = setTimeout(() => {
                        if (!column.onInputChange || !e.target.value) return;
                        column.onInputChange(e.target.value);
                      }, 2000);
                      return () => clearTimeout(delayDebounceFn);
                    }}
                    onClear={() => {
                      if (!column.onInputChange) return;
                      column.onInputChange(undefined);
                    }}
                    variant="secondary"
                    isTableView
                    disabled={!column.isFilter}
                    isFilter={column.isFilter}
                  />
                </th>
              );
            }

            if (column.input === 'date') {
              return (
                <th style={{ width: column.width }} key={column.placeholder}>
                  <Datepicker
                    maxWidth={column.width}
                    placeholder={column.placeholder}
                    date={column.value}
                    onChange={() => {
                      /* */
                    }}
                    id={column.placeholder || 'id'}
                    isTableView
                    disabled={!column.isFilter}
                    isFilter={column.isFilter}
                  />
                </th>
              );
            }

            if (column.input === 'select') {
              return (
                <th
                  style={{
                    width: column.width,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                  key={column.placeholder}
                >
                  <div>
                    {status && <Caption>{column.placeholder}</Caption>}
                    <Select
                      size="full"
                      placeholder={column.placeholder}
                      value={status ?? null}
                      options={selectOptions}
                      onChange={(newValue: ISelectValue) => {
                        if (!column.onSelectChange) return;
                        if (newValue.value === 'none') {
                          setStatus && setStatus(null);
                          column.onSelectChange('');
                        } else {
                          setStatus && setStatus(newValue);
                          column.onSelectChange(newValue.value);
                        }
                      }}
                      isFilter={column.isFilter}
                    />
                  </div>
                </th>
              );
            }
            return (
              <th
                style={{ width: column.width, maxWidth: column.maxWidth }}
                key={column.placeholder}
              ></th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0 ? (
          data.map((d, index) => (
            <tr className={isMerchantsPage ? styles.merchRow : styles.transRow} key={index}>
              {d.map((item, index) => (
                <td
                  key={index}
                  className={isMerchantsPage ? styles.merchIndent : styles.transIndent}
                  style={{ maxWidth: widths[index] }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr className={styles.noDataWrapper}>
            <td colSpan={columns.length}>
              <div className={styles.noData}>No data</div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

import { AutoUpdateState } from '../reducers/autoUpdateReducer/types';

export const updateAutoUpdateAction = (
  previous: AutoUpdateState,
  { payload }: { payload: Partial<AutoUpdateState> },
): AutoUpdateState => {
  const active = payload.active !== undefined ? payload.active : previous.active;
  return {
    active,
  };
};

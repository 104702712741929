import { FC } from 'react';

export const CrimsonGradient: FC<{ id: string; opacity?: number }> = ({ id, opacity = 1 }) => (
  <defs>
    <linearGradient id={id} x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
      <stop offset="0" stopColor="#FFC1E3" stopOpacity={opacity} />
      <stop offset="1" stopColor="#FFC1E366" stopOpacity={opacity} />
    </linearGradient>
  </defs>
);

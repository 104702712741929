import { FC } from 'react';
import ReactSelect from 'react-select';
import cx from 'classnames';
import './Select.scss';
import styles from './Select.module.scss';
import { DropdownIndicator } from './DropdownIndicator/DropdownIndicator';
import { DropdownIndicatorPrimary } from './DropdownIndicatorPrimary/DropdownIndicatorPrimary';
import { XMarkIcon } from '../../assets/icons/XMarkIcon';

export interface ISelectValue {
  value: string;
  label: string;
}

export interface ISelectProps {
  variant?: 'primary' | 'secondary' | 'table';
  value: ISelectValue | null;
  options: Array<ISelectValue>;
  placeholder?: string;
  size?: 'sm' | 'lg' | 'xl' | 'xxl' | 'full';
  isFilter?: boolean;
  disabled?: boolean;

  onChange: (newValue: ISelectValue) => void;
  onClear?: () => void;
}

export const Select: FC<ISelectProps> = ({
  variant = 'table',
  value,
  options,
  placeholder = 'state',
  size = 'sm',
  isFilter,
  disabled = false,
  onChange,
  onClear,
}) => {
  const SelectCx = cx(styles[size], `my-react-select__${variant}`, {
    ['react-select__filter-view']: isFilter,
  });

  const hasClearIcon = value && onClear;
  const clearIconCx = cx(
    styles.closeBtn,
    { [styles.closeBtnSecondary]: variant === 'secondary' },
    { [styles.closeBtnFilter]: isFilter },
  );
  const clearIconSize = isFilter || variant === 'secondary' ? 12 : 16;

  const dropDownIndicator = hasClearIcon
    ? undefined
    : variant === 'primary'
    ? DropdownIndicatorPrimary
    : DropdownIndicator;

  return (
    <section className={styles.selectWrapper}>
      <ReactSelect
        value={value as unknown}
        classNamePrefix="react-select"
        className={SelectCx}
        placeholder={placeholder}
        options={options}
        isDisabled={disabled}
        components={{
          DropdownIndicator: dropDownIndicator,
        }}
        onChange={(newValue) => onChange(newValue as ISelectValue)}
      />
      {hasClearIcon && (
        <XMarkIcon
          width={clearIconSize}
          height={clearIconSize}
          className={clearIconCx}
          onClear={onClear}
        />
      )}
    </section>
  );
};

import { IMainStats } from '../../backend/_models/stats';
import { usePostRequest } from '../usePostRequest';

const path = 'statistics/get-main-statistics';

export interface IGetMainStatistics {
  beginDate: Date;
  endDate: Date;
  currency: 'BTC';
  userId?: number;
}

export const useGetMainStatistics = (input: IGetMainStatistics) =>
  usePostRequest<IGetMainStatistics, IMainStats>(path, input);

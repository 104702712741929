import { FC } from 'react';
import dayjs from 'dayjs';
import styles from './DatepickerHeader.module.scss';
import { LeftArrow } from '../../../../assets/icons/LeftArrow';
import { RightArrow } from '../../../../assets/icons/RightArrow';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

interface IDatepickerHeader {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

export const DatepickerHeader: FC<IDatepickerHeader> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const dDate = dayjs(date);
  return (
    <div className={styles.header}>
      <button className={styles.arrow} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <LeftArrow />
      </button>
      <div className={styles.date}>
        <span className={styles.month}> {dDate.format('MMMM')}, </span>
        <span className={styles.year}> {dDate.get('year')} </span>
      </div>
      <button className={styles.arrow} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <RightArrow />
      </button>
    </div>
  );
};

export const renderCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => (
  <DatepickerHeader
    date={date}
    decreaseMonth={decreaseMonth}
    increaseMonth={increaseMonth}
    prevMonthButtonDisabled={prevMonthButtonDisabled}
    nextMonthButtonDisabled={nextMonthButtonDisabled}
  />
);

import { FC } from 'react';
import cx from 'classnames';
import { GreaterOrEqualIcon } from '../../../assets/icons/GreaterOrEqualIcon/GreaterOrEqualIcon';
import { ICompareInputProps } from '../CompareInput';
import styles from './CompareControls.module.scss';
import { IconColor } from '../../../assets/types';

type ICompareControlsProps = Pick<ICompareInputProps, 'comparison' | 'setComparison'>;

export const CompareControls: FC<ICompareControlsProps> = ({
  comparison = 'GTE',
  setComparison,
}) => {
  const lowerControlCx = cx(styles.control, { [styles.active]: comparison === 'LTE' });
  const greaterControlCx = cx(styles.control, { [styles.active]: comparison === 'GTE' });

  return (
    <div className={styles.controls}>
      <div className={lowerControlCx} onClick={() => setComparison('LTE')}>
        <GreaterOrEqualIcon isLower fill={comparison === 'LTE' ? IconColor.BLUE : undefined} />
      </div>
      <div className={greaterControlCx} onClick={() => setComparison('GTE')}>
        <GreaterOrEqualIcon fill={comparison === 'GTE' ? IconColor.BLUE : undefined} />
      </div>
    </div>
  );
};

import { Loader } from '../../../../components/Loader/Loader';
import { useGetQRFor2FA } from '../../../../core/hooks/auth/getQRFor2FA';
import styles from './QRCode.module.scss';

export const QRCode = () => {
  const { data, loading } = useGetQRFor2FA();

  if (loading)
    return (
      <div className={styles.loaderWrapper}>
        <Loader margin={55} />
      </div>
    );

  if (!loading && data) return <img className={styles.qr} src={data} />;

  return <></>;
};

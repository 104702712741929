import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from '../../../components/Loader/Loader';
import { ModalNewUser } from '../../../components/Modals/ModalNewUser/ModalNewUser';
import { ModalSecurity } from '../../../components/Modals/ModalSecurity/ModalSecurity';
import { Table } from '../../../components/Table/Table';
import { customToast } from '../../../components/Toast/Toast';
import { useGetAdmins } from '../../../core/hooks/admins/getAdmins';
import {
  SetIsBannedInput,
  useSetBannedCallback,
} from '../../../core/hooks/users/setMerchantBanned';
import { AdminsHeader } from './AdminsHeader/AdminsHeader';
import { columns } from './columns';
import { mapAdmin } from './mapAdmin';

export type setAdminBannedCB = (input: SetIsBannedInput) => void;

export interface ModalState {
  security?: number;
  enabled2FA?: boolean;
}

export const AdminsPage = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [innerLoad, setInnerLoad] = useState(false);

  const [modals, setModals] = useState<ModalState>({
    security: undefined,
    enabled2FA: false,
  });

  const { data, loading, error, reload, reloadAsync } = useGetAdmins();

  const setModalState = (name: keyof ModalState, state?: boolean | number) =>
    setModals((o) => ({ ...o, [name]: state }));

  const setIsBanned = useSetBannedCallback({
    onResult: (_) => reloadAsync(),
    setLoading: setInnerLoad,
    onError: (e) => toast(e),
  });

  const changePasswordCB = useCallback((i: number) => {
    setModalState('security', i);
  }, []);

  const enabled2FACB = useCallback((value: boolean) => setModalState('enabled2FA', value), []);

  if (!loading && error) customToast(error);

  return (
    <>
      {openCreateModal && (
        <ModalNewUser createAdmin reload={reload} onClose={() => setOpenCreateModal(false)} />
      )}
      {modals.security && (
        <ModalSecurity
          onClose={() => setModalState('security')}
          reload={reload}
          userId={modals.security}
          enabled2FA={modals.enabled2FA}
          isAdmin={true}
        />
      )}
      <AdminsHeader openModal={() => setOpenCreateModal(true)} />
      {!data || loading || innerLoad ? (
        <Loader />
      ) : (
        <>
          <Table
            columns={columns}
            data={data
              .sort((a, b) => a.id - b.id)
              .map(mapAdmin(setIsBanned, changePasswordCB, enabled2FACB))}
            isMerchantsPage
          />
        </>
      )}
    </>
  );
};

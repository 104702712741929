import { FC } from 'react';
import cx from 'classnames';
import { ReloadIcon } from '../../assets/icons/ReloadIcon';
import styles from './ReloadButton.module.scss';

interface IReloadButtonProps {
  size?: number;
  reload: () => void;
}

export const ReloadButton: FC<IReloadButtonProps> = ({ reload, size }) => {
  return <ReloadIcon width={size} height={size} className={styles.reloadButton} onClick={reload} />;
};

import { Heading } from '../../Heading/Heading';
import styles from './CardTableHeader.module.scss';
import type { ICardTableProps } from '../CardTable';
import { FC } from 'react';

type ICardTableHeaderProps = Pick<ICardTableProps, 'title' | 'icon'>;

export const CardTableHeader: FC<ICardTableHeaderProps> = ({ title, icon }) => {
  return (
    <section className={styles.cardTableHeader}>
      {icon}
      <Heading headType={'h2'} variant="cardName" className={styles.heading}>
        {title}
      </Heading>
    </section>
  );
};

import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { USER_ROLE } from '../../../core/backend/_models/user';
import { RootState } from '../../../store/store';
import styles from './DashboardBarCharts.module.scss';
import { TotalStatsChart } from './TotalStatsChart/TotalStatsChart';
import { TransactionStatsChart } from './TransactionStatsChart/TransactionStatsChart';
import { RequestStatsChart } from './RequestStatsChart/RequestStatsChart';
import { Button } from '../../../components/Button/Button';
import { PERIODS } from './constants';

export interface IDashboardBarChartsProps {
  reloader: number;
}

export const DashboardBarCharts: FC<IDashboardBarChartsProps> = ({ reloader }) => {
  const userRole = useSelector((state: RootState) => state.user.info?.role);

  const [period, setPeriod] = useState(PERIODS.MONTHLY);

  return (
    <section className={styles.charts}>
      <div className={styles.periodButtons}>
        <Button
          variant={period === PERIODS.MONTHLY ? 'primary' : 'secondary'}
          size="largerXs"
          onClick={() => setPeriod(PERIODS.MONTHLY)}
          className={styles.button}
        >
          MONTHLY
        </Button>
        <Button
          variant={period === PERIODS.DAILY ? 'primary' : 'secondary'}
          size="largerXs"
          onClick={() => setPeriod(PERIODS.DAILY)}
          className={styles.button}
        >
          DAILY
        </Button>
      </div>
      <div className={styles.row}>
        <TotalStatsChart period={period} reloader={reloader} />
        <TransactionStatsChart period={period} reloader={reloader} />
      </div>
      <div className={styles.row}>
        {userRole === USER_ROLE.SUPER_ADMIN && (
          <RequestStatsChart period={period} reloader={reloader} />
        )}
      </div>
    </section>
  );
};

import { FC, ReactNode } from 'react';
import { NextIcon } from '../../assets/icons/NextIcon/NextIcon';
import styles from './Pagination.module.scss';

interface IPaginationProps {
  maxPage: number;
  currentPage: number;

  setCurrentPage: (currentPage: number) => void;
}

export const Pagination: FC<IPaginationProps> = ({ maxPage, currentPage, setCurrentPage }) => {
  const addPageButtons = (start: number, end: number, pageButtons: ReactNode[]) => {
    for (let i = start; i <= end; i++) {
      pageButtons.push(
        <button
          key={i}
          className={i === currentPage ? styles.current : undefined}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>,
      );
    }
  };

  const renderPageButtons = () => {
    const pageButtons: ReactNode[] = [];
    if (maxPage <= 5) {
      addPageButtons(1, maxPage, pageButtons);
    } else if (maxPage - currentPage <= 2) {
      pageButtons.push(
        <button key={1} onClick={() => setCurrentPage(1)}>
          1
        </button>,
        <button key={2} onClick={() => setCurrentPage(2)}>
          2
        </button>,
        <button key={maxPage + 1}>...</button>,
      );
      addPageButtons(maxPage - 2, maxPage, pageButtons);
    } else if (currentPage <= 3) {
      addPageButtons(1, 3, pageButtons);
      pageButtons.push(
        <button key={maxPage + 1}>...</button>,
        <button key={maxPage - 1} onClick={() => setCurrentPage(maxPage - 1)}>
          {maxPage - 1}
        </button>,
        <button key={maxPage} onClick={() => setCurrentPage(maxPage)}>
          {maxPage}
        </button>,
      );
    } else {
      pageButtons.push(
        <button key={1} onClick={() => setCurrentPage(1)}>
          1
        </button>,
        <button key={maxPage + 1}>...</button>,
        <button key={currentPage - 1} onClick={() => setCurrentPage(currentPage - 1)}>
          {currentPage - 1}
        </button>,
        <button key={currentPage} className={styles.current}>
          {currentPage}
        </button>,
        <button key={currentPage + 1} onClick={() => setCurrentPage(currentPage + 1)}>
          {currentPage + 1}
        </button>,
        <button key={maxPage + 2}>...</button>,
        <button key={maxPage} onClick={() => setCurrentPage(maxPage)}>
          {maxPage}
        </button>,
      );
    }
    return pageButtons;
  };

  return (
    <div className={styles.wrapper}>
      <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1}>
        <NextIcon isPrev />
      </button>
      {renderPageButtons()}
      <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= maxPage}>
        <NextIcon />
      </button>
    </div>
  );
};

import { FC } from 'react';
import cx from 'classnames';
import styles from './Heading.module.scss';

interface IHeadingProps extends React.HTMLAttributes<HTMLElement> {
  headType: 'h1' | 'h2' | 'h3' | 'h4';
  variant?: 'primary' | 'cardName' | 'secondary' | 'modal';
}

export const Heading: FC<IHeadingProps> = ({
  children,
  headType,
  variant = 'primary',
  className,
}) => {
  const headingClassName = cx(styles.heading, styles[headType], styles[variant], className);
  const HeadingTag = headType as keyof JSX.IntrinsicElements;
  return <HeadingTag className={headingClassName}>{children}</HeadingTag>;
};

import { FC, HTMLAttributes } from 'react';
import styles from './Button.module.scss';
import cx from 'classnames';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'xs' | 'largerXs' | 'longerXs' | 'preSm' | 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'transparent' | 'ghost';
  icon?: JSX.Element | React.ReactNode;
  className?: string;
}

export const Button: FC<IButtonProps> = ({
  size = 'sm',
  variant = 'primary',
  icon,
  onClick,
  children,
  className,
}) => {
  const classCx = () => cx(className, styles.button, styles[size], styles[variant]);
  return (
    <button className={classCx()} onClick={onClick}>
      <span> {children} </span> {icon}
    </button>
  );
};

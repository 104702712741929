import { sendPost } from '../sendPost';

export interface ILoginInput {
  login: string;
  password: string;
  twoFACode?: string;
}

export interface ILoginOutput {
  jwtToken: string;
}

export const login = async (input: ILoginInput) =>
  sendPost<ILoginInput, ILoginOutput>('auth/login', input);

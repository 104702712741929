import { DateRangeReducerState } from '../reducers/dateRangeReducer/types';

export const updateDateRangeAction = (
  previous: DateRangeReducerState,
  { payload }: { payload: Partial<DateRangeReducerState> },
): DateRangeReducerState => {
  const dateFrom = payload.dateFrom ? new Date(payload.dateFrom) : undefined;
  const dateTo = payload.dateTo ? new Date(payload.dateTo) : undefined;
  let dateRange = undefined;
  if (dateFrom && dateTo) dateRange = { from: dateFrom, to: dateTo };
  if (!dateRange) dateRange = previous.range;
  return {
    dateFrom: dateFrom?.toString(),
    dateTo: dateTo?.toString(),
    range: dateRange,
  };
};

import { login as doLogin } from '../../core/backend/auth/login';
import { whoami } from '../../core/backend/auth/whoami';
import { getAccessToken, setAccessToken } from '../../core/localStorage/accessToken';
import { setCachedUser } from '../../core/localStorage/cachedUser';
import { UserReducerState, USER_STATE } from '../reducers/userReducer/types';

export const userLoadAction = async (): Promise<UserReducerState> => {
  const token = getAccessToken();
  if (!token) {
    return {
      state: USER_STATE.UNLOGGED,
    };
  }
  try {
    const me = await whoami();
    const payload: UserReducerState = {
      info: me,
      state: USER_STATE.LOGGED,
    };
    return payload;
  } catch {
    setAccessToken(null);
    const payload: UserReducerState = {
      state: USER_STATE.UNLOGGED,
    };
    return payload;
  }
};

export const userLoginAction = async (
  {
    login,
    password,
    twoFACode,
    onErr,
    setLoading,
  }: {
    login: string;
    password: string;
    twoFACode: string | undefined;
    onErr?: (msg: string) => void;
    setLoading?: (loading: boolean) => void;
  },
  { rejectWithValue }: { rejectWithValue: (err: string) => void },
) => {
  try {
    if (setLoading) setLoading(true);
    const payload: UserReducerState = {
      state: USER_STATE.LOGGED,
    };
    const token = await doLogin({
      login: login,
      password,
      twoFACode: twoFACode,
    });
    setAccessToken(token.jwtToken);
    const me = await whoami();
    payload.info = me;
    setCachedUser(me);
    return payload;
  } catch (err: any) {
    const msg = err.message;
    if (onErr) onErr(msg);
    return rejectWithValue(msg);
  } finally {
    if (setLoading) setLoading(false);
  }
};

export const userLogoutAction = () => {
  const payload: UserReducerState = {
    state: USER_STATE.UNLOGGED,
  };
  setAccessToken(null);
  setCachedUser();
  return payload;
};

import { FC } from 'react';
import { ScrollBar } from '../../../ScrollBar/ScrollBar';
import { IPsEditProps } from '../IPEditProps';
import { EditIP } from './EditIP/EditIP';
import styles from './EditIPsSection.module.scss';

export const EditIPsSection: FC<IPsEditProps> = ({ IPs, ...others }) => {
  return (
    <ScrollBar className={styles.inputRows}>
      {IPs.map((IP, ind) => (
        <div key={ind} className={styles.inputRow}>
          <EditIP IP={IP} {...others} />
        </div>
      ))}
    </ScrollBar>
  );
};

import { ChangeEvent, FC, useState } from 'react';
import { Heading } from '../../Heading/Heading';
import { Input } from '../../Input/Input';
import { ModalButtons } from '../ModalButtons/ModalButtons';
import { IModal, ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { useSetDisableTwoFACallback } from '../../../core/hooks/users/disableTwoFA';
import { Button } from '../../Button/Button';
import { useChangePasswordCallback } from '../../../core/hooks/users/changePassword';
import { customToast } from '../../Toast/Toast';
import { useSetDisableAdminTwoFACallback } from '../../../core/hooks/users/disableAdminTwoFA';
import { useChangeAdminPasswordCallback } from '../../../core/hooks/users/changeAdminPassword';

export interface IModalSecurityProps extends IModal {
  userId: number;
  enabled2FA?: boolean;
  isAdmin?: boolean;

  reload: () => void;
}

export const ModalSecurity: FC<IModalSecurityProps> = ({
  userId,
  enabled2FA,
  isAdmin = false,
  onClose,
  reload,
}) => {
  const [user, setUser] = useState({ password: '', enabled2FA: enabled2FA });
  const [innerLoad, setInnerLoad] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUser((o) => {
      return { ...o, [e.target.id]: e.target.value };
    });

  const onResult = async () => {
    await reload();
    onClose();
  };
  const onError = (e: string) => customToast(e);

  const setdisableTwoFA = useSetDisableTwoFACallback({
    onResult: onResult,
    setLoading: setInnerLoad,
    onError: (e) => customToast(e),
  });

  const setdisableAdminTwoFA = useSetDisableAdminTwoFACallback({
    onResult: onResult,
    setLoading: setInnerLoad,
    onError: (e) => customToast(e),
  });
  const changePassword = useChangePasswordCallback({
    onResult: onResult,
    setLoading: setInnerLoad,
    onError: onError,
  });
  const changeAdminPassword = useChangeAdminPasswordCallback({
    onResult: onResult,
    setLoading: setInnerLoad,
    onError: onError,
  });
  return (
    <ModalWrapper onClose={onClose}>
      <Heading variant="modal" headType="h2">
        Security
      </Heading>
      <Input
        width={224}
        variant="secondary"
        placeholder="Password"
        value={user.password}
        id="password"
        onChange={onChange}
        disabled={innerLoad}
      />
      <Button
        variant="primary"
        size="longerXs"
        onClick={
          user.password !== ''
            ? () =>
                isAdmin
                  ? changeAdminPassword({ userId, password: user.password })
                  : changePassword({ userId, password: user.password })
            : void 0
        }
      >
        Change password
      </Button>

      <ModalButtons
        actionButtonName={'Reset 2FA'}
        loading={innerLoad}
        onClose={onClose}
        onAction={() =>
          isAdmin ? setdisableAdminTwoFA({ id: userId }) : setdisableTwoFA({ id: userId })
        }
        icon={false}
        disableActBut={!enabled2FA}
      />
    </ModalWrapper>
  );
};

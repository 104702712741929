import { FC } from 'react';
import { IIcon } from '../types';

export const RightArrow: FC<IIcon> = ({ width = 10, height = 16, fill = '#3190FF' }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.14977 1.60474C1.15477 1.77474 1.22477 1.93474 1.34727 2.05224L7.29477 7.99974L1.34728 13.9472C1.22352 14.0685 1.15352 14.2335 1.15352 14.4072C1.15352 14.6672 1.31227 14.901 1.55352 14.9985C1.79477 15.0972 2.07102 15.0385 2.25227 14.8522L8.65227 8.45224C8.90227 8.20224 8.90227 7.79724 8.65227 7.54724L2.25227 1.14724C2.06977 0.957244 1.78852 0.897243 1.54477 0.999744C1.30102 1.10099 1.14477 1.34099 1.14977 1.60474Z"
      fill={fill}
      stroke={fill}
      strokeLinecap="round"
    />
  </svg>
);

import { IPieData } from '../../../../components/PieChart/PieChart';
import { IMainStats } from '../../../../core/backend/_models/stats';

interface IPieStatsObject {
  none?: IPieData;
  desc?: IPieData;
  fee?: IPieData;
  rbf?: IPieData;
}

export const getBottomPieStats = (stats?: IMainStats) => {
  if (!stats) return { none: { key: 'NONE', value: 0, color: '#fff' } };

  const allBottomStats = stats
    ? Number(stats.hasDescendants) + Number(stats.lowFee) + Number(stats.rbf)
    : 1;
  const desc = stats ? Number(stats.hasDescendants) / allBottomStats : 0;
  const fee = stats ? Number(stats.lowFee) / allBottomStats : 0;
  const rbf = stats ? Number(stats.rbf) / allBottomStats : 0;

  const data: IPieStatsObject = {};
  if (desc > 0) data.desc = { key: 'DESC', value: desc, color: '#FFC1E3' };
  if (fee > 0) data.fee = { key: 'FEE', value: fee, color: '#ffcb68' };
  if (rbf > 0) data.rbf = { key: 'RBF', value: rbf, color: '#67adff' };
  if (Object.keys(data).length > 0) data.none = { key: 'NONE', value: 0, color: '#fff' };

  return data;
};

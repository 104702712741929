import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { FC } from 'react';
import styles from './Dropdown.module.scss';
import './Dropdown.module.scss';

export interface IDropdownValue {
  label: string;
  key: string;
}
export interface IDropdownProps {
  title: string;
  options: Array<IDropdownValue>;
  onClick: (newValue: IDropdownValue) => void;
}

export const Dropdown: FC<IDropdownProps> = ({ options, onClick, title }) => {
  return (
    <div>
      <Menu
        className={styles.menu}
        menuButton={<MenuButton className={styles.button}>{title}</MenuButton>}
      >
        <div className={styles.back}>
          {options.map((row) => {
            return (
              <MenuItem key={row.key} className={styles.menuItem} onClick={() => onClick(row)}>
                {row.label}
              </MenuItem>
            );
          })}
        </div>
      </Menu>
    </div>
  );
};

import cx from 'classnames';
import { IUser } from '../../../core/backend/_models/user';
import { SetIsBannedInput } from '../../../core/hooks/users/setMerchantBanned';
import { Button } from '../../Button/Button';
import styles from './ModalUsers.module.scss';

type setMerchBannedCB = (input: SetIsBannedInput) => void;
type setIdCB = (id: number) => void;
type setEnabled2FACB = (value: boolean) => void;
const enabledCx = (isBanned: boolean) =>
  cx({ [styles.enabled]: !isBanned, [styles.disabled]: isBanned });

export const mapMerchantUsers =
  (setMerchantBanned: setMerchBannedCB, securityId: setIdCB, setEnabled2FACB: setEnabled2FACB) =>
  (merchant: IUser): JSX.Element[] => {
    const isBanned = merchant.isBanned;
    const enabledTwoFA = merchant.enabled2FA;
    return [
      <>{merchant.login}</>,
      <>{merchant.email}</>,
      <span key="3" className={enabledCx(isBanned)}>
        {isBanned ? 'False' : 'True'}
      </span>,
      <Button
        key="4"
        size="largerXs"
        onClick={() => setMerchantBanned({ isBanned: !isBanned, userId: merchant.id })}
      >
        {isBanned ? 'Enable' : 'Disable'}
      </Button>,
      <Button
        key="7"
        size="largerXs"
        variant="secondary"
        onClick={() => {
          securityId(merchant.id);
          setEnabled2FACB(merchant.enabled2FA ?? false);
        }}
      >
        Security
      </Button>,
    ];
  };

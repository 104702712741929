import { ChangeEvent, FC, useState } from 'react';
import { Heading } from '../../Heading/Heading';
import { Input } from '../../Input/Input';
import { ModalButtons } from '../ModalButtons/ModalButtons';
import { IModal, ModalWrapper } from '../ModalWrapper/ModalWrapper';
import styles from './ModalNewUser.module.scss';
import { useCreateAdmin } from '../../../core/hooks/admins/createAdmin';
import { customToast } from '../../Toast/Toast';
import { useCreateMerchantUserCallback } from '../../../core/hooks/users/createMerchantUser';

export interface IModalNewUserProps extends IModal {
  login?: string;
  email?: string;
  createAdmin?: boolean;
  userId?: number;

  reload: () => void;
}

export const ModalNewUser: FC<IModalNewUserProps> = ({
  login,
  email,
  createAdmin: isCreateAdmin,
  userId,
  onClose,
  reload,
}) => {
  const [user, setUser] = useState({ login: login ?? '', email: email ?? '', password: '' });
  const [innerLoad, setInnerLoad] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUser((o) => {
      return { ...o, [e.target.id]: e.target.value };
    });

  const onResult = async () => {
    await reload();
    onClose();
  };
  const onError = (e: string) => customToast(e);

  const createMerchant = useCreateMerchantUserCallback({
    onResult: onResult,
    setLoading: setInnerLoad,
    onError: onError,
  });

  const createAdmin = useCreateAdmin({
    onResult: onResult,
    setLoading: setInnerLoad,
    onError: onError,
  });

  return (
    <ModalWrapper onClose={onClose}>
      <Heading variant="modal" headType="h2">
        New {isCreateAdmin ? 'admin' : 'user'}
      </Heading>
      <Input
        width={224}
        variant="secondary"
        placeholder="Login"
        value={user.login}
        id="login"
        onChange={onChange}
        disabled={innerLoad}
      />
      <Input
        width={224}
        variant="secondary"
        placeholder="E-mail"
        id="email"
        value={user.email}
        onChange={onChange}
        disabled={innerLoad}
      />
      <Input
        width={224}
        variant="secondary"
        placeholder="Password"
        id="password"
        value={user.password}
        onChange={onChange}
        disabled={innerLoad}
      />
      <ModalButtons
        actionButtonName={'Create'}
        loading={innerLoad}
        onClose={onClose}
        onAction={() =>
          !isCreateAdmin
            ? userId &&
              createMerchant({
                login: user.login,
                password: user.password,
                email: user.email,
                merchantId: userId,
              })
            : createAdmin({
                login: user.login,
                password: user.password,
                email: user.email,
              })
        }
      />
      <span className={styles.explanation}>
        You should send the user his credentials so that he can login
      </span>
    </ModalWrapper>
  );
};

import cx from 'classnames';
import { Button } from '../../../components/Button/Button';
import { IUser } from '../../../core/backend/_models/user';
import { IChangePasswordInput } from '../../../core/hooks/users/changePassword';
import { ISetDisableTwoFAInput } from '../../../core/hooks/users/disableTwoFA';
import { SetIsBannedInput } from '../../../core/hooks/users/setMerchantBanned';
import { formatNumber } from '../../../utils/formatNumber';
import styles from './MerchantsPage.module.scss';

type setMerchBannedCB = (input: SetIsBannedInput) => void;
type setIdCB = (id: number) => void;
type setNameCB = (value: string) => void;
const enabledCx = (isBanned: boolean) =>
  cx({ [styles.enabled]: !isBanned, [styles.disabled]: isBanned });

export const mapMerchant =
  (
    setMerchantBanned: setMerchBannedCB,
    setApiId: setIdCB,
    setIpId: setIdCB,
    usersId: setIdCB,
    usersName: setNameCB,
  ) =>
  (merchant: IUser): JSX.Element[] => {
    const isBanned = merchant.isBanned;
    const enabledTwoFA = merchant.enabled2FA;
    return [
      <>{merchant.id}</>,
      <>{merchant.login}</>,
      <span key="3" className={enabledCx(isBanned)}>
        {isBanned ? 'False' : 'True'}
      </span>,
      <>{merchant.usdAmount ? formatNumber(Number(merchant.usdAmount), true, true) : '–'}</>,
      <Button
        key="4"
        size="largerXs"
        onClick={() => setMerchantBanned({ isBanned: !isBanned, userId: merchant.id })}
      >
        {isBanned ? 'Enable' : 'Disable'}
      </Button>,
      <Button key="5" size="largerXs" variant="secondary" onClick={() => setApiId(merchant.id)}>
        API keys
      </Button>,
      <Button key="6" size="largerXs" variant="secondary" onClick={() => setIpId(merchant.id)}>
        IPs
      </Button>,
      <Button
        key="8"
        size="largerXs"
        variant="secondary"
        onClick={() => {
          usersId(merchant.id);
          usersName(merchant.login);
        }}
      >
        Users
      </Button>,
    ];
  };

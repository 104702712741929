import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'security/deleteIpAddress';

export interface IDeleteIpAddress {
  userId: number;
  ipAddress: string;
}

export const useDeleteIpAddress = (params: IPostCallbackParams<boolean>) =>
  usePostCallback<IDeleteIpAddress, boolean>(path, params);

import { FC, InputHTMLAttributes, ReactNode, useState } from 'react';
import cx from 'classnames';
import styles from './Input.module.scss';
import { XMarkIcon } from '../../assets/icons/XMarkIcon';

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  width?: number;
  minWidth?: number;
  variant?: 'primary' | 'secondary';
  icon?: ReactNode | JSX.Element;
  isTableView?: boolean;
  isFilter?: boolean;

  onClear?: () => void;
}

export const Input: FC<IInputProps> = ({
  width,
  minWidth,
  variant = 'primary',
  className,
  icon,
  isTableView = false,
  isFilter,
  value,
  onClear,
  onChange,
  ...props
}) => {
  const inputClassName = cx(
    styles.input,
    styles[variant],
    className,
    { [styles.tableView]: isTableView },
    { [styles.filterView]: isFilter },
  );

  const hasClearIcon = typeof value !== 'undefined' && onClear;
  const clearIconCx = cx(styles.closeBtn, {
    [styles.closeBtnPrimary]: variant === 'primary',
    [styles.filterView]: isFilter,
  });
  const clearIconSize = variant === 'primary' && !isFilter ? 16 : 12;

  return (
    <label className={styles.wrapper} style={{ maxWidth: width, minWidth: minWidth }}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {hasClearIcon && (
        <XMarkIcon
          width={clearIconSize}
          height={clearIconSize}
          onClear={onClear}
          className={clearIconCx}
        />
      )}
      <input
        value={value}
        className={inputClassName}
        onChange={hasClearIcon && isFilter ? undefined : onChange}
        {...props}
      />
    </label>
  );
};

import { IIpAddress } from '../../backend/_models/ip';
import { usePostRequest } from '../usePostRequest';

const path = 'security/getUserIpAddresses';

export interface IGetUserIpAddresses {
  userId: number;
}

export const useGetUserIpAddresses = (input: IGetUserIpAddresses) =>
  usePostRequest<IGetUserIpAddresses, IIpAddress[]>(path, input);

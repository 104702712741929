import { FC, useState } from 'react';
import { useCreateApiKey } from '../../../core/hooks/apiKey/createApiKey';
import { useGetUserApiKeys } from '../../../core/hooks/apiKey/getUserApiKeys';
import { Heading } from '../../Heading/Heading';
import { Loader } from '../../Loader/Loader';
import { ScrollBar } from '../../ScrollBar/ScrollBar';
import { customToast } from '../../Toast/Toast';
import { ModalButtons } from '../ModalButtons/ModalButtons';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { ApiKeySection } from './ApiKeySection/ApiKeySection';
import styles from './ModalApiKeys.module.scss';

interface IModalApiKeys {
  userId: number;

  onClose: () => void;
}

export const ModalApiKeys: FC<IModalApiKeys> = ({ userId, onClose }) => {
  const [creating, setCreating] = useState(false);

  const { data, loading, error, reload } = useGetUserApiKeys({ userId: userId });

  const createApiKey = useCreateApiKey({
    setLoading: setCreating,
    onResult: reload,
    onError: (e) => customToast(e),
  });

  const onAction = () => {
    createApiKey({ userId });
  };

  if (loading)
    return (
      <ModalWrapper onClose={onClose} className={styles.modal}>
        <Loader />
      </ModalWrapper>
    );

  if (!loading && error) customToast(error);

  return (
    <ModalWrapper onClose={onClose} className={styles.modal}>
      <Heading variant="modal" headType="h2">
        Api Keys
      </Heading>
      {!data || data.length === 0 ? (
        <div className={styles.noData}> No data </div>
      ) : (
        <ScrollBar className={styles.apiKeys}>
          {data.map((apiKey) => (
            <ApiKeySection key={apiKey.apiKey} apiKey={apiKey} userId={userId} reload={reload} />
          ))}
        </ScrollBar>
      )}
      <ModalButtons
        onClose={onClose}
        actionButtonName={'Add Api'}
        onAction={onAction}
        loading={creating}
      />
    </ModalWrapper>
  );
};

import { PERIODS } from './constants';

export const matchPeriod = (period: PERIODS) => {
  switch (period) {
    case PERIODS.MONTHLY:
      return 'Monthly';
    case PERIODS.DAILY:
      return 'Daily';
  }
};

import { FC, useState } from 'react';
import { FiltersIcon } from '../../../assets/icons/FiltersIcon';
import { Button } from '../../../components/Button/Button';
import { Heading } from '../../../components/Heading/Heading';
import { ReloadButton } from '../../../components/ReloadButton/ReloadButton';
import { SwitchAutoRefresh } from '../../../components/SwitchAutoRefresh/SwitchAutoRefresh';
import {
  ITransactionsFiltersProps,
  TransactionsFilters,
} from './TransactionsFilters/TransactionsFilters';
import styles from './TransactionsHeader.module.scss';

interface ITransactionHeaderProps extends ITransactionsFiltersProps {
  reload: () => void;
}

export const TransactionHeader: FC<ITransactionHeaderProps> = ({ reload, ...props }) => {
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  return (
    <section className={styles.header}>
      <section className={styles.headerMain}>
        <div className={styles.headingWrapper}>
          <Heading headType={'h1'}> Transactions </Heading>
          <ReloadButton size={32} reload={reload} />
          <SwitchAutoRefresh refetch={reload} />
        </div>
        <Button
          variant={areFiltersOpen ? 'ghost' : 'secondary'}
          onClick={() => setAreFiltersOpen((prev) => !prev)}
          icon={<FiltersIcon />}
        >
          Filters
        </Button>
      </section>
      {areFiltersOpen && <TransactionsFilters {...props} />}
    </section>
  );
};

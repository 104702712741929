import { FC } from 'react';
import Fraction from 'fraction.js';
import { Badge } from '../../../../components/Badge/Badge';
import { Loader } from '../../../../components/Loader/Loader';
import { PieChart } from '../../../../components/PieChart/PieChart';
import { IMainInfoProps } from '../types';
import styles from './MainInfoChart.module.scss';
import { getTopPieStats } from './getTopPieStats';
import { getBottomPieStats } from './getBottomPieStats';
import { USER_ROLE } from '../../../../core/backend/_models/user';

const size = 279;

export const MainInfoChart: FC<IMainInfoProps & { role: USER_ROLE | undefined }> = ({
  stats,
  loading,
  role,
}) => {
  const dataTop = getTopPieStats(stats);
  const dataBottom = getBottomPieStats(stats);
  const isAdmin = role === USER_ROLE.SUPER_ADMIN;
  return (
    <section className={styles.wrapper}>
      {!loading ? (
        <PieChart size={size} dataKey={'value'} data={Object.values(dataTop)} />
      ) : (
        <Loader />
      )}
      <section className={styles.badges}>
        <div className={styles.pair}>
          <Badge size="lg" color="green">
            Approved{' '}
            {dataTop.approved &&
              dataTop.approved.value > 0 &&
              `– ${Math.round(dataTop.approved.value * 100)}%`}
          </Badge>

          <Badge size="lg" color="crimson">
            Denied{' '}
            {dataTop.denied &&
              dataTop.denied.value > 0 &&
              `– ${Math.round(dataTop.denied.value * 100)}%`}
          </Badge>
        </div>
      </section>
      {isAdmin && (
        <>
          {!loading ? (
            <PieChart size={size} dataKey={'value'} data={Object.values(dataBottom)} />
          ) : (
            <Loader />
          )}
          <section className={styles.badges}>
            <div className={styles.pair}>
              <Badge size="lg" color="lightBlue">
                RBF{' '}
                {dataBottom.rbf &&
                  dataBottom.rbf.value > 0 &&
                  `– ${Math.round(dataBottom.rbf.value * 100)}%`}
              </Badge>
              <Badge size="lg" color="yellow">
                Fee{' '}
                {dataBottom.fee &&
                  dataBottom.fee.value > 0 &&
                  `– ${Math.round(dataBottom.fee.value * 100)}%`}
              </Badge>
            </div>
            <div className={styles.pair}>
              <Badge size="lg" color="lightCrimson">
                Desc{' '}
                {dataBottom.desc &&
                  dataBottom.desc.value > 0 &&
                  `– ${Math.round(dataBottom.desc.value * 100)}%`}
              </Badge>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

import { FC } from 'react';
import cx from 'classnames';
import { IIcon } from '../../types';
import styles from './ArrowIcon.module.scss';

interface IArrowIconProps extends IIcon {
  opacity?: number;
  invert?: boolean;
}

export const ArrowIcon: FC<IArrowIconProps> = ({
  width = 16,
  height = 14,
  fill = '#3190FF',
  opacity = 0.3,
  invert = false,
  className,
}) => (
  <svg
    className={cx(className, { [styles.invert]: invert })}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={fill}
    opacity={opacity}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.24425 0.599976C9.56282 0.599976 9.88143 0.725372 10.1241 0.974965L15.1017 6.09482C15.5883 6.59529 15.5883 7.4043 15.1017 7.90477L10.1241 13.0246C9.63752 13.5251 8.85098 13.5251 8.36441 13.0246C7.87784 12.5242 7.87784 11.7151 8.36441 11.2147L11.2178 8.27976H1.77776C1.09084 8.27976 0.533342 7.70762 0.533342 6.9998C0.533342 6.29198 1.09084 5.71983 1.77776 5.71983H11.2178L8.36441 2.78491C7.87784 2.28445 7.87784 1.47543 8.36441 0.974965C8.60707 0.725372 8.92568 0.599976 9.24425 0.599976Z"
      fill={fill}
    />
  </svg>
);

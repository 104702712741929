import { IApiKey } from '../../backend/_models/apiKey';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'security/setApiKeyEnabled';

export interface ISetApiKeyEnabled {
  userId: number;
  apiKey: string;
  enabled: boolean;
}

export const useSetApiKeyEnabled = (params: IPostCallbackParams<IApiKey>) =>
  usePostCallback<ISetApiKeyEnabled, IApiKey>(path, params);

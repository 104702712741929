import { FC } from 'react';
import cx from 'classnames';
import { IIcon } from '../../types';
import styles from './NextIcon.module.scss';

interface INextIconProps extends IIcon {
  isPrev?: boolean;
}

export const NextIcon: FC<INextIconProps> = ({
  width = 6,
  height = 8,
  fill = '#3190FF',
  className,
  isPrev = false,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, { [styles.prev]: isPrev })}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.07193 3.24063L1.36392 0.0756431C1.23844 -0.0140025 1.08263 0.000932952 0.964953 0.000932952C0.49425 0.000932952 0.496338 0.437028 0.496338 0.547511V7.10658C0.496338 7.19998 0.494277 7.65319 0.964953 7.65319C1.08263 7.65319 1.23847 7.66806 1.36392 7.57844L5.07191 4.32524C5.42029 4.07649 5.36009 3.78292 5.36009 3.78292C5.36009 3.78292 5.42032 3.48935 5.07193 3.24063Z"
      fill={fill}
    />
  </svg>
);

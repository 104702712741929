import { IUser } from '../../backend/_models/user';
import { usePostRequest } from '../usePostRequest';

const path = 'users/get-users-of-merchant';

export interface IGetMerchantUsers {
  userId: number;
}

export const useGetMerchantUsers = (input: IGetMerchantUsers) =>
  usePostRequest<IGetMerchantUsers, IUser[]>(path, input);

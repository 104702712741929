import { Heading } from '../../components/Heading/Heading';
import { DashboardControls } from './DashboardControls/DashboardControls';
import { DashboardMainInfo } from './DashboardMainInfo/DashboardMainInfo';
import styles from './DashboardPage.module.scss';
import { DashboardBarCharts } from './DashboardBarCharts/DashboardBarCharts';
import { DashboardTables } from './DashboardTables/DashboardTables';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { curMonth, prevMonth } from './constants';
import { USER_ROLE } from '../../core/backend/_models/user';
import { useState } from 'react';

export const DashboardPage = () => {
  const dates = useSelector((state: RootState) => state.dateRange.range);
  const userRole = useSelector((state: RootState) => state.user.info?.role);
  const renderRole = userRole === USER_ROLE.SUPER_ADMIN ? 'Admin' : 'Merchant';

  const beginDate = dates?.from ?? prevMonth;
  const endDate = dates?.to ?? curMonth;

  const [reloader, setReloader] = useState(0);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <Heading headType={'h1'} className={styles.heading}>
          {`${renderRole} Dashboard`}
        </Heading>
        <DashboardControls reload={() => setReloader((prev) => prev + 1)} />
        <DashboardMainInfo
          reloader={reloader}
          beginDate={beginDate}
          endDate={endDate}
          role={userRole}
        />
        <DashboardTables reloader={reloader} beginDate={beginDate} endDate={endDate} />
        <DashboardBarCharts reloader={reloader} />
      </div>
    </div>
  );
};

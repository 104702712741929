import { ITopStats } from '../../backend/_models/stats';
import { usePostRequest } from '../usePostRequest';

const path = 'statistics/get-top-statistics';

export interface IGetTopStatistics {
  beginDate: Date;
  endDate: Date;
  currency: 'BTC';
  userId?: number;
}

export const useGetTopStatistics = (input: IGetTopStatistics) =>
  usePostRequest<IGetTopStatistics, ITopStats>(path, input);

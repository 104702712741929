import dayjs from 'dayjs';
import { IBarData } from '../../../components/BarChart/BarChart';
import { ITransactionItem } from '../../../core/backend/_models/stats';
import { timeFromUtc } from '../../../utils/timeFromUtc';
import { MONTHS, PERIODS } from './constants';

const getMonthlyStats = (stats: ITransactionItem[] | undefined): IBarData[] => {
  if (!stats) return [];

  const yearlyStatsTail = stats.map((item) => {
    const from = timeFromUtc(item.from);
    return {
      label: MONTHS[dayjs(from).get('month')],
      key: MONTHS[dayjs(from).get('month')],
      value: Number(item.count),
    };
  });

  return yearlyStatsTail;
};

const getDailyStats = (stats: ITransactionItem[] | undefined): IBarData[] => {
  if (!stats) return [];
  return stats.map((item) => {
    const from = timeFromUtc(item.from);
    return {
      label: `${MONTHS[dayjs(from).get('month')]} ${dayjs(from).get('date').toString()}`,
      key: dayjs(from).get('date').toString(),
      value: Number(item.count),
    };
  });
};

export const getStats = (stats: ITransactionItem[] | undefined, period: PERIODS): IBarData[] => {
  switch (period) {
    case PERIODS.MONTHLY:
      return getMonthlyStats(stats);
    case PERIODS.DAILY:
      return getDailyStats(stats);
  }
};

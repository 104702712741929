import { ChangeEvent, FC } from 'react';
import { ISelectValue, Select } from '../../../../components/Select/Select';
import {
  DateRangeAction,
  FilterAction,
} from '../../../TransactionsPage/TransactionsHeader/TransactionsFilters/types';
import { FilterValue } from '../../../TransactionsPage/TransactionsPage';
import { STATE_OPTIONS } from './constants';
import { convertToSelectValue } from './convertToSelectValue';
import { RequestsRangepicker } from './RequestsRangepicker/RequestsRangepicker';
import styles from './RequestsFilters.module.scss';
import { Input } from '../../../../components/Input/Input';
import { Comparison } from '../../../../core/hooks/transactions/types';
import { MerchantSelect } from '../../../../components/MerchantSelect/MerchantSelect';
import { REASON_OPTIONS } from '../../../TransactionsPage/TransactionsHeader/TransactionsFilters/constants';
import { CompareInput } from '../../../../components/CompareInput/CompareInput';

export interface IRequestsFiltersProps {
  state: FilterValue;
  reason: FilterValue;
  address: FilterValue;
  amount: FilterValue;
  amountComparison: Comparison | undefined;
  amountUSD: FilterValue;
  amountUSDComparison: Comparison | undefined;
  apiKey: FilterValue;
  ipAddress: FilterValue;
  txid: FilterValue;
  result: FilterValue;

  setState: FilterAction;
  setReason: FilterAction;
  setAddress: FilterAction;
  setAmount: FilterAction;
  setAmountComparison: FilterAction;
  setAmountUSD: FilterAction;
  setAmountUSDComparison: FilterAction;
  setApiKey: FilterAction;
  setIpAddress: FilterAction;
  setTxid: FilterAction;
  setResult: FilterAction;
  setDateRange: DateRangeAction;
}

export const RequestsFilters: FC<IRequestsFiltersProps> = ({
  state,
  reason,
  address,
  amount,
  amountComparison,
  amountUSD,
  amountUSDComparison,
  apiKey,
  ipAddress,
  txid,
  result,
  setState,
  setReason,
  setAddress,
  setAmount,
  setAmountComparison,
  setAmountUSD,
  setAmountUSDComparison,
  setApiKey,
  setIpAddress,
  setTxid,
  setResult,
  setDateRange,
}) => {
  const handleChangeInput = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (e: string) => void,
    delay = 2000,
  ) => {
    const delayDebounceFn = setTimeout(() => {
      if (!onChange || !e.target.value) return;
      onChange(e.target.value);
    }, delay);
    return () => clearTimeout(delayDebounceFn);
  };

  return (
    <section className={styles.filters}>
      <RequestsRangepicker width={260} setRange={setDateRange} />
      <MerchantSelect variant="primary" size="full" isFilter />
      <Select
        variant="primary"
        size="full"
        placeholder="State"
        value={convertToSelectValue(state)}
        options={STATE_OPTIONS}
        onChange={(newValue: ISelectValue) => setState(newValue.value)}
        onClear={() => setState(undefined)}
        isFilter
      />
      <Select
        variant="primary"
        size="full"
        placeholder="Reason"
        value={convertToSelectValue(reason)}
        options={REASON_OPTIONS}
        onChange={(newValue: ISelectValue) => setReason(newValue.value)}
        onClear={() => setReason(undefined)}
        isFilter
      />
      <Input
        minWidth={189}
        width={561}
        placeholder="Address"
        value={address}
        onChange={(e) => handleChangeInput(e, (value) => setAddress(value))}
        onClear={async () => {
          await setAddress('');
          setAddress(undefined);
        }}
        isFilter
      />
      <CompareInput
        width={189}
        type="number"
        placeholder="Amount"
        value={amount}
        onChange={(e) => handleChangeInput(e, (value) => setAmount(value), 3000)}
        onClear={async () => {
          await setAmount('');
          setAmount(undefined);
        }}
        comparison={amountComparison}
        setComparison={setAmountComparison}
        isFilter
      />
      <CompareInput
        width={189}
        type="number"
        placeholder="Amount USD"
        value={amountUSD}
        onChange={(e) => handleChangeInput(e, (value) => setAmountUSD(value), 3000)}
        onClear={async () => {
          await setAmountUSD('');
          setAmountUSD(undefined);
        }}
        comparison={amountUSDComparison}
        setComparison={setAmountUSDComparison}
        isFilter
      />
      <Input
        width={189}
        placeholder="ApiKey"
        value={apiKey}
        onChange={(e) => handleChangeInput(e, (value) => setApiKey(value))}
        onClear={async () => {
          await setApiKey('');
          setApiKey(undefined);
        }}
        isFilter
      />
      <Input
        width={189}
        placeholder="IP address"
        value={ipAddress}
        onChange={(e) => handleChangeInput(e, (value) => setIpAddress(value))}
        onClear={async () => {
          await setIpAddress('');
          setIpAddress(undefined);
        }}
        isFilter
      />
      <Input
        minWidth={189}
        width={561}
        placeholder="Txid"
        value={txid}
        onChange={(e) => handleChangeInput(e, (value) => setTxid(value))}
        onClear={async () => {
          await setTxid('');
          setTxid(undefined);
        }}
        isFilter
      />
    </section>
  );
};

export enum USER_ROLE {
  MERCHANT = 'MERCHANT',
  SUPER_ADMIN = 'SUPERADMIN',
}

export interface IUser {
  id: number;
  login: string;
  role: USER_ROLE;
  isBanned: boolean;
  description: string;
  email?: string;
  usdAmount?: number;
  enabled2FA?: boolean;
}

export interface IMerchantRequest {
  maxPage: number;
  values: IUser[];
}

export interface IAdmin {
  id: number;
  login: string;
  role: USER_ROLE.SUPER_ADMIN;
  isBanned: boolean;
  enabled2FA: boolean;
}

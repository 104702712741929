import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { LogoutIcon } from '../../../../assets/icons/LogoutIcon';
import { UserIcon } from '../../../../assets/icons/UserIcon';
import { IUser, USER_ROLE } from '../../../../core/backend/_models/user';
import { useSetDisableTwoFACallback } from '../../../../core/hooks/users/disableTwoFA';
import { AppDispatch, RootState } from '../../../../store';
import { userLoad, userLogout } from '../../../../store/reducers/userReducer/userReducer';
import { Dropdown, IDropdownValue } from '../../../Dropdown/Dropdown';
import { Modal2FA } from '../../../Modals/Modal2FA/Modal2FA';
import { ModalNewPassword } from '../../../Modals/ModalNewPassword/ModalNewPassword';
import { customToast } from '../../../Toast/Toast';
import styles from './HeaderMenu.module.scss';

export interface ModalState {
  enable2FA: boolean;
  changePassword: boolean;
  disable2FA?: number;
}

export const HeaderMenu = () => {
  const user = useSelector((state: RootState) => state.user.info);
  const userRole = user?.role;
  const userLogin = user?.login;
  const enabled2FA = user?.enabled2FA;
  const [modals, setModals] = useState<ModalState>({
    changePassword: false,
    disable2FA: undefined,
    enable2FA: false,
  });

  const [innerLoad, setInnerLoad] = useState(false);

  const setModalState = (name: keyof ModalState, state?: boolean | number) =>
    setModals((o) => ({ ...o, [name]: state }));

  const setChangePasswordCB = useCallback((i: boolean) => {
    setModalState('changePassword', i);
  }, []);

  const setDisable2FA = useSetDisableTwoFACallback({
    onResult: (_: IUser) => {
      dispatch(userLoad());
    },
    setLoading: setInnerLoad,
    onError: (e) => customToast(e),
  });

  const setEnableCB = useCallback((i: boolean) => {
    setModalState('enable2FA', i);
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const options: IDropdownValue[] = [{ key: 'changePassword', label: 'Change password' }];

  if (userRole === USER_ROLE.SUPER_ADMIN)
    if (enabled2FA) options.push({ key: 'disable2FA', label: 'Disable 2FA' });
    else options.push({ key: 'enable2FA', label: 'Enable 2FA' });

  const handleChange = (newValue: IDropdownValue | undefined) => {
    if (!user) return;
    const key = newValue?.key;
    switch (key) {
      case 'changePassword':
        setChangePasswordCB(true);
        break;
      case 'disable2FA':
        setDisable2FA({ id: user.id });
        break;
      case 'enable2FA':
        setEnableCB(true);
        break;
    }
  };

  return (
    <>
      {modals.changePassword && user && (
        <ModalNewPassword
          onClose={() => setModalState('changePassword', false)}
          enabled2FA={enabled2FA}
        />
      )}
      {modals.enable2FA && (
        <Modal2FA
          onClose={() => {
            setModalState('enable2FA', false);
          }}
        />
      )}
      <section className={styles.menu}>
        <div className={styles.greeting}>
          <UserIcon />
          <Dropdown options={options} onClick={handleChange} title={`Hello, ${userLogin}!`} />
        </div>
        <button className={styles.logout} onClick={() => dispatch(userLogout())}>
          <span>Logout</span>
          <LogoutIcon />
        </button>
      </section>
    </>
  );
};

import { FC } from 'react';
import { ISvg } from '../types';

export const LoginImage: FC<ISvg> = ({ width = 516, height = 491, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M375.884 468.324L364.722 469.152L155.816 468.324C146.331 468.324 138.749 460.602 138.874 451.076L144.678 19.7078C144.803 10.1809 152.593 2.46194 162.078 2.46194L370.824 0C394.819 0 399.214 10.1809 399.089 19.7078L393.291 451.076C393.16 460.602 385.37 468.324 375.884 468.324Z"
        fill="#0E55A8"
      />
      <path
        d="M362.641 468.324H142.573C133.088 468.324 125.506 460.577 125.63 451.021L131.274 18.2803C131.399 8.72372 139.189 0.975006 148.668 0.975006H368.743C378.222 0.975006 385.81 8.72372 385.685 18.2803L380.042 451.021C379.917 460.577 372.127 468.324 362.641 468.324Z"
        fill="#7DB7FC"
      />
      <path
        d="M138.237 451.617L143.625 38.4226C143.655 36.2163 145.451 34.4263 147.639 34.4263H368.897C371.085 34.4263 372.834 36.2163 372.81 38.4226L367.416 451.617C367.386 453.823 365.59 455.612 363.402 455.612H142.144C139.956 455.612 138.207 453.823 138.237 451.617Z"
        fill="#E3EDFF"
      />
      <path
        d="M258.417 22.9846C254.867 22.9846 252.024 20.0826 252.072 16.5026C252.119 12.9226 255.033 10.0205 258.59 10.0205C262.14 10.0205 264.982 12.9226 264.935 16.5026C264.887 20.0826 261.973 22.9846 258.417 22.9846Z"
        fill="white"
      />
      <path
        d="M372.579 56.1262L372.811 38.5414C372.835 36.3352 371.086 34.7176 368.898 34.7176H147.64C145.452 34.7176 143.656 36.3352 143.626 38.5414L143.394 56.1262H372.579Z"
        fill="#BBDAFF"
      />
      <path
        d="M158.332 44.8273C158.332 47.3428 156.286 49.3826 153.771 49.3826C151.255 49.3826 149.209 47.3428 149.209 44.8273C149.209 42.3059 151.255 40.2661 153.771 40.2661C156.286 40.2661 158.332 42.3059 158.332 44.8273Z"
        fill="#1664BF"
      />
      <path
        d="M170.225 45.2193C170.225 47.7407 168.185 49.7805 165.664 49.7805C163.148 49.7805 161.103 47.7407 161.103 45.2193C161.103 42.7038 163.148 40.664 165.664 40.664C168.185 40.664 170.225 42.7038 170.225 45.2193Z"
        fill="#1664BF"
      />
      <path
        d="M182.91 45.618C182.91 48.1335 180.871 50.1792 178.349 50.1792C175.834 50.1792 173.794 48.1335 173.794 45.618C173.794 43.1025 175.834 41.0567 178.349 41.0567C180.871 41.0567 182.91 43.1025 182.91 45.618Z"
        fill="#1664BF"
      />
      <path
        d="M167.104 138.989L194.108 125.734C216.076 114.952 236.58 101.399 255.117 85.4144L256.437 77.2078C256.437 77.2078 265.595 77.3089 267.551 77.1959C270.055 77.0532 272.6 77.9274 274.515 79.8066C294.633 99.4906 317.535 116.112 342.487 129.13L360.138 138.341C364.241 140.482 365.751 144.252 364.514 149.676L341.447 247.144C331.403 274.412 290.958 310.9 268.188 329.893C264.043 333.348 258.982 335.036 253.945 335.005C251.715 334.991 242.462 335.285 242.462 335.285C242.462 335.285 240.499 331.385 237.907 328.753C217.449 307.965 181.554 267.949 174.275 236.436L162.197 149.319C161.234 145.15 163.268 140.875 167.104 138.989Z"
        fill="#1664BF"
      />
      <path
        d="M156.881 139.513L183.672 126.364C205.473 115.66 225.811 102.22 244.204 86.3542L251.109 80.4014C254.695 77.3031 260.065 77.4696 263.454 80.788C283.412 100.323 306.135 116.814 330.897 129.73L348.411 138.871C352.484 140.994 354.34 145.811 352.752 150.116L329.863 246.826C319.896 273.881 279.332 310.426 256.74 329.272C247.999 336.568 235.124 335.918 227.131 327.8C206.829 307.174 171.213 267.468 163.994 236.202L152.005 149.759C151.053 145.626 153.069 141.38 156.881 139.513Z"
        fill="#ACC8FE"
      />
      <path
        d="M173.336 150.996L195.517 140.107C213.566 131.246 230.413 120.114 245.637 106.983L251.352 102.053C254.326 99.4902 258.768 99.627 261.575 102.374C278.101 118.544 296.917 132.198 317.415 142.896L331.92 150.466C335.292 152.221 336.832 156.211 335.518 159.779L316.559 239.848C308.311 262.249 274.723 292.506 256.02 308.11C248.777 314.152 238.12 313.613 231.502 306.891C214.696 289.814 185.206 256.939 179.223 231.051L169.304 159.482C168.513 156.056 170.184 152.542 173.336 150.996Z"
        fill="#DCE8FF"
      />
      <path
        d="M267.224 187.318L266.82 161.67C266.629 153.38 260.058 146.595 251.78 146.215C242.652 145.798 235.076 153.262 235.29 162.396L235.242 187.353L225.132 188.74C224.347 201.603 225.745 162.979 225.745 162.979C225.4 148.302 237.145 136.26 252.291 136.872V136.89C264.804 137.395 276.251 148.403 276.561 161.807L276.888 187.71L267.224 187.318Z"
        fill="white"
      />
      <path
        d="M258.292 257.838L247.469 258.264C232.489 257.89 219.246 239.985 220.667 218.94L222.938 185.275L286.617 184.764C292.267 184.741 297.006 189.781 296.614 195.418L294.651 223.852C293.355 242.981 277.465 257.838 258.292 257.838Z"
        fill="#1664BF"
      />
      <path
        d="M247.665 258.8C225.561 258.8 208.074 240.225 209.564 218.329L211.876 184.373H282.433C285.673 184.373 288.247 187.1 288.023 190.315L285.773 223.442C284.415 243.344 267.757 258.8 247.665 258.8Z"
        fill="white"
      />
      <path
        d="M247.024 236.665C245.299 236.665 243.943 235.214 244.05 233.499L245.668 208.127C245.769 206.56 247.065 205.34 248.635 205.34C250.354 205.34 251.716 206.791 251.609 208.506L249.991 233.878C249.89 235.445 248.594 236.665 247.024 236.665Z"
        fill="black"
      />
      <path
        d="M259.024 210.434C259.024 216.487 254.118 221.394 248.064 221.394C242.01 221.394 237.104 216.487 237.104 210.434C237.104 204.382 242.01 199.474 248.064 199.474C254.118 199.474 259.024 204.382 259.024 210.434Z"
        fill="black"
      />
      <path
        d="M348.827 372.835H199.793C195.851 372.835 192.657 369.64 192.657 365.699C192.657 361.758 195.851 358.564 199.793 358.564H348.827C352.77 358.564 355.963 361.758 355.963 365.699C355.963 369.64 352.77 372.835 348.827 372.835Z"
        fill="#7DB7FC"
      />
      <path
        d="M322.523 398.109H158.528C154.585 398.109 151.392 394.913 151.392 390.972C151.392 387.031 154.585 383.837 158.528 383.837H322.523C326.466 383.837 329.66 387.031 329.66 390.972C329.66 394.913 326.466 398.109 322.523 398.109Z"
        fill="#7DB7FC"
      />
      <path
        d="M345.318 424.57H177.873C173.931 424.57 170.737 421.375 170.737 417.434C170.737 413.493 173.931 410.298 177.873 410.298H345.318C349.261 410.298 352.455 413.493 352.455 417.434C352.455 421.375 349.261 424.57 345.318 424.57Z"
        fill="#7DB7FC"
      />
      <path
        d="M39.3983 442.467L35.8421 467.404L26.7553 467.439L26.3271 442.913L39.3983 442.467Z"
        fill="#FFCF97"
      />
      <path
        d="M36.1519 491.244C32.5838 491.624 27.2793 491.779 20.1788 491.155C17.6395 490.929 15.8495 488.58 16.2598 486.064C17.3659 479.297 20.4404 467.358 25.2871 461.881L27.6777 465.293L29.099 461.767C29.3309 461.387 35.3551 461.134 35.48 461.85L35.8427 465.293L39.2621 461.461L39.0778 474.043C39.054 475.432 39.1848 476.82 39.4703 478.181L40.3683 482.532C40.9094 485.148 40.2374 487.919 38.4117 489.87C37.6922 490.631 36.9012 491.166 36.1519 491.244Z"
        fill="#053267"
      />
      <path
        d="M104.623 444.693L107.822 473.757L120.049 476.318L119.305 442.238L104.623 444.693Z"
        fill="#FFCF97"
      />
      <path
        d="M102.655 487.616C102.381 482.525 102.375 473.144 105.479 468.624L115.256 472.106V470.017C115.256 469.344 115.738 468.79 116.35 468.754L121.803 468.441C123.843 468.324 125.871 468.746 127.727 469.66C133.21 472.356 149.896 479.27 152.21 487.045C152.888 489.341 151.098 491.63 148.707 491.701C143.212 491.856 130.016 492.029 106.324 491.22C104.35 491.154 102.756 489.59 102.655 487.616Z"
        fill="#053267"
      />
      <path
        d="M108.441 256.113C108.441 256.113 125.966 376.329 122.231 456.567L116.76 457.114C113.823 457.408 110.867 457.481 107.911 457.333L102.726 457.072C102.726 457.072 81.8287 335.186 71.3979 314.945C71.3979 314.945 43.0137 423.336 41.9255 452.702C41.9255 452.702 29.3063 453.97 24.2812 452.161C21.5814 423.82 20.8023 394.487 20.6656 376.071C20.279 323.364 25.316 299.424 28.0753 288.066C30.6146 277.637 33.3323 269.162 35.3185 263.429L41.9552 244.502L108.441 256.113Z"
        fill="#1664BF"
      />
      <path
        d="M107.025 156.057C95.435 154.748 70.6903 152.405 53.6764 153.957C48.5384 154.433 41.1227 157.567 37.2156 160.939C25.7026 170.87 3.99066 192.742 0.369043 218.988C-0.166172 222.818 2.34938 226.412 6.12562 227.249L26.1843 231.694C26.1843 231.694 18.1858 263.213 18.828 268.656C19.4762 274.099 36.0441 283.361 56.7688 275.763C72.9323 269.839 95.8037 265.128 110.326 270.239C110.463 270.287 111.854 281.447 111.997 281.467C116.659 282.145 121.357 280.33 121.25 273.778L119.038 239.742L145.62 236.231L147.999 235.44C155.183 233.047 158.894 225.122 156.122 218.073C149.878 202.176 140.458 176.442 123.005 162.223C119.27 159.185 111.813 156.592 107.025 156.057Z"
        fill="black"
      />
      <path
        d="M94.9415 260.798H136.486C138.288 260.798 139.864 259.569 140.298 257.818L154.671 199.862C155.284 197.386 153.411 194.991 150.859 194.991H109.315C107.513 194.991 105.937 196.221 105.503 197.972L91.1295 255.927C90.517 258.404 92.3903 260.798 94.9415 260.798Z"
        fill="#4983C9"
      />
      <path
        d="M155.359 224.613L153.962 230.461L148.937 228.788C146.172 227.866 143.513 226.638 141.022 225.126L138.137 223.379C135.985 222.074 133.196 223.093 132.357 225.467C130.579 230.509 130.936 238.956 149.026 244.262C159.011 247.191 161.865 234.4 160.575 227.294C160.242 225.477 159.35 224.317 158.434 223.583C157.34 222.706 155.704 223.254 155.359 224.613Z"
        fill="#FFCF97"
      />
      <path
        d="M51.8392 191.062C49.7578 193.19 47.397 195.015 44.8101 196.487C36.0861 201.453 15.2544 213.476 10.0687 217.905C3.46775 223.547 0.309963 225.651 5.61453 240.164C14.2672 263.823 54.6045 203.09 54.6045 203.09C54.6045 203.09 66.4506 205.233 69.1088 194.851C69.1088 194.851 75.2876 196.01 77.8566 194.895C79.8309 194.04 79.7477 190.69 77.1132 190.322L60.0697 187.145C57.7266 186.725 55.3241 187.495 53.653 189.201L51.8392 191.062Z"
        fill="#FFCF97"
      />
      <path
        d="M91.3264 133.768L92.599 151.513C92.8845 153.821 94.5436 155.724 96.7975 156.307L103.03 157.93L98.986 161.011C90.0181 167.837 77.6011 167.849 68.6214 161.04L62.2227 156.188L69.9654 154.457C72.1955 153.958 73.9022 152.179 74.3066 149.932L77.726 126.453L91.3264 133.768Z"
        fill="#FFCF97"
      />
      <path
        d="M111.486 109.077C107.222 106.817 110.659 113.846 96.6246 101.703C91.0405 96.8678 90.8383 105.895 89.3873 104.646C84.0173 100.026 84.2076 100.18 80.8238 99.5915C78.5819 99.199 75.5847 102.291 74.6927 105.223C73.5152 109.083 76.0842 111.396 75.21 120.292C74.5915 126.542 72.6232 129.611 74.4251 131.389C76.2448 133.185 79.2122 130.985 81.3472 132.799C84.1124 135.142 80.9547 140.351 83.0004 144.05C84.2849 146.375 88.5131 149.117 97.4096 148.903C98.8547 148.873 100.074 147.808 100.306 146.381C100.609 144.549 100.978 142.236 101.144 140C101.941 129.332 99.6456 125.787 101.905 123.962C105.372 121.167 111.26 126.376 113.763 123.92C116.356 121.369 117.325 112.163 111.486 109.077Z"
        fill="black"
      />
      <path
        d="M90.2676 115.053L90.446 117.967C90.5292 119.341 90.5174 120.721 90.4103 122.088C90.2081 124.663 89.8989 129.094 89.9168 131.586C89.9405 135.249 101.251 135.962 101.251 135.962L102.691 134.363L104.017 134.416C104.272 134.428 104.534 134.386 104.766 134.268C105.337 133.97 105.622 133.358 105.521 132.769C104.986 129.534 104.052 122.392 105.087 116.843C105.087 116.843 98.7953 111.735 90.2676 115.053Z"
        fill="#FFCF97"
      />
      <path
        d="M88.2755 124.039C88.2755 124.039 87.8593 117.027 84.7193 117.913C81.5794 118.8 82.2276 126.501 88.2755 124.039Z"
        fill="#FFCF97"
      />
      <path
        d="M439.849 448.367L443.008 470.722H432.682L425.955 446.438L439.849 448.367Z"
        fill="#FFCF97"
      />
      <path
        d="M397.317 488.943H444.448C446.904 488.943 448.785 486.47 448.427 483.71C447.619 477.449 446.183 467.29 444.947 463.145C443.106 456.952 437.141 466.817 437.141 466.817L430.56 460.521C430.56 460.521 402.591 473.815 395.459 484.7C394.311 486.452 395.395 488.943 397.317 488.943Z"
        fill="#5396E7"
      />
      <path
        d="M497.212 431.87C498.185 434.367 509.184 459.936 509.184 459.936L500.904 470.307L495.178 463.621L483.88 434.367L497.212 431.87Z"
        fill="#FFCF97"
      />
      <path
        d="M515.436 458.118C516.906 459.539 517.387 461.689 516.678 463.605C514.269 470.116 507.747 484.548 496.127 488.797C486.874 492.172 480.539 492.184 477.012 491.685C475.336 491.445 474.468 489.547 475.359 488.105C479.233 481.864 488.357 466.444 489.189 458.494C490.243 448.376 502.28 460.447 502.28 460.447L508.755 451.648L515.436 458.118Z"
        fill="#5396E7"
      />
      <path
        d="M449.323 250.594C449.323 250.594 471.955 268.933 477.234 287.858C481.618 303.585 489.845 395.354 489.845 395.354C489.845 395.354 503.001 431.971 503.663 442.617C503.663 442.617 493.22 450.065 485.204 448.923L466.915 404.601C466.915 404.601 462.877 399.25 460.909 393.029C458.94 386.808 442.315 328.296 442.315 328.296C442.315 328.296 440.973 414.827 443.598 451.648C443.598 451.648 431.673 453.472 424.547 451.925C424.547 451.925 421.823 421.886 418.582 414.536C415.341 407.186 414.656 395.669 415.687 384.637C416.724 373.604 405.831 311.019 408.655 255.797L449.323 250.594Z"
        fill="#10244F"
      />
      <path
        d="M471.228 266.902C471.685 264.08 463.64 259.627 461.489 258.96C456.268 257.34 450.783 258.717 446.066 261.197C426.669 271.404 403.639 266.269 403.639 266.269C403.639 266.269 400.416 219.871 410.044 194.22C411.562 190.18 415.553 186.076 419.766 185.156C428.796 183.181 435.219 180.286 445.04 179.624C452.406 179.132 466.804 180.427 471.304 181.405C475.846 182.39 481.706 187.336 480.51 190.328L469.898 205.76C474.674 236.488 474.51 246.778 471.228 266.902Z"
        fill="#1664BF"
      />
      <path
        d="M442.415 168.484C444.566 170.576 447.255 170.98 448.55 171.179C451.269 171.59 462.89 172.228 470.707 163.473C478.366 154.906 479.145 140.954 474.774 138.111C471.639 136.066 466.652 140.274 461.923 137.104C459.825 135.697 460.323 134.549 458.096 133.06C454.346 130.552 449.529 131.519 445.837 132.316C439.104 133.769 439.321 135.627 433.397 137.109C424.958 139.225 420.159 136.541 418.506 139.254C417.188 141.422 418.876 145.372 421.149 147.657C425.861 152.398 431.703 148.396 435.324 152.767C437.575 155.48 437.252 159.207 440.604 165.935C440.874 166.474 441.395 167.488 442.415 168.484Z"
        fill="black"
      />
      <path
        d="M435.828 175.041C436.08 174.174 436.35 173.213 436.602 172.205C434.697 172.99 432.699 173.119 431.521 172.468C430.062 171.671 429.798 170.095 429.212 168.214C428.966 167.429 428.744 166.509 427.923 165.712C427.484 165.284 426.997 165.003 426.529 164.815C425.948 164.587 425.644 163.954 425.802 163.35L425.954 162.993C427.144 160.174 427.777 157.156 427.812 154.097L427.876 148.056C431.176 146.749 432.377 146.972 432.822 147.493C432.986 147.686 433.232 147.815 433.221 148.741C433.168 153.23 437.2 152.767 440.575 151.941C443.95 151.115 442.767 157.373 442.767 157.373C442.767 157.373 444.859 154.666 447.191 157.016C448.855 158.703 445.609 161.457 443.634 162.887L444.97 175.855C445.321 179.266 448.533 179.237 451.756 180.414L460.44 182.588C460.44 182.588 437.715 192.693 423.716 185.548L431.217 182.981C433.783 181.598 435.16 177.338 435.828 175.041Z"
        fill="#FFCF97"
      />
      <path
        d="M437.34 258.421L377.709 258.33C376.197 258.328 374.885 257.296 374.521 255.829L362.561 207.275C362.046 205.201 363.622 203.197 365.755 203.2L425.386 203.291C426.898 203.294 428.211 204.326 428.574 205.793L440.54 254.347C441.05 256.421 439.479 258.424 437.34 258.421Z"
        fill="#AAD1FF"
      />
      <path
        d="M366.957 227.33L368.697 232.188L383.048 225.383C383.048 225.383 390.924 232.222 371.797 240.941C363.828 244.574 366.957 227.33 366.957 227.33Z"
        fill="#FFCF97"
      />
      <path
        d="M470.203 288.203C467.32 288.826 465.738 288.891 464.361 284.939C463.663 282.921 466.57 277.396 467.285 275.384C471.691 263.038 473.285 251.067 471.234 238.206C469.09 224.739 464.396 210.529 468.111 197.017C469.359 192.478 473.748 180.937 480.112 187.787C481.425 189.193 482.28 190.959 482.708 192.833C486.236 208.309 489.207 224.149 489.131 240.128C489.06 254.476 483.868 268.088 476.907 280.643C475.178 283.749 473.033 287.594 470.203 288.203Z"
        fill="#FFCF97"
      />
    </svg>
  );
};

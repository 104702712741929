import { FC, useCallback, useMemo, useState } from 'react';
import { PlusAdminIcon } from '../../../assets/icons/PlusAdminIcon';
import { IUser } from '../../../core/backend/_models/user';
import { useGetMerchantUsers } from '../../../core/hooks/users/getMerchantUsers';
import { useSetMerchantUserBannedCallback } from '../../../core/hooks/users/setMerchantUserBanned';
import { Button } from '../../Button/Button';
import { Heading } from '../../Heading/Heading';
import { Loader } from '../../Loader/Loader';
import { ScrollBar } from '../../ScrollBar/ScrollBar';
import { Table } from '../../Table/Table';
import { customToast } from '../../Toast/Toast';
import { ModalNewUser } from '../ModalNewUser/ModalNewUser';
import { ModalSecurity } from '../ModalSecurity/ModalSecurity';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { columns } from './constants';
import { mapMerchantUsers } from './mapUsers';
import styles from './ModalUsers.module.scss';

interface IModalUsers {
  userId: number;
  userLogin: string;
  onClose: () => void;
}

export interface ModalState {
  create?: boolean;
  security?: number;
  enabled2FA?: boolean;
}

export const ModalUsers: FC<IModalUsers> = ({ userId, userLogin, onClose }) => {
  const { data, loading, error, reloadAsync, reload } = useGetMerchantUsers({ userId });
  const [modals, setModals] = useState<ModalState>({
    create: false,
    security: undefined,
    enabled2FA: false,
  });
  const setModalState = (name: keyof ModalState, state?: boolean | number) =>
    setModals((o) => ({ ...o, [name]: state }));
  const [innerLoad, setInnerLoad] = useState(false);

  const setIsBanned = useSetMerchantUserBannedCallback({
    onResult: (_: IUser) => reloadAsync(),
    setLoading: setInnerLoad,
    onError: (e) => customToast(e),
  });

  const changePasswordCB = useCallback((i: number) => {
    setModalState('security', i);
  }, []);

  const enabled2FACB = useCallback((value: boolean) => setModalState('enabled2FA', value), []);

  const mapUsers = useMemo(
    () => mapMerchantUsers(setIsBanned, changePasswordCB, enabled2FACB),
    [setIsBanned, changePasswordCB, enabled2FACB],
  );

  if (loading)
    return (
      <ModalWrapper onClose={onClose} className={styles.modal}>
        <Loader />
      </ModalWrapper>
    );

  if (!loading && error) customToast(error);

  return (
    <>
      {modals.create && (
        <ModalNewUser
          onClose={() => setModalState('create', false)}
          reload={reload}
          userId={userId}
        />
      )}
      {modals.security && (
        <ModalSecurity
          onClose={() => setModalState('security')}
          reload={reload}
          userId={modals.security}
          enabled2FA={modals.enabled2FA}
        />
      )}
      <ModalWrapper onClose={onClose} className={styles.modal}>
        <div className={styles.headerRow}>
          <Heading variant="modal" headType="h2">
            <span className={styles.h2Header}> Users of Merchant </span>
            {userLogin}
          </Heading>
          <Button
            variant="secondary"
            onClick={() => setModalState('create', true)}
            icon={<PlusAdminIcon />}
          >
            Create
          </Button>
        </div>
        {!data || loading || innerLoad ? (
          <Loader />
        ) : (
          <>
            <ScrollBar orientation="vertical" color="blue" className={styles.scroll}>
              <Table
                columns={columns}
                data={data.sort((a, b) => a.id - b.id).map(mapUsers)}
                isMerchantsPage
              />
            </ScrollBar>
          </>
        )}
      </ModalWrapper>
    </>
  );
};

import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../../components/Loader/Loader';
import { ModalNewUser } from '../../../components/Modals/ModalNewUser/ModalNewUser';
import { ModalSecurity } from '../../../components/Modals/ModalSecurity/ModalSecurity';
import { Pagination } from '../../../components/Pagination/Pagination';
import { Table } from '../../../components/Table/Table';
import { customToast } from '../../../components/Toast/Toast';
import { IUser } from '../../../core/backend/_models/user';
import { useGetMerchants } from '../../../core/hooks/users/getMerchants';
import { useSetBannedCallback } from '../../../core/hooks/users/setMerchantBanned';
import { RootState } from '../../../store';
import { timeToUtc } from '../../../utils/timeToUtc';
import { columns } from './constants';
import { mapMerchant } from './mapMerchant';
import { MerchantsHeader } from './MerchantsHeader/MerchantsHeader';
import { ModalNewMerchant } from '../../../components/Modals/ModalNewMerchant/ModalNewMerchant';
import { ModalApiKeys } from '../../../components/Modals/ModalApiKeys/ModalApiKeys';
import { ModalIPs } from '../../../components/Modals/ModalIPs/ModalIPs';
import { ModalUsers } from '../../../components/Modals/ModalUsers/ModalUsers';

export interface ModalState {
  create?: boolean;
  api?: number;
  ip?: number;
  security?: number;
  enabled2FA?: boolean;
  users?: number;
  usersName?: string;
}

export const MerchantsPage = () => {
  const dates = useSelector((state: RootState) => state.dateRange.range);

  const [modals, setModals] = useState<ModalState>({
    create: false,
    api: undefined,
    ip: undefined,
    security: undefined,
    enabled2FA: false,
    users: undefined,
    usersName: undefined,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const setModalState = (name: keyof ModalState, state?: boolean | number | string) =>
    setModals((o) => ({ ...o, [name]: state }));

  const [innerLoad, setInnerLoad] = useState(false);
  const rightTime = timeToUtc(dates);

  const { data, loading, reloadAsync, reload } = useGetMerchants({
    ...rightTime,
    page: currentPage,
  });

  const setIsBanned = useSetBannedCallback({
    onResult: (_: IUser) => reloadAsync(),
    setLoading: setInnerLoad,
    onError: (e) => customToast(e),
  });

  const setApiIdCB = useCallback((i: number) => {
    setModalState('api', i);
  }, []);

  const setIPIdCB = useCallback((i: number) => {
    setModalState('ip', i);
  }, []);

  const setUsersCB = useCallback((i: number) => {
    setModalState('users', i);
  }, []);

  const setUsersNameCB = useCallback((value: string) => setModalState('usersName', value), []);

  const mapUsers = useMemo(
    () => mapMerchant(setIsBanned, setApiIdCB, setIPIdCB, setUsersCB, setUsersNameCB),
    [setApiIdCB, setIPIdCB, setIsBanned, setUsersCB, setUsersNameCB],
  );

  // if (!data || loading || innerLoad) return <Loader />;

  return (
    <>
      {modals.create && (
        <ModalNewMerchant onClose={() => setModalState('create', false)} reload={reload} />
      )}
      {modals.api && <ModalApiKeys onClose={() => setModalState('api')} userId={modals.api} />}
      {modals.ip && <ModalIPs onClose={() => setModalState('ip')} userId={modals.ip} />}
      {modals.security && (
        <ModalSecurity
          onClose={() => setModalState('security')}
          reload={reload}
          userId={modals.security}
          enabled2FA={modals.enabled2FA}
        />
      )}
      {modals.users && modals.usersName && (
        <ModalUsers
          onClose={() => setModalState('users')}
          userId={modals.users}
          userLogin={modals.usersName}
        />
      )}
      <MerchantsHeader setModalState={setModalState} />
      {!data || loading || innerLoad ? (
        <Loader />
      ) : (
        <>
          <Table
            columns={columns}
            data={data.values.sort((a, b) => a.id - b.id).map(mapUsers)}
            isMerchantsPage
          />
          {data && data.maxPage > 1 && (
            <Pagination
              maxPage={data.maxPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </>
      )}
    </>
  );
};

import { FC, useState } from 'react';
import { useConfirm2fa } from '../../../core/hooks/auth/confirm2fa';
import { useDispatch } from 'react-redux';
import { userLoad } from '../../../store/reducers/userReducer/userReducer';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import styles from './Modal2FA.module.scss';
import { AppDispatch } from '../../../store';
import { TFAForm } from '../../../pages/TwoFactorAuthPage/TFAForm/TFAForm';
import { customToast } from '../../Toast/Toast';

interface IModal2FA {
  onClose: () => void;
}
export const Modal2FA: FC<IModal2FA> = ({ onClose }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const confirm2fa = useConfirm2fa({
    onResult: (res) => {
      if (!res) customToast('Code is incorrect');
      else {
        customToast('2FA created');
        dispatch(userLoad());
        onClose();
      }
    },
    setLoading: setConfirmLoading,
  });

  return (
    <ModalWrapper onClose={onClose} className={styles.modal}>
      <TFAForm isModal={true} onClose={onClose} />
    </ModalWrapper>
  );
};

import { FC } from 'react';
import { IIcon } from '../types';

export const DropdownIcon: FC<IIcon> = ({
  width = 16,
  height = 16,
  fill = '#BEC9D7',
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.4875 5.8625L8 9.35L4.5125 5.8625C4.3625 5.7125 4.125 5.7125 3.9875 5.8625C3.8375 6.0125 3.8375 6.25 3.9875 6.3875L7.7375 10.1375C7.8125 10.2125 7.9125 10.25 8 10.25C8.0875 10.25 8.1875 10.2125 8.2625 10.1375L12.0125 6.3875C12.1625 6.2375 12.1625 6 12.0125 5.8625C11.8625 5.7125 11.6375 5.7125 11.4875 5.8625Z"
      fill={fill}
    />
  </svg>
);

import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'users/create-user-in-merchant';

export interface CreateMerchantUserInput {
  login: string;
  password: string;
  email: string;
  merchantId: number;
}

export const useCreateMerchantUserCallback = (params: IPostCallbackParams<undefined>) =>
  usePostCallback<CreateMerchantUserInput, undefined>(path, params);

import { LoginImage } from '../../../assets/images/LoginImage';
import { LoginForm } from './LoginForm/LoginForm';
import styles from './Main.module.scss';

export const Main = () => {
  return (
    <main className={styles.main}>
      <LoginForm />
      <span className={styles.copyright}>&copy;2022</span>
      <LoginImage className={styles.image} />
    </main>
  );
};

import { FC } from 'react';
import { Comparison } from '../../core/hooks/transactions/types';
import { IInputProps, Input } from '../Input/Input';
import { CompareControls } from './CompareControls/CompareControls';

export type ICompareInputProps = Omit<IInputProps, 'icon' | 'variant'> & {
  comparison?: Comparison;

  setComparison: (newComp: Comparison) => void;
};

export const CompareInput: FC<ICompareInputProps> = ({ comparison, setComparison, ...props }) => {
  const controls = <CompareControls comparison={comparison} setComparison={setComparison} />;
  return <Input variant="primary" icon={controls} {...props} />;
};

import { HTMLAttributes } from 'react';

export enum IconColor {
  WHITE = '#ffffff',
  GREY = '#ccd5df',
  BLUE = '#3190FF',
  LIGHT_BLUE = '#9CCAFF',
}

export interface ISvg extends HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
}

export interface IIcon extends ISvg {
  fill?: IconColor;
}

import { IIpAddress } from '../../backend/_models/ip';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'security/createIpAddress';

export interface ICreateIpAddress {
  userId: number;
  ipAddress: string;
}

export const useCreateIpAddress = (params: IPostCallbackParams<IIpAddress>) =>
  usePostCallback<ICreateIpAddress, IIpAddress>(path, params);

import { FC } from 'react';
import { IIcon } from '../types';

export const MerchantIcon: FC<IIcon> = ({ width = 30, height = 30, fill = '#8CC1FF' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.74657 6C7.49602 6 7.26145 6.12178 7.12242 6.32542L5.12203 9.2485C5.00701 9.41609 4.97446 9.62034 5.01947 9.8118C5.23001 12.0543 7.17496 13.7949 9.49691 13.7949C10.4736 13.7949 11.4026 13.4944 12.1722 12.9376C13.0019 13.4939 13.9838 13.7949 14.998 13.7949C16.0122 13.7949 16.9941 13.4939 17.8237 12.9376C18.5934 13.4944 19.5224 13.7949 20.4991 13.7949C22.8095 13.7949 24.747 12.072 24.9736 9.84605C25.0301 9.64485 24.9955 9.42632 24.874 9.2485L22.8736 6.32542C22.7345 6.12178 22.5 6 22.2494 6H7.74657ZM5.99623 13.518V24.2692C5.99623 24.6726 6.33229 25 6.74637 25H15.248C15.6621 25 15.9982 24.6726 15.9982 24.2692V17.4487C15.9982 17.0453 16.3343 16.7179 16.7483 16.7179H20.249C20.6631 16.7179 20.9992 17.0453 20.9992 17.4487V24.2692C20.9992 24.6726 21.3352 25 21.7493 25H23.2496C23.6637 25 23.9998 24.6726 23.9998 24.2692V13.5437C23.0471 14.3105 21.8278 14.7692 20.4991 14.7692C19.5419 14.7692 18.6238 14.5347 17.8071 14.086C16.944 14.5347 15.9847 14.7692 14.998 14.7692C14.0113 14.7692 13.052 14.5347 12.1888 14.086C11.3722 14.5347 10.4541 14.7692 9.49691 14.7692C8.17966 14.7692 6.95542 14.2965 5.99623 13.518ZM9.74696 16.7179H12.7476C13.1621 16.7179 13.4977 17.0448 13.4977 17.4487V20.3718C13.4977 20.7757 13.1621 21.1026 12.7476 21.1026H9.74696C9.33238 21.1026 8.99682 20.7757 8.99682 20.3718V17.4487C8.99682 17.0448 9.33238 16.7179 9.74696 16.7179Z"
        fill={fill}
      />
    </svg>
  );
};

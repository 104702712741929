import { ISelectValue } from '../../../../components/Select/Select';

export const STATE_OPTIONS: ISelectValue[] = [
  {
    value: 'denied',
    label: 'denied',
  },
  {
    value: 'approved',
    label: 'approved',
  },
  {
    value: 'confirmed',
    label: 'confirmed',
  },
  {
    value: 'removed',
    label: 'removed',
  },
  {
    value: 'undefined',
    label: 'undefined',
  },
  {
    value: 'pending',
    label: 'pending',
  },
];

export const RESULT_OPTIONS: ISelectValue[] = [
  {
    value: 'denied',
    label: 'denied',
  },
  {
    value: 'approved',
    label: 'approved',
  },
  {
    value: 'confirmed',
    label: 'confirmed',
  },
  {
    value: 'removed',
    label: 'removed',
  },
];

export const REASON_OPTIONS = [
  { value: 'rbf', label: 'rbf' },
  { value: 'fee', label: 'fee' },
  { value: 'descendant', label: 'descendant' },
];

import { sendPost } from '../../backend/sendPost';
import { IIpAddress } from '../../backend/_models/ip';
import { IPostCallbackParams, usePostCallback } from '../usePostCallback';

const path = 'security/updateIpAddress';

export interface IUpdateIpAddress {
  userId: number;
  targetIpAddress: string;
  updatedIpAddress: string;
}

export const useUpdateIpAddress = (params: IPostCallbackParams<IIpAddress>) =>
  usePostCallback<IUpdateIpAddress, IIpAddress>(path, params);

import { FC } from 'react';
import cx from 'classnames';
import { IIcon } from '../../types';
import styles from './GreaterOrEqualIcon.module.scss';

interface IGreaterOrEqualIcon extends IIcon {
  isLower?: boolean;
}

export const GreaterOrEqualIcon: FC<IGreaterOrEqualIcon> = ({
  width = 14,
  height = 14,
  fill = '#BEC9D7',
  isLower,
}) => {
  return (
    <svg
      className={cx({ [styles.lower]: isLower })}
      width={width}
      height={height}
      viewBox="0 -1 7 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M0.800001 -6.29444e-07L8 3.98885L0.8 7.97263L0.266667 7.09579L5.88267 3.98885L0.266668 0.876837L0.800001 -6.29444e-07ZM8 8.98632L8 10L5.56284e-07 10L6.44903e-07 8.98632L8 8.98632Z"
      />
    </svg>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { updateMerchantAction } from '../../actions/merchantActions';
import { MerchantReducerState } from './types';

const initialState: MerchantReducerState = {
  id: undefined,
};

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    updateMerchant: updateMerchantAction,
  },
});

export const { updateMerchant: updateMerchant } = merchantSlice.actions;
export const merchantReducer = merchantSlice.reducer;

import { FC } from 'react';
import { IIcon } from '../types';

export const CopyIcon: FC<IIcon> = ({ width = 16, height = 16, fill = 'white' }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_54_4119)">
      <path
        d="M11.4286 2.85742H1.71428C0.767511 2.85742 0 3.62493 0 4.57171V14.286C0 15.2327 0.767511 16.0003 1.71428 16.0003H11.4286C12.3753 16.0003 13.1428 15.2327 13.1428 14.286V4.57171C13.1428 3.62493 12.3753 2.85742 11.4286 2.85742ZM12 14.286C12 14.6016 11.7442 14.8574 11.4286 14.8574H1.71428C1.39868 14.8574 1.14285 14.6016 1.14285 14.286V4.57171C1.14285 4.2561 1.39868 4.00027 1.71428 4.00027H11.4286C11.7442 4.00027 12 4.2561 12 4.57171V14.286Z"
        fill={fill}
      />
      <path
        d="M14.2856 0H3.99993C3.05316 0 2.28564 0.767511 2.28564 1.71428C2.28564 2.02989 2.54148 2.28572 2.85708 2.28572C3.17269 2.28572 3.42849 2.02989 3.42849 1.71428C3.42849 1.39868 3.68433 1.14285 3.99993 1.14285H14.2856C14.6012 1.14285 14.8571 1.39868 14.8571 1.71428V12C14.8571 12.3156 14.6012 12.5714 14.2856 12.5714C13.97 12.5714 13.7142 12.8273 13.7142 13.1429C13.7142 13.4585 13.97 13.7143 14.2856 13.7143C15.2324 13.7143 15.9999 12.9468 15.9999 12V1.71428C15.9999 0.767511 15.2324 0 14.2856 0Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_54_4119">
        <rect width="16" height="16" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);

import { FC } from 'react';
import { IIcon } from '../types';

export const IPIcon: FC<IIcon> = ({ width = 30, height = 30, fill = '#8CC1FF' }) => (
  <svg
    width={width}
    height={height}
    viewBox={` 0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 4C10.0295 4 6 8.19658 6 13.3731C6 19.5372 11.8523 22.4888 12.5854 23.1755C13.334 23.8777 13.9514 25.448 14.201 26.3393C14.3221 26.7713 14.6621 26.9898 15 27C15.3371 26.9906 15.6779 26.7722 15.799 26.3393C16.0486 25.448 16.666 23.8768 17.4146 23.1755C18.1477 22.4888 24 19.5372 24 13.3731C24 8.19658 19.9705 4 15 4ZM10.9091 9.96469H12.5742V16.7815H10.9091V9.96469ZM15.0447 9.96469H17.9116C19.318 9.96469 20.2702 10.9198 20.2702 12.3845C20.2702 13.8348 19.268 14.7827 17.8125 14.7827H16.7099V16.7815H15.0447V9.96469ZM16.7099 11.2961V13.4746H17.4641C18.1719 13.4746 18.5891 13.0959 18.5891 12.3878C18.5883 11.6695 18.1742 11.2961 17.4705 11.2961H16.7099Z"
      fill={fill}
    />
  </svg>
);

import cx from 'classnames';
import { TransactionsRangepicker } from './TransactionsRangepicker/TransactionsRangepicker';
import styles from './TransactionsFilters.module.scss';
import { MerchantSelect } from '../../../../components/MerchantSelect/MerchantSelect';
import { ISelectValue, Select } from '../../../../components/Select/Select';
import { DateRangeAction, FilterAction } from './types';
import { ChangeEvent, FC } from 'react';
import { Input } from '../../../../components/Input/Input';
import { convertToSelectValue } from './convertToSelectValue';
import { REASON_OPTIONS, RESULT_OPTIONS, STATE_OPTIONS } from './constants';
import { CompareInput } from '../../../../components/CompareInput/CompareInput';
import { FilterValue } from '../../TransactionsPage';
import { Comparison } from '../../../../core/hooks/transactions/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { USER_ROLE } from '../../../../core/backend/_models/user';

export interface ITransactionsFiltersProps {
  state: FilterValue;
  reason: FilterValue;
  address: FilterValue;
  amount: FilterValue;
  amountComparison: Comparison | undefined;
  amountUSD: FilterValue;
  amountUSDComparison: Comparison | undefined;
  apiKey: FilterValue;
  ipAddress?: FilterValue;
  txid: FilterValue;
  result: FilterValue;

  setState: FilterAction;
  setReason: FilterAction;
  setAddress: FilterAction;
  setAmount: FilterAction;
  setAmountComparison: FilterAction;
  setAmountUSD: FilterAction;
  setAmountUSDComparison: FilterAction;
  setApiKey: FilterAction;
  setIpAddress: FilterAction;
  setTxid: FilterAction;
  setResult: FilterAction;
  setDateRange: DateRangeAction;
}

export const TransactionsFilters: FC<ITransactionsFiltersProps> = ({
  state,
  reason,
  address,
  amount,
  amountComparison,
  amountUSD,
  amountUSDComparison,
  apiKey,
  ipAddress,
  txid,
  result,
  setState,
  setReason,
  setAddress,
  setAmount,
  setAmountComparison,
  setAmountUSD,
  setAmountUSDComparison,
  setApiKey,
  setIpAddress,
  setTxid,
  setResult,
  setDateRange,
}) => {
  const userRole = useSelector((state: RootState) => state.user.info?.role);

  const handleChangeInput = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (e: string) => void,
    delay = 2000,
  ) => {
    const delayDebounceFn = setTimeout(() => {
      if (!onChange || !e.target.value) return;
      onChange(e.target.value);
    }, delay);
    return () => clearTimeout(delayDebounceFn);
  };

  const filtersCx = cx(styles.filters, {
    [styles.filtersAdmin]: userRole === USER_ROLE.SUPER_ADMIN,
    [styles.filtersMerchant]: userRole === USER_ROLE.MERCHANT,
  });

  return (
    <section className={filtersCx}>
      <TransactionsRangepicker width={260} setRange={setDateRange} />
      {userRole === USER_ROLE.SUPER_ADMIN && (
        <MerchantSelect variant="primary" size="full" isFilter />
      )}
      {userRole === USER_ROLE.MERCHANT && (
        <Select
          variant="primary"
          size="full"
          placeholder="Result"
          value={convertToSelectValue(result)}
          options={RESULT_OPTIONS}
          onChange={(newResult: ISelectValue) => setResult(newResult.value)}
          onClear={() => setResult(undefined)}
          isFilter
        />
      )}
      {userRole === USER_ROLE.SUPER_ADMIN && (
        <Select
          variant="primary"
          size="full"
          placeholder="State"
          value={convertToSelectValue(state)}
          options={STATE_OPTIONS}
          onChange={(newValue: ISelectValue) => setState(newValue.value)}
          onClear={() => setState(undefined)}
          isFilter
        />
      )}
      {userRole === USER_ROLE.SUPER_ADMIN && (
        <Select
          variant="primary"
          size="full"
          placeholder="Reason"
          value={convertToSelectValue(reason)}
          options={REASON_OPTIONS}
          onChange={(newValue: ISelectValue) => setReason(newValue.value)}
          onClear={() => setReason(undefined)}
          isFilter
        />
      )}
      <Input
        minWidth={189}
        width={561}
        placeholder="Address"
        value={address}
        onChange={(e) => handleChangeInput(e, (value) => setAddress(value))}
        onClear={async () => {
          await setAddress('');
          setAddress(undefined);
        }}
        isFilter
      />
      <CompareInput
        width={189}
        type="number"
        placeholder="Amount"
        value={amount}
        onChange={(e) => handleChangeInput(e, (value) => setAmount(value), 3000)}
        onClear={async () => {
          await setAmount('');
          setAmount(undefined);
        }}
        comparison={amountComparison}
        setComparison={setAmountComparison}
        isFilter
      />
      <CompareInput
        width={189}
        type="number"
        placeholder="Amount USD"
        value={amountUSD}
        onChange={(e) => handleChangeInput(e, (value) => setAmountUSD(value), 3000)}
        onClear={async () => {
          await setAmountUSD('');
          setAmountUSD(undefined);
        }}
        comparison={amountUSDComparison}
        setComparison={setAmountUSDComparison}
        isFilter
      />
      {userRole === USER_ROLE.SUPER_ADMIN && (
        <Input
          width={189}
          placeholder="ApiKey"
          value={apiKey}
          onChange={(e) => handleChangeInput(e, (value) => setApiKey(value))}
          onClear={async () => {
            await setApiKey('');
            setApiKey(undefined);
          }}
          isFilter
        />
      )}
      {userRole === USER_ROLE.SUPER_ADMIN && (
        <Input
          width={189}
          placeholder="IP address"
          value={ipAddress}
          onChange={(e) => handleChangeInput(e, (value) => setIpAddress(value))}
          onClear={async () => {
            await setIpAddress('');
            setIpAddress(undefined);
          }}
          isFilter
        />
      )}
      <Input
        minWidth={189}
        width={561}
        placeholder="Txid"
        value={txid}
        onChange={(e) => handleChangeInput(e, (value) => setTxid(value))}
        onClear={async () => {
          await setTxid('');
          setTxid(undefined);
        }}
        isFilter
      />
    </section>
  );
};

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { AppRoutes } from './routes/AppRoutes';
import { AppDispatch, RootState } from './store';
import { USER_STATE } from './store/reducers/userReducer/types';
import { userLoad } from './store/reducers/userReducer/userReducer';
import './App.css';
import './fonts/fonts.scss';
import { Toast } from './components/Toast/Toast';

const App: FC = () => {
  const userState = useSelector((store: RootState) => store.user.state);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (userState === USER_STATE.LOADING || userState === USER_STATE.CACHED) {
      dispatch(userLoad());
    }
  }, [dispatch, userState]);

  // It's just an example. Best way not to load whoami each time and show loader
  // Better to cache current user instead and after do check that he is OK, unlogg if no
  // if (userState === USER_STATE.LOADING) return <>Loading</>;

  return (
    <BrowserRouter>
      <AppRoutes />
      <Toast />
    </BrowserRouter>
  );
};

export default App;

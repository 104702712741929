import { IStatisticsCardProps } from '../../../../components/StatisticsCard/StatisticsCard';
import { IMainStats } from '../../../../core/backend/_models/stats';
import { USER_ROLE } from '../../../../core/backend/_models/user';
import { formatNumber } from '../../../../utils/formatNumber';
import { roundNumber } from '../../../../utils/roundNumber';

export const getCards = (
  stats: IMainStats | undefined,
  userRole: USER_ROLE,
): IStatisticsCardProps[][] => {
  if (userRole === USER_ROLE.MERCHANT) {
    return [
      [
        {
          heading: 'Total BTC',
          statValue: stats?.totalBtc && formatNumber(stats.totalBtc, false),
          key: 'totalBTC',
        },
        {
          heading: 'Total USD',
          statValue: stats?.totalUsd && formatNumber(roundNumber(stats.totalUsd), false),
          key: 'totalUsd',
        },
      ],
      [
        {
          heading: 'Request',
          statValue: stats?.requests && formatNumber(stats.requests, false),
          key: 'requests',
        },
        {
          heading: 'Transactions',
          statValue: stats?.transactions && formatNumber(stats.transactions, false),
          key: 'transactions',
        },
        {
          heading: 'Avg Approval Time',
          statValue: stats?.avgApprTime && formatNumber(stats.avgApprTime, false),
          key: 'avgApprTime',
        },
        {
          heading: 'Avg Saved Time',
          statValue: stats?.avgSavedTime && formatNumber(stats.avgSavedTime, false),
          key: 'avgSavedTime',
        },
      ],
      [
        {
          heading: 'Approved',
          statValue: stats?.approved && formatNumber(stats.approved, false),
          key: 'approved',
        },
        {
          heading: 'Confirmed',
          statValue: stats?.confirmed && formatNumber(stats.confirmed, false),
          key: 'confirmed',
        },
        {
          heading: 'Denied',
          statValue: stats?.denied && formatNumber(stats.denied, false),
          key: 'denied',
        },
        {
          heading: 'Removed',
          statValue: stats?.reject && formatNumber(stats.reject, false),
          key: 'removed',
        },
      ],
      [
        {
          heading: 'Pending',
          statValue: stats?.pending && formatNumber(stats.pending, false),
          key: 'pending',
        },
      ],
    ];
  }
  return [
    [
      {
        heading: 'Total BTC',
        statValue: stats?.totalBtc && formatNumber(stats.totalBtc, false),
        key: 'totalBTC',
      },
      {
        heading: 'Avg BTC',
        statValue: stats?.avgBtc && formatNumber(stats.avgBtc, false),
        key: 'avgBtc',
      },
      {
        heading: 'Total USD',
        statValue: stats?.totalUsd && formatNumber(roundNumber(stats.totalUsd), false),
        key: 'totalUsd',
      },
      {
        heading: 'Avg USD',
        statValue: stats?.avgUsd && formatNumber(roundNumber(stats.avgUsd), false),
        key: 'avgUsd',
      },
    ],
    [
      {
        heading: 'Request',
        statValue: stats?.requests && formatNumber(stats.requests, false),
        key: 'requests',
      },
      {
        heading: 'Transacions',
        statValue: stats?.transactions && formatNumber(stats.transactions, false),
        key: 'transactions',
      },
      {
        heading: 'Avg Approval Time',
        statValue: stats?.avgApprTime && formatNumber(stats.avgApprTime, false),
        key: 'avgApprTime',
      },
      {
        heading: 'Avg Saved Time',
        statValue: stats?.avgSavedTime && formatNumber(stats.avgSavedTime, false),
        key: 'avgSavedTime',
      },
    ],
    [
      {
        heading: 'Approved',
        statValue: stats?.approved && formatNumber(stats.approved, false),
        key: 'approved',
      },
      {
        heading: 'Approved to Confirmed',
        statValue: stats?.approvedToConfirmed && formatNumber(stats.approvedToConfirmed, false),
        key: 'approvedToConfirmed',
      },
      {
        heading: 'Confirmed',
        statValue: stats?.confirmed && formatNumber(stats.confirmed, false),
        key: 'confirmed',
      },
      {
        heading: 'Approved to Removed',
        statValue: stats?.approvedToReject && formatNumber(stats.approvedToReject, false),
        key: 'approvedToReject',
      },
    ],
    [
      {
        heading: 'Denied',
        statValue: stats?.denied && formatNumber(stats.denied, false),
        key: 'denied',
      },
      {
        heading: 'Denied to Approved',
        statValue: stats?.deniedToApproved && formatNumber(stats.deniedToApproved, false),
        key: 'deniedToApproved',
      },
      {
        heading: 'Denied to Confirmed',
        statValue: stats?.deniedToConfirmed && formatNumber(stats.deniedToConfirmed, false),
        key: 'deniedToConfirmed',
      },
      {
        heading: 'Denied to Removed',
        statValue: stats?.deniedToReject && formatNumber(stats.deniedToReject, false),
        key: 'deniedToReject',
      },
    ],
    [
      {
        heading: 'Low Fee',
        statValue: stats?.lowFee && formatNumber(stats.lowFee, false),
        key: 'lowFee',
      },
      {
        heading: 'RBF',
        statValue: stats?.rbf && formatNumber(stats.rbf, false),
        key: 'rbf',
      },
      {
        heading: 'Descendants',
        statValue: stats?.hasDescendants && formatNumber(stats.hasDescendants, false),
        key: 'hasDescendants',
      },
      {
        heading: 'Pending',
        statValue: stats?.pending && formatNumber(stats.pending, false),
        key: 'pending',
      },
    ],
  ];
};

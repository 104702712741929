import { createSlice } from '@reduxjs/toolkit';
import { updateAutoUpdateAction } from '../../actions/autoUpdateReducer';
import { AutoUpdateState } from './types';

const initialState: AutoUpdateState = {
  active: false,
};

export const autoUpdateSlice = createSlice({
  name: 'autoUpdate',
  initialState,
  reducers: {
    updateAutoUpdate: updateAutoUpdateAction,
  },
});

export const { updateAutoUpdate: updateAutoUpdate } = autoUpdateSlice.actions;
export const autoUpdateReducer = autoUpdateSlice.reducer;

import { FC, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../../../assets/icons/CopyIcon';
import { IconColor } from '../../../../assets/types';
import { IApiKey } from '../../../../core/backend/_models/apiKey';
import { useSetApiKeyEnabled } from '../../../../core/hooks/apiKey/setApiKeyEnabled';
import { Button } from '../../../Button/Button';
import { Input } from '../../../Input/Input';
import { Loader } from '../../../Loader/Loader';
import { customToast } from '../../../Toast/Toast';

import styles from './ApiKeySection.module.scss';
import { INPUT_MAX_WIDTH } from './constants';

interface IApiKeySectionProps {
  userId: number;
  apiKey: IApiKey;

  reload: () => void;
}

export const ApiKeySection: FC<IApiKeySectionProps> = ({ userId, apiKey, reload }) => {
  const [toggleApiLoad, setToggleApiLoad] = useState(false);

  const setApiKeyEnabled = useSetApiKeyEnabled({
    setLoading: setToggleApiLoad,
    onResult: reload,
    onError: (e) => customToast(e),
  });

  const toggleApiKey = () =>
    setApiKeyEnabled({
      apiKey: apiKey.apiKey,
      userId: userId,
      enabled: !apiKey.enabled,
    });

  return (
    <section className={styles.apiKey}>
      <div className={styles.apiKeyInputRow}>
        <CopyToClipboard
          text={apiKey.apiKey}
          onCopy={() => customToast('Api key copied successfully!')}
        >
          <div className={styles.inputWrapper} style={{ maxWidth: INPUT_MAX_WIDTH }}>
            <Input
              width={INPUT_MAX_WIDTH}
              value={apiKey.apiKey}
              variant="secondary"
              className={styles.input}
              disabled
            />
          </div>
        </CopyToClipboard>
        <CopyToClipboard
          text={apiKey.apiKey}
          onCopy={() => customToast('Api key copied successfully!')}
        >
          <div className={styles.copyIcon}>
            <CopyIcon width={20} height={20} fill={IconColor.GREY} />
          </div>
        </CopyToClipboard>
      </div>
      <Button
        variant={apiKey.enabled ? 'secondary' : 'primary'}
        onClick={!toggleApiLoad ? toggleApiKey : () => void 0}
        size="largerXs"
      >
        {toggleApiLoad ? (
          <Loader size="xs" color={apiKey.enabled ? 'primary' : 'secondary'} />
        ) : apiKey.enabled ? (
          'Disable'
        ) : (
          'Enable'
        )}
      </Button>
    </section>
  );
};

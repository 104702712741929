import { FC } from 'react';
import { ScrollBar } from '../../ScrollBar/ScrollBar';
import { ICardTableProps } from '../CardTable';
import styles from './CardTableBody.module.scss';
import cx from 'classnames';
import { formatNumber } from '../../../utils/formatNumber';

type ICardTableBodyProps = Pick<ICardTableProps, 'rows' | 'isAdmin'>;

export const CardTableBody: FC<ICardTableBodyProps> = ({ rows, isAdmin }) => {
  return (
    <ScrollBar color="blue" className={styles.cardTableBody}>
      {typeof rows !== 'undefined' && rows.length > 0 ? (
        rows.map((row) => (
          <div key={row.name} className={styles.row}>
            <span className={cx(styles.key, { [styles.bigKey]: !isAdmin })}>{row.name}</span>
            <span className={styles.value}>{formatNumber(row.count, false)}</span>
          </div>
        ))
      ) : (
        <div className={styles.noData}> No data </div>
      )}
    </ScrollBar>
  );
};

import { sendPost } from '../../backend/sendPost';
import { ITransactionItem } from '../../backend/_models/stats';
import { usePostRequest } from '../usePostRequest';

const path = 'statistics/get-transactions-statistic';

export interface IGetTransactionStatistics {
  status: 'YEARLY' | 'MONTHLY' | 'DAILY';
  userId?: number;
}

export const useGetTransactionStatistics = (input: IGetTransactionStatistics) =>
  usePostRequest<IGetTransactionStatistics, ITransactionItem[]>(path, input);

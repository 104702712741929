import { FC } from 'react';
import { IIcon } from '../types';

type XMarckProps = IIcon & {
  onClear?: () => void;
};
export const XMarkIcon: FC<XMarckProps> = ({
  width = 12,
  height = 12,
  fill = '#3190FF',
  className,
  onClear,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => {
      onClear?.call(this);
    }}
  >
    <path d="M9 3L3 9" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 3L9 9" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
